export const InitialNodes = [
  // {
  //   id: "1",
  //   type: "questionNode",
  //   data: {
  //     handles: [
  //       {
  //         id: "question_node_source_1",
  //         allowedTypes: ["appNode", "appGroupNode"],
  //       },
  //       { id: "question_node_source_2", allowedTypes: ["resultNode"] },
  //     ],
  //   },
  //   position: { x: 150, y: 25 },
  // },
  // {
  //   id: "2",
  //   type: "appNode",
  //   data: {
  //     appName: "Notion",
  //     appLogo:
  //       "https://imagedelivery.net/prp7br5UdCsJqvWC5hRwlA/d1dba8c0-c9ff-4936-da05-c8039a579000/mid",
  //     handles: [{ id: "app_node_target_1", allowedTypes: ["questionNode"] }],
  //   },
  //   position: { x: 0, y: 50 },
  // },
  // {
  //   id: "3",
  //   type: "questionNode",
  //   data: {
  //     handles: [
  //       {
  //         id: "question_node_source_1",
  //         allowedTypes: ["appNode", "appGroupNode"],
  //       },
  //       { id: "question_node_source_2", allowedTypes: ["resultNode"] },
  //     ],
  //   },
  //   position: { x: 150, y: 190 },
  // },
  // {
  //   id: "4",
  //   type: "appNode",
  //   data: {
  //     appName: "Slack",
  //     appLogo:
  //       "https://imagedelivery.net/prp7br5UdCsJqvWC5hRwlA/9bfa6d6b-9415-4f9c-5e82-bb05de1ec000/mid",
  //     handles: [{ id: "app_node_target_1", allowedTypes: ["questionNode"] }],
  //   },
  //   position: { x: 0, y: 200 },
  // },
  {
    id: "finalResultNode_1",
    type: "finalResultNode",
    data: {
      handles: [
        { id: "final_result_node_source_1", allowedTypes: ["resultNode"] },
      ],
    },
    position: { x: 100, y: 600 },
  },
  // {
  //   id: "6",
  //   type: "appGroupNode",
  //   data: {
  //     apps: [
  //       {
  //         name: "Slack",
  //         logo: "https://imagedelivery.net/prp7br5UdCsJqvWC5hRwlA/9bfa6d6b-9415-4f9c-5e82-bb05de1ec000/mid",
  //       },
  //       {
  //         name: "Notion",
  //         logo: "https://imagedelivery.net/prp7br5UdCsJqvWC5hRwlA/d1dba8c0-c9ff-4936-da05-c8039a579000/mid",
  //       },
  //     ],
  //     handles: [
  //       { id: "app_group_node_target_1", allowedTypes: ["questionNode"] },
  //     ],
  //   },
  //   position: { x: -140, y: 380 },
  // },
  // {
  //   id: "7",
  //   type: "questionNode",
  //   data: {
  //     handles: [
  //       {
  //         id: "question_node_source_1",
  //         allowedTypes: ["appNode", "appGroupNode"],
  //       },
  //       { id: "question_node_source_2", allowedTypes: ["resultNode"] },
  //     ],
  //   },
  //   position: { x: 150, y: 380 },
  // },
];
