/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { CompanyUserInvite } from "../../app/models/CompanyUserInvite";

export default async function updateCompanyUserInvite(
  companyUserInvite: CompanyUserInvite
): Promise<CompanyUserInvite> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const mutation = gql`
    mutation {
      updateCompanyUserInvite(
        id: ${companyUserInvite.id}
        name: "${companyUserInvite.name}"
        email: "${companyUserInvite.email}"
        company_id: ${companyUserInvite.company_id}
        role_id: ${companyUserInvite.role_id}
        status: "${companyUserInvite.status}"
        invited_at: ${companyUserInvite.invited_at}
        user_invite_departments: "${companyUserInvite.user_invite_departments}"
      )
    }
  `;

    try {
      const updateCompanyUserInviteMutationResult = await client.mutate({
        mutation,
      });

      const updatedCompanyUserInvite: CompanyUserInvite = get(
        updateCompanyUserInviteMutationResult,
        "data.updateCompanyUserInvite",
        null
      );

      if (updatedCompanyUserInvite) {
        resolve(updatedCompanyUserInvite);
      } else {
        reject(new Error("Failed to update the company user invite"));
      }
    } catch (error) {
      console.error("Error updating company user invite:", error);
      reject(error);
    }
  });
}
