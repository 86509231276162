import React from "react";
import {
  Handle,
  useHandleConnections,
  HandleProps,
  Node,
  Edge,
} from "@xyflow/react";

interface CustomHandleProps extends HandleProps {
  connectionCount: number;
  nodes: Node[];
  edges: Edge[];
}

const CustomHandle: React.FC<CustomHandleProps> = (props) => {
  const connections = useHandleConnections({
    type: props.type,
  });

  return (
    <Handle
      {...props}
      isConnectable={connections.length < props.connectionCount}
    />
  );
};

export default CustomHandle;
