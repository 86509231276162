/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { KTIcon } from "../../../helpers";
import { getCSSVariableValue } from "../../../assets/ts/_utils";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";
import { useThemeMode } from "../../layout/theme-mode/ThemeModeProvider";

type StatItem = {
  bgColor: string;
  iconName: string;
  text: string;
  linkText: string;
  linkUrl?: string;
};

type ChartData = {
  name: string;
  data: number[];
};

type Props = {
  className: string;
  chartColor: string;
  strokeColor: string;
  chartHeight: string;
  headerTitle: string;
  stats: StatItem[][];
  chartData: ChartData[];
  categories: string[];
  yaxisMin: number;
  yaxisMax: number;
};

const MixedWidget2: React.FC<Props> = ({
  className,
  chartColor,
  chartHeight,
  strokeColor,
  headerTitle,
  stats,
  chartData,
  categories,
  yaxisMin,
  yaxisMax,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();

  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(
        chartHeight,
        chartColor,
        strokeColor,
        chartData,
        categories,
        yaxisMin,
        yaxisMax
      )
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshChart();
    return () => {
      if (chart) {
        chart.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode]);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className={`card-header border-0 py-5 bg-${chartColor}`}>
        <h3 className="card-title fw-bold text-white">{headerTitle}</h3>
        <div className="card-toolbar">
          <button
            type="button"
            className="btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color border-0 me-n3"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <KTIcon iconName="category" className="fs-2" />
          </button>
          <Dropdown1 />
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body p-0">
        <div
          ref={chartRef}
          className={`mixed-widget-2-chart card-rounded-bottom bg-${chartColor}`}
        ></div>
        <div className="card-p mt-n20 position-relative">
          {stats.map((row, rowIndex) => (
            <div className="row g-0" key={rowIndex}>
              {row.map((item, index) => (
                <div
                  className={`col bg-light-${
                    item.bgColor
                  } px-6 py-8 rounded-2 ${
                    index < row.length - 1 ? "me-7" : ""
                  }`}
                  key={index}
                >
                  <KTIcon
                    iconName={item.iconName}
                    className={`fs-3x text-${item.bgColor} d-block my-2`}
                  />
                  <a
                    href={item.linkUrl || "#"}
                    className={`text-${item.bgColor} fw-semibold fs-6 mt-2`}
                  >
                    {item.linkText}
                  </a>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      {/* end::Body */}
    </div>
  );
};

const chartOptions = (
  chartHeight: string,
  chartColor: string,
  strokeColor: string,
  chartData: ChartData[],
  categories: string[],
  yaxisMin: number,
  yaxisMax: number
): ApexOptions => {
  const labelColor = getCSSVariableValue("--bs-gray-500");
  const borderColor = getCSSVariableValue("--bs-gray-200");
  const color = getCSSVariableValue("--bs-" + chartColor);

  return {
    series: chartData,
    chart: {
      fontFamily: "inherit",
      type: "area",
      height: chartHeight,
      toolbar: { show: false },
      zoom: { enabled: false },
      sparkline: { enabled: true },
      dropShadow: {
        enabled: true,
        top: 5,
        left: 0,
        blur: 3,
        color: strokeColor,
        opacity: 0.5,
      },
    },
    stroke: {
      curve: "smooth",
      width: 3,
      colors: [strokeColor],
    },
    xaxis: {
      categories,
      labels: { style: { colors: labelColor, fontSize: "12px" } },
      crosshairs: {
        stroke: { color: borderColor, width: 1, dashArray: 3 },
      },
    },
    yaxis: {
      min: yaxisMin,
      max: yaxisMax,
      labels: { style: { colors: labelColor, fontSize: "12px" } },
    },
    tooltip: {
      y: {
        formatter: (val) => `$${val} thousands`,
      },
    },
    colors: ["transparent"],
    markers: {
      colors: [color],
      strokeColors: [strokeColor],
      strokeWidth: 3,
    },
  };
};

export { MixedWidget2 };
