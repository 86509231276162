import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { observer } from "mobx-react";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
// import DatePicker from "react-datepicker";

import { deleteFile, uploadFile } from "../../../../helpers/api/fileHelper";
import { createApiKey, updateUser } from "../../../../helpers/api";
import i18n from "../../../../i18n";
import { User } from "../../../models/User";
import { FeatureType } from "../../../../helpers/Enums";
import stores from "../../../stores";

import { AccountLoading } from "./loading/AccountLoading";
import GenerateAPIKeyModal from "./GenerateAPIKeyModal";

interface IProfileDetails {
  picture: string;
  fName: string;
  phone: string;
}

interface Props {
  onShowDeleteModal: () => void;
}

interface State {
  data: {
    picture: string;
    fName: string;
    email: string;
    company: string;
    phone: string;
  };
  loading: boolean;
  apiKey: string;
  apiKeyGenerating: boolean;
  apiKeyModalIsVisible: boolean;
  showNotificationModal: boolean;
  useFineTuneAvatar: boolean;
  useFineTuneTheChief: boolean;
  useAutoSync: boolean;
  receiveEmailNotifications: boolean;
  showFineTuneAvatarToggle: boolean;
  showFineTuneTheChiefToggle: boolean;
  canUseAutoSync: boolean;
  selectedFile: File | null;
  previewUrl: string | null;
  autoSyncTime: Date | null;
  showModal: boolean;
  modalMessage: string;
  togglesLoading: boolean;
  showDeleteModal: boolean;
}

const CLOUDFLARE_DOMAIN = "imagedelivery.net";

const profileDetailsSchema = Yup.object().shape({
  fName: Yup.string().required("First name is required"),
});

@observer
export default class Settings extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const currentUser = stores.userStore.currentUser;
    const settings = currentUser.settings || {};
    const isFreeUser = stores.userStore.isFreeUser;

    this.state = {
      data: {
        picture: `${currentUser.picture}`,
        fName: `${currentUser.name}`,
        email: `${currentUser.email}`,
        company: `${stores.companyStore.selectedUserCompany?.name}`,
        phone: `${
          currentUser.phone_number ? currentUser.phone_number : "Phone Number"
        }`,
      },
      loading: false,
      apiKey: "",
      apiKeyGenerating: false,
      apiKeyModalIsVisible: false,
      showNotificationModal: false,
      useFineTuneAvatar: settings.is_fine_tuned_avatar_chat_active || false,
      useFineTuneTheChief: settings.is_fine_tuned_general_chat_active || false,
      useAutoSync: settings.is_auto_sync_active || false,
      receiveEmailNotifications: settings.is_notifications_active || false,
      showFineTuneAvatarToggle: false,
      showFineTuneTheChiefToggle: false,
      canUseAutoSync: true,
      selectedFile: null,
      previewUrl: null,
      autoSyncTime: this.convertUTCToLocalTime(
        settings.auto_sync_time ? settings.auto_sync_time : "00:00"
      ),
      showModal: false,
      modalMessage: "",
      togglesLoading: !isFreeUser,
      showDeleteModal: false,
    };
    if (!isFreeUser) {
      this.initializeFeatureToggles();
    }
  }

  handleShowApiKeyModal = () => {
    this.setState({ apiKeyModalIsVisible: true });
  };

  handleCloseApiKeyModal = () => {
    this.setState({ apiKeyModalIsVisible: false });
  };

  handleShowNotificationModal = (message: string) => {
    this.setState({ showNotificationModal: true, modalMessage: message });
  };

  handleCloseNotificationModal = () => {
    this.setState({ showNotificationModal: false, modalMessage: "" });
  };

  private async initializeFeatureToggles() {
    const isFreeUser = stores.userStore.isFreeUser;

    if (!isFreeUser) {
      const companyHasAvatar =
        stores.companyAvatarStore.companyAvatars.length > 0;

      const availableAvatarCount =
        stores.companyStore.selectedUserCompany?.available_avatar_count || 0;

      const showFineTuneAvatarToggle =
        companyHasAvatar || availableAvatarCount >= 0;

      const hasSubscription = await stores.userStore.checkUserSubscription();
      const showFineTuneTheChiefToggle = hasSubscription;

      const canUseAutoSync = await stores.userStore.checkSubscribedFeatureType(
        FeatureType.AutoSync
      );

      this.setState({
        showFineTuneAvatarToggle,
        showFineTuneTheChiefToggle,
        canUseAutoSync,
        togglesLoading: false,
      });
    } else {
      this.setState({
        togglesLoading: false,
      });
    }
  }

  private handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      this.setState({
        selectedFile: file,
        previewUrl: URL.createObjectURL(file),
      });
    }
  };

  handleTimeChange = (date: Date | null) => {
    if (date) {
      this.setState({ autoSyncTime: date });
    }
  };

  convertToUTCTime = (localTime: Date): string => {
    const utcTime = new Intl.DateTimeFormat("en-US", {
      timeZone: "UTC",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }).format(localTime);

    return utcTime;
  };

  convertUTCToLocalTime = (utcHour: string) => {
    let currentDate = new Date();
    const hour = parseInt(utcHour.split(":")[0]);
    const minutes = parseInt(utcHour.split(":")[1]);

    currentDate.setUTCHours(hour, minutes, 0, 0);

    return currentDate;
  };

  handleSubmit = async (values: Partial<IProfileDetails>): Promise<void> => {
    this.setState({ loading: true });

    const {
      id,
      email,
      email_verified,
      gender,
      locale,
      phone_number_verified,
      is_onboarded,
      auth0_id,
      created_at,
    } = stores.userStore.currentUser;

    const utcTime = this.state.useAutoSync
      ? this.convertToUTCTime(this.state.autoSyncTime!)
      : null;

    const settings: any = {
      is_notifications_active: this.state.receiveEmailNotifications,
      is_auto_sync_active: this.state.useAutoSync,
      auto_sync_time: utcTime,
      is_fine_tuned_avatar_chat_active: this.state.useFineTuneAvatar,
      is_fine_tuned_general_chat_active: this.state.useFineTuneTheChief,
    };

    let newPicture = this.state.data.picture;

    if (this.state.selectedFile) {
      const oldImageUrl = this.state.data.picture;

      if (oldImageUrl.includes(CLOUDFLARE_DOMAIN)) {
        const isDeleted = await deleteFile(oldImageUrl);
        if (!isDeleted) {
          console.error("Old image could not be deleted.");
        }
      }

      const uploadedImageUrl = await uploadFile(this.state.selectedFile);
      if (uploadedImageUrl) {
        newPicture = uploadedImageUrl;
      } else {
        console.error("Failed to upload new image.");
      }
    }

    const updatedUser: User = {
      id,
      name: values.fName,
      email,
      email_verified,
      gender,
      locale,
      picture: newPicture,
      phone_number: values.phone,
      phone_number_verified,
      settings,
      is_onboarded,
      auth0_id,
      created_at,
    };

    try {
      const updateUserResult = await updateUser(updatedUser);

      if (updateUserResult) {
        stores.userStore.currentUser.name = updatedUser.name;
        stores.userStore.currentUser.phone_number = updatedUser.phone_number;
        stores.userStore.currentUser.picture = updatedUser.picture;
        stores.userStore.currentUser.settings = updatedUser.settings;

        await stores.companyUserStore.getCompanyUsers();

        this.handleShowNotificationModal(
          "Changes have been successfully saved."
        );

        this.setState({ loading: false });
      } else {
        this.handleShowNotificationModal("Failed to save changes.");

        this.setState({ loading: false });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      this.handleShowNotificationModal(
        "An error occurred while saving changes."
      );
      this.setState({ loading: false });
    }
  };

  toggleFineTuneAvatar = () => {
    const companyHasAvatar =
      stores.companyAvatarStore.companyAvatars.length > 0;
    const availableAvatarCount =
      stores.companyStore.selectedUserCompany?.available_avatar_count || 0;

    if (!companyHasAvatar && availableAvatarCount === 0) {
      toast.error(i18n.ToastMessages.avatarCreationRequired, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (availableAvatarCount > 0 && !companyHasAvatar) {
      toast.error(i18n.ToastMessages.avatarCreationRequired, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    this.setState((prevState) => ({
      useFineTuneAvatar: !prevState.useFineTuneAvatar,
    }));
  };

  toggleFineTuneTheChief = () => {
    const { selectedUserCompany } = stores.companyStore;

    if (selectedUserCompany && !selectedUserCompany.is_chief_available) {
      toast.error(i18n.ToastMessages.contactUsForFeature, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return;
    }

    this.setState((prevState) => ({
      useFineTuneTheChief: !prevState.useFineTuneTheChief,
    }));
  };

  toggleEmailNotifications = () => {
    this.setState((prevState) => ({
      receiveEmailNotifications: !prevState.receiveEmailNotifications,
    }));
  };

  toggleAutoSync = () => {
    this.setState((prevState) => ({
      useAutoSync: !prevState.useAutoSync,
    }));
  };

  generateAPIKey = async () => {
    const company = stores.companyStore.selectedUserCompany;
    const currentUser = stores.userStore.currentUser;

    if (!company) {
      toast.error(i18n.ToastMessages.companyNotFoundError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const hasSubscription = await stores.userStore.checkUserSubscription();
    if (!hasSubscription) {
      toast.error(i18n.ToastMessages.subscriptionRequiredError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (!company.is_api_available) {
      toast.error(i18n.ToastMessages.apiKeyFeatureContactUs, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    this.setState({ apiKeyGenerating: true });

    const userId = currentUser.id;
    const subscriptionId = currentUser.subscription?.id || "";

    try {
      const result = await createApiKey(
        Number(company.id),
        Number(userId),
        subscriptionId
      );

      if (result && result.success) {
        this.setState({
          apiKey: result.apiKey || "",
          apiKeyModalIsVisible: true,
          apiKeyGenerating: false,
        });
      } else {
        this.setState({ apiKeyGenerating: false });
        toast.error(i18n.ToastMessages.generateApiKeyError, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeButton: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("API Key Generation Error:", error);
      this.setState({ apiKeyGenerating: false });
      toast.error(i18n.ToastMessages.generateApiKeyError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  render() {
    const {
      data,
      loading,
      togglesLoading,
      apiKeyGenerating,
      apiKey,
      apiKeyModalIsVisible,
      useFineTuneAvatar,
      useFineTuneTheChief,
      receiveEmailNotifications,
      showFineTuneAvatarToggle,
      showFineTuneTheChiefToggle,
      previewUrl,
      modalMessage,
      showNotificationModal,
    } = this.state;

    const isAdmin = stores.userStore.isCurrentUserAdmin;
    const isFreeUser = stores.userStore.isFreeUser;
    const company = stores.companyStore.selectedUserCompany;
    const isApiAvailable =
      stores.companyStore.selectedUserCompany?.is_api_available;

    if (loading || togglesLoading) {
      return (
        <div className="card mb-5 mb-xl-10">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "50vh" }}
          >
            <AccountLoading />
          </div>
        </div>
      );
    }

    return (
      <div className="card mb-5 mb-xl-10">
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">
              {i18n.ProfileDetailsSettings.title}
            </h3>
          </div>
        </div>

        {apiKeyModalIsVisible && company ? (
          <GenerateAPIKeyModal
            apiKey={apiKey}
            onClose={() =>
              this.setState({
                apiKey: "",
                apiKeyGenerating: false,
                apiKeyModalIsVisible: false,
              })
            }
          />
        ) : null}

        <Modal
          show={showNotificationModal}
          onHide={this.handleCloseNotificationModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Notification</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalMessage}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={this.handleCloseNotificationModal}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <div id="kt_account_profile_details" className="collapse show">
          {isAdmin ? (
            <Formik
              initialValues={this.state.data}
              enableReinitialize={true}
              validationSchema={profileDetailsSchema}
              onSubmit={async (values, { resetForm }) => {
                await this.handleSubmit(values);

                this.setState({
                  data: { ...this.state.data, fName: values.fName },
                });
                resetForm({ values: this.state.data });
              }}
            >
              {(formik) => (
                <form
                  onSubmit={formik.handleSubmit}
                  noValidate
                  className="form"
                >
                  <div className="card-body border-top p-9">
                    {/* Avatar Section */}
                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label fw-bold fs-6">
                        {i18n.ProfileDetailsSettings.avatarLabel}
                      </label>
                      <div className="col-lg-8">
                        <div
                          className="image-input image-input-outline"
                          data-kt-image-input="true"
                          style={{
                            backgroundImage: `url(${
                              previewUrl || data.picture
                            })`,
                          }}
                        >
                          <div
                            className="image-input-wrapper w-125px h-125px"
                            style={{
                              backgroundImage: `url(${
                                previewUrl || data.picture
                              })`,
                            }}
                          ></div>
                          <input
                            className="mt-4"
                            type="file"
                            onChange={(e) => {
                              const file = e.target.files
                                ? e.target.files[0]
                                : null;
                              if (file) {
                                this.setState({
                                  selectedFile: file,
                                  previewUrl: URL.createObjectURL(file),
                                });
                                formik.setFieldValue("picture", file);
                              }
                            }}
                            accept="image/*"
                            onClick={() =>
                              ReactGA.event({
                                category: "settings_page",
                                action: "choose_user_profile_image_button",
                                label: "choose_user_profile_image_button",
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {/* Full Name Section */}
                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label required fw-bold fs-6">
                        {i18n.ProfileDetailsSettings.fullNameLabel}
                      </label>

                      <div className="col-lg-8 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          placeholder="Full Name"
                          {...formik.getFieldProps("fName")}
                        />
                        {formik.touched.fName && formik.errors.fName && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.fName}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Receive Email Notifications */}
                    <div className="row mb-6 d-flex align-items-center">
                      <label className="col-lg-4 col-form-label fw-bold fs-6">
                        Receive Email Notifications
                      </label>
                      <div className="col-lg-8 fv-row">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="receiveEmailNotifications"
                            checked={receiveEmailNotifications}
                            onChange={() => {
                              this.toggleEmailNotifications();
                              formik.setFieldValue(
                                "receiveEmailNotifications",
                                !receiveEmailNotifications
                              );
                            }}
                            onClick={() =>
                              ReactGA.event({
                                category: "settings_page",
                                action: "receive_email_notifications_button",
                                label: "receive_email_notifications_button",
                              })
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="receiveEmailNotifications"
                          >
                            {receiveEmailNotifications ? "Enabled" : "Disabled"}
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* Feature Toggles */}
                    {showFineTuneAvatarToggle && (
                      <div className="row mb-6 d-flex align-items-center">
                        <label className="col-lg-4 col-form-label fw-bold fs-6">
                          Use Fine Tune Avatar
                        </label>
                        <div className="col-lg-8 fv-row">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="useFineTuneAvatar"
                              checked={useFineTuneAvatar}
                              onChange={() => {
                                this.toggleFineTuneAvatar();
                                formik.setFieldValue(
                                  "useFineTuneAvatar",
                                  !useFineTuneAvatar
                                );
                              }}
                              onClick={() =>
                                ReactGA.event({
                                  category: "settings_page",
                                  action: "show_fine_tune_avatar_toggle_button",
                                  label: "show_fine_tune_avatar_toggle_button",
                                })
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="useFineTuneAvatar"
                            >
                              {useFineTuneAvatar ? "Enabled" : "Disabled"}
                            </label>
                          </div>
                        </div>
                      </div>
                    )}

                    {showFineTuneTheChiefToggle && (
                      <div className="row mb-6 d-flex align-items-center">
                        <label className="col-lg-4 col-form-label fw-bold fs-6">
                          Use Fine Tune The Chief
                        </label>
                        <div className="col-lg-8 fv-row">
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="useFineTuneTheChief"
                              checked={useFineTuneTheChief}
                              onChange={() => {
                                this.toggleFineTuneTheChief();
                                formik.setFieldValue(
                                  "useFineTuneTheChief",
                                  !useFineTuneTheChief
                                );
                              }}
                              onClick={() =>
                                ReactGA.event({
                                  category: "settings_page",
                                  action:
                                    "show_fine_tune_the_chief_toggle_button",
                                  label:
                                    "show_fine_tune_the_chief_toggle_button",
                                })
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="useFineTuneTheChief"
                            >
                              {useFineTuneTheChief ? "Enabled" : "Disabled"}
                            </label>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* API Key Section */}
                    {!isFreeUser && company && (
                      <div className="row mb-6 d-flex align-items-center">
                        <label className="col-lg-4 col-form-label fw-bold fs-6">
                          API Key
                        </label>

                        <div className="col-lg-8 fv-row">
                          <button
                            type="button"
                            className="btn btn-lg btn-light-warning"
                            disabled={apiKeyGenerating}
                            onClick={() => {
                              if (!company.is_api_available) {
                                toast.error(
                                  i18n.ToastMessages.apiKeyFeatureContactUs,
                                  {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeButton: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                  }
                                );
                              } else {
                                this.generateAPIKey();
                              }
                            }}
                          >
                            {!apiKeyGenerating
                              ? `${i18n.ProfileDetailsSettings.generate}`
                              : `${i18n.ProfileDetailsSettings.pleaseWait}`}
                          </button>
                        </div>
                      </div>
                    )}

                    {/* Integration Section */}
                    {!isFreeUser && company && (
                      <div className="row mb-6 d-flex align-items-center">
                        <label className="col-lg-4 col-form-label fw-bold fs-6">
                          Integration
                        </label>

                        <div className="col-lg-8 fv-row">
                          <a href="https://slack.com/oauth/v2/authorize?client_id=5824836852406.7825566892903&scope=commands,im:history,im:read,im:write,chat:write&user_scope=">
                            <img
                              alt="Add to Slack"
                              height="40"
                              width="139"
                              src="https://platform.slack-edge.com/img/add_to_slack.png"
                              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                            />
                          </a>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="card-footer d-flex justify-content-between py-6 px-9">
                    {isAdmin && (
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={this.props.onShowDeleteModal}
                      >
                        Delete Account
                      </button>
                    )}
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={!formik.dirty || loading}
                      onClick={() =>
                        ReactGA.event({
                          category: "settings_page",
                          action: "save_profile_settings_change_button",
                          label: "save_profile_settings_change_button",
                        })
                      }
                    >
                      {!loading
                        ? `${i18n.ProfileDetailsSettings.saveChanges}`
                        : `${i18n.ProfileDetailsSettings.pleaseWait}`}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          ) : (
            <div className="card-body border-top p-9">
              {!isAdmin && isApiAvailable && !isFreeUser && company && (
                <div className="row mb-6 d-flex align-items-center">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    API Key
                  </label>

                  <div className="col-lg-8 fv-row">
                    <button
                      type="button"
                      className="btn btn-lg btn-light-warning"
                      disabled={apiKeyGenerating}
                      onClick={() => {
                        if (!company.is_api_available) {
                          toast.error(
                            i18n.ToastMessages.apiKeyFeatureContactUs,
                            {
                              position: "top-center",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeButton: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                            }
                          );
                        } else {
                          this.generateAPIKey();
                        }
                      }}
                    >
                      {!apiKeyGenerating
                        ? `${i18n.ProfileDetailsSettings.generate}`
                        : `${i18n.ProfileDetailsSettings.pleaseWait}`}
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
