import { action, observable, when } from "mobx";
import { Crisp } from "crisp-sdk-web";

import {
  createCompany,
  deleteCompany,
  getCompany,
  getCompanyUsers,
  getDateRangeCompanyTokenUsagesGroups,
  getUserCompanies,
  updateCompany,
} from "../../helpers/api";
import { UserRole } from "../models/UserRole";
import { Company } from "../models/Company";
import { CompanyUserStatus } from "../../helpers/Enums";
import Functions from "../../helpers/Functions";

import RootStore from "./RootStore";
import stores from ".";

export type CompanyUserFilter = {
  role: UserRole;
};

export default class CompanyStore {
  @observable userCompanies: Company[] = [];
  @observable selectedUserCompany?: Company;
  @observable freeTokenCount: number = parseInt(
    process.env.REACT_APP_FREE_TOKEN_COUNT
      ? process.env.REACT_APP_FREE_TOKEN_COUNT
      : "25000"
  );
  @observable isLoading: boolean = true;

  constructor(rootStore: RootStore) {
    when(
      () => rootStore.userStore.isCurrentUserReady,
      () => {
        this.getCompanies();
      }
    );
  }

  @action getCompanies = async () => {
    this.isLoading = true;

    const companies = await getUserCompanies(stores.userStore.currentUser.id);

    if (companies.length > 0) {
      this.userCompanies = companies;
      this.selectedUserCompany = companies[0];
    } else if (stores.userStore.currentUserInvitation) {
      const company = await getCompany(
        stores.userStore.currentUserInvitation.company_id
      );

      if (company) {
        this.userCompanies = [company];
        this.selectedUserCompany = company;
      }
    }

    if (this.selectedUserCompany) {
      this.checkCompanyFreeToken();

      const companyUsers = await getCompanyUsers(this.selectedUserCompany.id);

      const currentUserIndex = companyUsers.findIndex(
        (item) =>
          item.user_id.toString() === stores.userStore.currentUser.id.toString()
      );

      if (currentUserIndex !== -1) {
        stores.userStore.isCurrentUserAdmin =
          companyUsers[currentUserIndex].user_role.id.toString() === "1";
      }

      // Check user timezone
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (
        this.selectedUserCompany &&
        (!this.selectedUserCompany ||
          this.selectedUserCompany.timezone !== timezone)
      ) {
        this.selectedUserCompany.timezone = timezone;
        this.updateCompany(this.selectedUserCompany);
      }
    }

    if (stores.userStore.currentUser && this.selectedUserCompany) {
      Crisp.user.setEmail(
        stores.userStore.currentUser.email
          ? stores.userStore.currentUser.email
          : "User Without Email"
      );
      Crisp.user.setNickname(
        stores.userStore.currentUser.name
          ? stores.userStore.currentUser.name
          : "Anonymous User"
      );
      Crisp.user.setAvatar(
        stores.userStore.currentUser.picture
          ? stores.userStore.currentUser.picture
          : ""
      );

      Crisp.session.setData({
        user_id: stores.userStore.currentUser.id,
        oauth0_id: stores.userStore.currentUser.auth0_id,
        stripe_id: stores.userStore.currentUser.stripeProfile?.id,
        company_id: this.selectedUserCompany?.id,
      });
    }

    this.isLoading = false;
  };

  @action createCompany(
    department_id: number,
    name: string,
    logo: string,
    teamSize: string,
    industry: string,
    plan: string,
    available_avatar_count: number,
    website: string
  ): Promise<Company> {
    return new Promise(async (resolve, reject) => {
      try {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        const createdCompany = await createCompany(
          stores.userStore.currentUser.id,
          department_id,
          name,
          logo,
          teamSize,
          industry,
          plan,
          timezone,
          available_avatar_count,
          website
        );

        if (!this.selectedUserCompany) {
          this.selectedUserCompany = createdCompany;
        }

        this.userCompanies.push(createdCompany);

        await stores.companyUserStore.createCompanyUser(
          stores.userStore.currentUser.id,
          createdCompany.id,
          1,
          CompanyUserStatus.Approved
        );

        stores.userStore.isCurrentUserAdmin = true;

        resolve(createdCompany);
      } catch (error) {
        console.error("Create company error: ", error);

        reject(error);
      }
    });
  }

  @action updateCompany(company: Company): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        await updateCompany(company);

        const existingCompanyIndex = this.userCompanies.findIndex(
          (item) => item.id === company.id
        );

        if (existingCompanyIndex !== -1) {
          this.userCompanies[existingCompanyIndex] = company;
        }

        resolve(true);
      } catch (error) {
        console.error("Error during updating company in CompanyStore:", error);
        reject(error);
      }
    });
  }

  @action deleteCompany(companyId: number): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      await deleteCompany(companyId);

      this.userCompanies = this.userCompanies.filter(
        (item) => item.id !== companyId
      );

      resolve(true);
    });
  }

  @action checkCompanyFreeToken = async () => {
    if (this.selectedUserCompany) {
      const companyTokenUsageSummaries =
        await getDateRangeCompanyTokenUsagesGroups(
          this.selectedUserCompany.id,
          Functions.convertTimestampToDate(this.selectedUserCompany.created_at),
          Functions.convertTimestampToDate(new Date().getTime())
        );

      if (companyTokenUsageSummaries.length > 0) {
        let usedTokenCount = 0;

        companyTokenUsageSummaries.forEach((companyTokenUsageSummary) => {
          usedTokenCount += companyTokenUsageSummary.total_token_count;
        });

        this.freeTokenCount =
          parseInt(
            process.env.REACT_APP_FREE_TOKEN_COUNT
              ? process.env.REACT_APP_FREE_TOKEN_COUNT
              : "25000"
          ) - usedTokenCount;
      } else {
        this.freeTokenCount = parseInt(
          process.env.REACT_APP_FREE_TOKEN_COUNT
            ? process.env.REACT_APP_FREE_TOKEN_COUNT
            : "25000"
        );
      }
    }
  };
}
