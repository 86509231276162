/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { DocumentMessage } from "../../app/models/DocumentMessage";

export default async function getDocumentMessages(
  user_document_id: number
): Promise<DocumentMessage[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query DocumentMessagesQuery($user_document_id: ID!) {
        documentMessages(user_document_id: $user_document_id) {
          id
          user_document_id
          company_app_id
          company_avatar_id
          company_model_id
          message
          message_reply
          source_documents
          created_at
          is_liked
          user_document {
            id
            name
            created_at
          }
          company_app {
            id
            app_id
            company_id
            app {
              name
            }
          }
          company_avatar {
            id
            company_id
            name
            logo_url
          }
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          user_document_id: user_document_id,
        },
        fetchPolicy: "no-cache",
      })
      .then((documentMessagesQueryResult: any) => {
        const documentMessages: DocumentMessage[] = get(
          documentMessagesQueryResult,
          "data.documentMessages",
          null
        );

        for (let index = 0; index < documentMessages.length; index++) {
          const documentMessage = documentMessages[index];

          if (documentMessage.is_liked !== null) {
            documentMessages[index].is_liked = documentMessage.is_liked
              ? true
              : false;
          }
        }

        resolve(documentMessages);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
