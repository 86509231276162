import { action, observable, when } from "mobx";
import { toast } from "react-toastify";

import { Department } from "../models/Department";
import getDepartments from "../../helpers/api/getDepartments";
import { CompanyUserDepartment } from "../models/CompanyUserDepartment";
import {
  createCompanyUserDepartment,
  deleteCompanyUserDepartment,
  getCompanyUserDepartments,
} from "../../helpers/api";

import RootStore from "./RootStore";

export default class CompanyDepartmentStore {
  @observable departments: Department[] = [];
  @observable isLoading = false;
  @observable selectedDepartment: Department | null = null;

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    when(
      () => this.rootStore.companyStore.selectedUserCompany !== undefined,
      () => {
        this.loadDepartments();
      }
    );
  }

  @action loadDepartments = async () => {
    this.isLoading = true;
    try {
      const departments = await getDepartments();
      this.departments = departments;
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
      console.error("Failed to load departments", error);
    }
  };

  @action selectDepartment = (department: Department) => {
    this.selectedDepartment = department;
    if (department) {
      toast.info(`Department ${department.name} selected.`, {
        position: "top-center",
        autoClose: 3000,
        closeButton: true,
      });
    }
  };

  @action getDepartmentById = (id: number): Department | undefined => {
    const department = this.departments.find((dept) => dept.id === id);
    if (!department) {
      toast.error("Department not found.", {
        position: "top-center",
        autoClose: 3000,
        closeButton: true,
      });
    }
    return department;
  };
  @action addCompanyUserDepartments(
    companyUserId: number,
    departments: Department[]
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      for (let index = 0; index < departments.length; index++) {
        const department = departments[index];

        await createCompanyUserDepartment(companyUserId, Number(department.id));
        if (department) {
          await getCompanyUserDepartments(companyUserId);
        }
      }
      await this.loadDepartments();

      resolve(true);
    });
  }

  @action createCompanyUserDepartments(
    companyUserId: number,
    departmentids: number[]
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      for (let index = 0; index < departmentids.length; index++) {
        const department = departmentids[index];
        await createCompanyUserDepartment(companyUserId, department);
      }
      resolve(true);
    });
  }

  @action deleteCompanyUserDepartments(
    companyUserDepartments: CompanyUserDepartment[]
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      for (let index = 0; index < companyUserDepartments.length; index++) {
        const companyUserDepartment = companyUserDepartments[index];

        await deleteCompanyUserDepartment(companyUserDepartment.id as number);
        if (companyUserDepartment) {
          await getCompanyUserDepartments(
            companyUserDepartment.company_user_id as number
          );
        }
      }
      await this.loadDepartments();

      resolve(true);
    });
  }
}
