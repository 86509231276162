import React from "react";
import { Position, NodeProps, Node, Edge } from "@xyflow/react";

import CustomHandle from "../handles/CustomHandle";

interface ResultNodeProps extends NodeProps {
  data: any;
  nodes: Node[];
  edges: Edge[];
}

const ResultNode: React.FC<ResultNodeProps> = (props) => {
  return (
    <div
      style={{
        padding: "10px",
        border: "1px solid #ddd",
        borderRadius: "5px",
        backgroundColor: "#f4f4f4",
      }}
    >
      <div>
        <strong>Result:</strong>
        <div>{props.data.label}</div>
      </div>

      <CustomHandle
        type="source"
        id="result_node_source_1"
        position={Position.Top}
        connectionCount={1}
        nodes={props.nodes}
        edges={props.edges}
      />

      <CustomHandle
        type="target"
        id="result_node_target_1"
        position={Position.Bottom}
        connectionCount={1}
        nodes={props.nodes}
        edges={props.edges}
      />
    </div>
  );
};

export default ResultNode;
