/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTIcon } from "../../../helpers";

type Task = {
  iconName: string;
  iconBgColor: string;
  iconColor: string;
  taskTitle: string;
  taskRole: string;
};

type Props = {
  className: string;
  tasks: Task[];
  overviewTitle?: string;
  overviewSubtitle?: string;
};

const ListsWidget1: React.FC<Props> = ({
  className,
  tasks = [],
  overviewTitle = "Tasks Overview",
  overviewSubtitle = "Pending 10 tasks",
}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-dark">{overviewTitle}</span>
          <span className="text-muted mt-1 fw-semibold fs-7">
            {overviewSubtitle}
          </span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body pt-5">
        {tasks.map((task, index) => (
          <div className="d-flex align-items-center mb-7" key={index}>
            {/* begin::Symbol */}
            <div className="symbol symbol-50px me-5">
              <span className={`symbol-label ${task.iconBgColor}`}>
                <KTIcon
                  iconName={task.iconName}
                  className={`fs-2x ${task.iconColor}`}
                />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Text */}
            <div className="d-flex flex-column">
              <a href="#" className="text-dark text-hover-primary fs-6 fw-bold">
                {task.taskTitle}
              </a>
              <span className="text-muted fw-semibold">{task.taskRole}</span>
            </div>
            {/* end::Text */}
          </div>
        ))}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { ListsWidget1 };
