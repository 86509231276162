/* @flow */

import stores from "../../app/stores";

export type DeleteAvatarIndexDataResult = {
  success: boolean;
  message: string;
};

export default async function deleteAvatarIndex(
  companyId: number,
  companyAvatarId: number,
  companyStorageId: number
): Promise<DeleteAvatarIndexDataResult> {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/deleteAvatarIndex`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "client-id": stores.userStore.socketClientId,
        },
        body: JSON.stringify({
          companyId: companyId,
          companyAvatarId: companyAvatarId,
          companyStorageId: companyStorageId,
        }),
        credentials:
          process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
      }
    );

    if (!response.ok) {
      throw new Error(`API request failed with status: ${response.status}`);
    }

    const data = await response.json();

    if (data.success) {
      return {
        success: true,
        message: data.message || "Index deleted successfully.",
      };
    } else {
      return {
        success: false,
        message: data.message || "Failed to delete index.",
      };
    }
  } catch (error) {
    console.error("Failed to delete avatar index:", error);
    return {
      success: false,
      message: "An error occurred while deleting the index.",
    };
  }
}
