import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import type { DashboardSection } from "../../app/models/DashboardSection";

export default async function createDashboardSection(
  section: DashboardSection
): Promise<DashboardSection> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const mutation = gql`
      mutation CreateDashboardSection(
        $company_id: Int!
        $section_name: String!
        $section_description: String
        $is_manager_section: Boolean!
        $order: Int!
        $section_preferences: String
        $view: String
        $suggestion_view: String
        $nodes: String
        $edges: String
      ) {
        createDashboardSection(
          company_id: $company_id
          section_name: $section_name
          section_description: $section_description
          is_manager_section: $is_manager_section
          order: $order
          section_preferences: $section_preferences
          view: $view
          suggestion_view: $suggestion_view
          nodes: $nodes
          edges: $edges
        ) {
          id
          company_id
          section_name
          section_description
          is_manager_section
          order
          section_preferences
          view
          suggestion_view
          nodes
          edges
        }
      }
    `;

    try {
      const variables = {
        company_id: section.company_id,
        section_name: section.section_name,
        section_description: section.section_description,
        is_manager_section: section.is_manager_section,
        order: section.order,
        section_preferences: section.section_preferences,
        view: section.view,
        suggestion_view: section.suggestion_view,
        nodes: section.nodes
          ? encodeURIComponent(JSON.stringify(section.nodes))
          : null,
        edges: section.edges
          ? encodeURIComponent(JSON.stringify(section.edges))
          : null,
      };

      const createDashboardSectionMutationResult = await client.mutate({
        mutation,
        variables,
      });

      const dashboardSection: DashboardSection = get(
        createDashboardSectionMutationResult,
        "data.createDashboardSection",
        null
      );

      if (dashboardSection) {
        resolve(dashboardSection);
      } else {
        reject(new Error("Failed to create dashboard section"));
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}
