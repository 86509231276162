import { gql } from "@apollo/client";
import ApolloClientHelper from "../ApolloClientHelper";
import type { CompanyAvatar } from "../../app/models/CompanyAvatar";

export default async function updateCompanyAvatar(
  companyavatar: Partial<CompanyAvatar>
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const id = Number(companyavatar.id);
  const company_id = Number(companyavatar.company_id);

  const mutation = gql`
    mutation UpdateCompanyAvatar(
      $id: ID!
      $company_id: Int!
      $dashboard_section_id: Int
      $name: String!
      $description: String
      $logo_url: String
      $status: String!
      $last_sync_at: Float
    ) {
      updateCompanyAvatar(
        id: $id
        company_id: $company_id
        dashboard_section_id: $dashboard_section_id
        name: $name
        description: $description
        logo_url: $logo_url
        status: $status
        last_sync_at: $last_sync_at
      )
    }
  `;

  try {
    const { data } = await client.mutate({
      mutation,
      variables: {
        id: id,
        company_id: company_id,
        dashboard_section_id: companyavatar.dashboard_section_id,
        name: companyavatar.name,
        description: companyavatar.description,
        logo_url: companyavatar.logo_url,
        status: companyavatar.status,
        last_sync_at: companyavatar.last_sync_at,
      },
    });

    return !!data.updateCompanyAvatar;
  } catch (error) {
    console.error("Error updating CompanyAvatar:", error);
    throw new Error("Failed to update CompanyAvatar.");
  }
}
