/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { observer } from "mobx-react";
import ReactGA from "react-ga4";

import stores from "../../stores";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import {
  CompanyAppStatus,
  AppStatus,
  FeatureType,
} from "../../../helpers/Enums";
import { CompanyApp } from "../../models/CompanyApp";
import { App } from "../../models/App";
import Functions from "../../../helpers/Functions";
import { ConnectionCredential } from "../../models/ConnectionCredential";
import { KTIcon } from "../../../helpers";

import ConnectAppModal from "./ConnectAppModal";
import AlertModal from "./AlertModal";
import { toast } from "react-toastify";
import i18n from "../../../i18n";

import { Dropdown, Button, Form } from "react-bootstrap";

type Props = {};

type State = {
  selectedApp?: App;
  isConnectAppModalOpened: boolean;
  isAlertModalOpened: boolean;
  searchQuery: string;
  selectedAppStatuses: AppStatus[];
  selectedStatuses: string[];
};

@observer
export default class ConnectionsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isConnectAppModalOpened: false,
      isAlertModalOpened: false,
      searchQuery: "",
      selectedAppStatuses: [],
      selectedStatuses: [],
    };
  }

  componentWillMount() {
    const savedAppStatuses = JSON.parse(
      localStorage.getItem("selectedAppStatuses") || "[]"
    );
    const savedStatuses = JSON.parse(
      localStorage.getItem("selectedStatuses") || "[]"
    );

    this.setState({
      selectedAppStatuses: savedAppStatuses,
      selectedStatuses: savedStatuses,
    });
  }

  private handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: event.target.value });
  };

  private handleSyncApp = async (app: App) => {
    ReactGA.event({
      category: "connections_page",
      action: "sync_connect_app",
      label: "sync_connect_app_button",
    });
    const appIndex = stores.companyAppStore.companyApps.findIndex(
      (companyApp) => companyApp.app.id === app.id
    );

    if (appIndex !== -1) {
      if (
        stores.companyStore.selectedUserCompany &&
        stores.companyStore.selectedUserCompany !== undefined
      ) {
        await stores.companyAppStore.syncCompanyAppData(
          stores.companyAppStore.companyApps[appIndex]
        );
      }
    }
  };

  private handleAppStatusCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const status = event.target.value as AppStatus;
    const { selectedAppStatuses } = this.state;

    const updatedStatuses = event.target.checked
      ? [...selectedAppStatuses, status]
      : selectedAppStatuses.filter((s) => s !== status);

    this.setState({ selectedAppStatuses: updatedStatuses }, () => {
      localStorage.setItem(
        "selectedAppStatuses",
        JSON.stringify(updatedStatuses)
      );
    });
  };

  private handleStatusCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const status = event.target.value;
    const { selectedStatuses } = this.state;

    const updatedStatuses = event.target.checked
      ? [...selectedStatuses, status]
      : selectedStatuses.filter((s) => s !== status);

    this.setState({ selectedStatuses: updatedStatuses }, () => {
      localStorage.setItem("selectedStatuses", JSON.stringify(updatedStatuses));
    });
  };

  private handleClearFilters = () => {
    this.setState(
      {
        selectedAppStatuses: [],
        selectedStatuses: [],
      },
      () => {
        localStorage.removeItem("selectedAppStatuses");
        localStorage.removeItem("selectedStatuses");
      }
    );
  };

  private handleConnectClick = async (credential?: ConnectionCredential) => {
    const { selectedApp } = this.state;

    ReactGA.event({
      category: "connections_page",
      action: "connect_app_modal_button",
      label: "connect_app_modal_button",
    });

    const hasConnectAppPermission =
      await stores.userStore.checkSubscribedFeatureType(FeatureType.ConnectApp);

    if (!hasConnectAppPermission) {
      toast.error(i18n.ToastMessages.maxCompanyError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      this.setState({ isConnectAppModalOpened: false });
      return;
    }

    if (
      selectedApp &&
      stores.companyStore.selectedUserCompany &&
      credential &&
      selectedApp.is_source_available === 0
    ) {
      this.setState({ isConnectAppModalOpened: false });

      const createdCompanyApp = await stores.companyAppStore.createCompanyApp(
        selectedApp,
        credential.connectionId
      );

      if (
        createdCompanyApp.status === CompanyAppStatus.Connected &&
        selectedApp.is_source_available === 0
      ) {
        await this.handleSyncApp(selectedApp);
      }
    }
  };

  private handleUpdateClick = async (
    companyApp: CompanyApp,
    credential?: ConnectionCredential
  ) => {
    const companyAppIndex = stores.companyAppStore.companyApps.findIndex(
      (item) => item.id === companyApp.id
    );

    if (companyAppIndex !== -1) {
      await stores.companyAppStore.deleteCompanyApp(
        stores.companyAppStore.companyApps[companyAppIndex]
      );
    }

    this.handleConnectClick(credential);
  };

  private handleDisconnectClick = async (companyApp: CompanyApp) => {
    const companyAppIndex = stores.companyAppStore.companyApps.findIndex(
      (item) => item.id === companyApp.id
    );

    if (companyAppIndex !== -1) {
      this.setState({ isConnectAppModalOpened: false });
    }

    await stores.companyAppStore.deleteCompanyApp(
      stores.companyAppStore.companyApps[companyAppIndex]
    );
  };

  renderAppStatusBadge = (status: AppStatus) => {
    let iconPath = "";
    let badgeClass = "";
    let labelText = "";

    switch (status) {
      case AppStatus.Verified:
        iconPath = "/media/icons/duotune/general/gen048.svg";
        badgeClass = "text-gray-600 text-hover-primary";
        labelText = "Verified";
        break;
      case AppStatus.InReview:
        iconPath = "/media/icons/duotune/general/gen049.svg";
        badgeClass = "text-gray-600 text-hover-warning";
        labelText = "In Review";
        break;
      case AppStatus.Unverified:
        iconPath = "/media/icons/duotune/general/gen050.svg";
        badgeClass = "text-gray-600 text-hover-danger";
        labelText = "Unverified";
        break;
      default:
        return null;
    }

    return (
      <span className={`${badgeClass} fs-8 ms-2 mb-1 fw-bold`}>
        <KTSVG path={iconPath} className="svg-icon svg-icon-3 me-1" />
        {labelText}
      </span>
    );
  };

  private renderAppItem = (app: App, index: number) => {
    const companyApp = stores.companyAppStore.companyApps.find(
      (companyApp) => companyApp.app_id.toString() === app.id.toString()
    );

    const departments =
      app.departments?.map((app_department) => (
        <span
          key={app_department.department.id}
          style={{
            backgroundColor: Functions.lightenColor(
              app_department.department.color,
              70
            ),
            color: app_department.department.color,
            padding: "2px 6px",
            borderRadius: "4px",
            marginRight: "2px",
            marginBottom: "2px",
            display: "inline-block",
          }}
        >
          {app_department.department.name}
        </span>
      )) || "";

    return (
      <div
        key={app.id}
        className="d-flex align-items-sm-center mb-7 pb-7 border-bottom"
      >
        <div className="me-2 position-relative">
          <div className="symbol symbol-90px me-1">
            <span className="symbol-label">
              <img
                src={toAbsoluteUrl(app.logo)}
                className="w-50 align-self-center"
                alt=""
              />
            </span>
          </div>
        </div>

        <div className="d-flex flex-row-fluid align-items-center">
          <div className="flex-grow-1 me-2">
            <span className="text-gray-800 fw-bold text-hover-primary fs-4 d-flex align-items-center">
              {app.name}
              {this.renderAppStatusBadge(app.status)}
            </span>
            <span className="d-block pt-1">{departments}</span>

            <span
              className="d-block pt-1"
              style={{ flex: 1, alignItems: "end" }}
            >
              {companyApp &&
              companyApp.status !== CompanyAppStatus.Failed &&
              companyApp.last_sync_at === 0 ? (
                <span className="fs-8 text-warning fw-bolder">Syncing..</span>
              ) : companyApp && companyApp.last_sync_at === 0 ? (
                <span className="fs-8 text-danger fw-bolder">Not Synced</span>
              ) : companyApp &&
                companyApp.status === CompanyAppStatus.Failed ? (
                <span className="fs-8 text-danger fw-bolder">Failed</span>
              ) : !companyApp ? (
                <span className="fs-8 text-danger fw-bolder"></span>
              ) : (
                <span className="d-flex mt-1 mb-1">
                  <span className="badge badge-light p-3 text-gray-700 fs-8 fw-bolder">
                    {`Last sync: ${Functions.getPastTimeInfo(
                      companyApp.last_sync_at
                    )}`}
                  </span>
                </span>
              )}
            </span>

            <span
              style={{ maxWidth: "35vw" }}
              className="text-gray-600 fw-semibold d-block pt-1 ps-1"
            >
              {app.description}
            </span>
          </div>

          <div className="d-flex ms-3">
            {companyApp ? (
              <>
                <button
                  className="btn btn-flex flex-center bg-body btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6"
                  onClick={() => {
                    this.setState({ selectedApp: app }, () => {
                      this.setState({ isConnectAppModalOpened: true });
                    });
                    ReactGA.event({
                      category: "connections_page",
                      action: "edit_connect_app",
                      label: "edit_connect_app_button",
                    });
                  }}
                >
                  <KTSVG
                    path={"/media/icons/duotune/art/art005.svg"}
                    className="svg-icon svg-icon-x text-gray me-3"
                  />

                  <span className="d-none d-md-inline">Edit</span>
                </button>
                {companyApp &&
                companyApp.last_sync_at !== 0 &&
                companyApp.app.name !== "Google Drive" &&
                companyApp.app.name !== "Google Docs" &&
                companyApp.app.name !== "Google Sheets" ? (
                  <button
                    className="btn btn-flex flex-center bg-light-info btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6 ms-2"
                    onClick={() => {
                      this.handleSyncApp(app);
                    }}
                  >
                    <KTSVG
                      path={"/media/icons/duotune/arrows/arr029.svg"}
                      className="svg-icon svg-icon-x text-gray me-3"
                    />
                    <span className="d-none d-md-inline">Sync</span>
                  </button>
                ) : null}
              </>
            ) : !companyApp ? (
              <button
                disabled={!app.published}
                className={`btn btn-flex flex-center ${
                  app.published
                    ? "btn-primary"
                    : "bg-light-info btn-color-gray-700 btn-active-color-primary text-nowrap"
                } w-40px w-md-auto h-40px px-0 px-md-6 text-nowrap`}
                onClick={async () => {
                  if (stores.companyModelStore.companyModels.length === 0) {
                    toast.error(i18n.ToastMessages.companyModelMissing, {
                      position: "top-center",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeButton: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                    return;
                  }

                  const hasConnectAppPermission =
                    await stores.userStore.checkSubscribedFeatureType(
                      FeatureType.ConnectApp
                    );

                  ReactGA.event({
                    category: "connections_page",
                    action: "connect_app_button",
                    label: "connect_app_button",
                  });
                  if (!hasConnectAppPermission) {
                    toast.error(i18n.ToastMessages.maxCompanyError, {
                      position: "top-center",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeButton: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                    this.setState({ isConnectAppModalOpened: false });
                    return;
                  }

                  this.setState({ selectedApp: app }, () => {
                    if (!stores.companyStorageStore.selectedCompanyStorage) {
                      this.setState({ isAlertModalOpened: true });
                    } else {
                      this.setState({ isConnectAppModalOpened: true });
                    }
                  });
                }}
              >
                <KTSVG
                  path={"/media/icons/duotune/coding/cod008.svg"}
                  className="svg-icon-muted svg-icon-x me-3"
                />
                {app.published ? "Connect" : "Coming Soon..."}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      isConnectAppModalOpened,
      isAlertModalOpened,
      selectedApp,
      searchQuery,
      selectedAppStatuses,
      selectedStatuses,
    } = this.state;

    const filteredApps = stores.companyAppStore.apps.filter((app) => {
      const matchesSearch = app.name
        .toLowerCase()
        .includes(searchQuery.toLowerCase());

      const matchesAppStatus =
        selectedAppStatuses.length === 0 ||
        selectedAppStatuses.includes(app.status);

      const companyApp = stores.companyAppStore.companyApps.find(
        (companyApp) => companyApp.app_id.toString() === app.id.toString()
      );

      const isConnected = !!companyApp;

      let matchesStatus = true;

      if (selectedStatuses.length > 0) {
        matchesStatus = false;

        if (selectedStatuses.includes("Connected") && isConnected) {
          matchesStatus = true;
        }
        if (
          selectedStatuses.includes("Not Connected") &&
          !isConnected &&
          app.published
        ) {
          matchesStatus = true;
        }
      }

      return matchesSearch && matchesAppStatus && matchesStatus;
    });

    const sortedApps = filteredApps.slice().sort((a, b) => {
      if (a.published === b.published) {
        return 0;
      }
      return a.published ? -1 : 1;
    });

    return (
      <div className="d-flex flex-column flex-lg-row">
        <div className="flex-lg-row-fluid">
          <div className="card ms-8 me-12">
            <div className="card-header align-items-center border-0 mt-4">
              <h3 className="card-title align-items-start flex-column">
                <span className="fw-bolder text-dark fs-2">Connections</span>

                <span className="text-gray-600 mt-4 fw-semibold fs-6">
                  {stores.userStore.isFreeUser
                    ? "Free users can connect up to 3 apps. Upgrade your plan to connect more applications."
                    : "Manage your real-time data flows by establishing the connection between your applications and CompanyDNA."}
                  {stores.userStore.isFreeUser && (
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#kt_modal_upgrade_plan"
                      className="text-primary fw-bold ms-2"
                    >
                      Upgrade Plan
                    </a>
                  )}
                </span>
              </h3>

              <div className="d-flex align-items-center mt-3">
                <div className="position-relative">
                  <KTIcon
                    iconName="magnifier"
                    className="fs-3 position-absolute p-4"
                  />
                  <input
                    type="text"
                    data-kt-user-table-filter="search"
                    className="form-control border-0 w-250px ps-12"
                    placeholder="Search App..."
                    value={searchQuery}
                    onChange={this.handleSearchChange}
                  />
                </div>
                <div className="ms-auto">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="primary"
                      id="filterDropdown"
                      className="btn btn-icon btn-light-primary d-flex align-items-center mx-2"
                    >
                      <KTSVG
                        path={"/media/icons/duotune/general/gen031.svg"}
                        className="svg-icon svg-icon-2"
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      className="py-5 px-7 w-300px overflow-auto"
                      style={{ maxHeight: "400px" }}
                    >
                      <div className="mb-5">
                        <h6 className="text-dark fw-bold mb-3">Status</h6>
                        <Form.Check
                          type="checkbox"
                          id="status-connected"
                          label="Connected"
                          value="Connected"
                          checked={selectedStatuses.includes("Connected")}
                          onChange={this.handleStatusCheckboxChange}
                          className="mb-2"
                        />
                        <Form.Check
                          type="checkbox"
                          id="status-not-connected"
                          label="Not Connected"
                          value="Not Connected"
                          checked={selectedStatuses.includes("Not Connected")}
                          onChange={this.handleStatusCheckboxChange}
                          className="mb-2"
                        />
                      </div>

                      <div className="border-top pt-5">
                        <h6 className="text-dark fw-bold mb-3">App Status</h6>
                        {Object.values(AppStatus)
                          .filter((status) => status !== AppStatus.None)
                          .map((status) => (
                            <Form.Check
                              type="checkbox"
                              key={status}
                              id={`appStatus-${status}`}
                              label={status}
                              value={status}
                              checked={selectedAppStatuses.includes(status)}
                              onChange={this.handleAppStatusCheckboxChange}
                              className="mb-2"
                            />
                          ))}
                      </div>

                      <div className="mt-5 d-flex justify-content-end">
                        <Button
                          variant="light"
                          onClick={this.handleClearFilters}
                        >
                          Clear Filters
                        </Button>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>

            <div className="card-body pt-5">
              {!stores.companyAppStore.isLoading
                ? sortedApps.map((app, index) => this.renderAppItem(app, index))
                : null}
            </div>
          </div>
        </div>

        {isConnectAppModalOpened && selectedApp ? (
          <ConnectAppModal
            app={selectedApp}
            onCancel={() => {
              this.setState({ isConnectAppModalOpened: false });

              ReactGA.event({
                category: "connections_page",
                action: "cancel_connect_app_modal",
                label: "cancel_connect_app_modal_button",
              });
            }}
            onConnect={this.handleConnectClick}
            onUpdate={this.handleUpdateClick}
            onDisconnect={this.handleDisconnectClick}
          />
        ) : null}

        {isAlertModalOpened ? (
          <AlertModal
            onClick={() => this.setState({ isAlertModalOpened: false })}
          />
        ) : null}
      </div>
    );
  }
}
