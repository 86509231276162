/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { KTIcon } from "../../../helpers";
import { getCSSVariableValue } from "../../../assets/ts/_utils";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";
import { useThemeMode } from "../../layout/theme-mode/ThemeModeProvider";

type Props = {
  className: string; // Custom class name for styling
  chartHeight: string; // Height of the chart
  chartColor: string; // Chart's primary color
};

const MixedWidget6: React.FC<Props> = ({
  className,
  chartHeight,
  chartColor,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();

  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartHeight, chartColor)
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, mode]);

  return (
    <div className={`card ${className}`}>
      {/* Header */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Sales Overview</span>
          <span className="text-muted fw-semibold fs-7">
            Recent sales statistics
          </span>
        </h3>
        <div className="card-toolbar">
          <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <KTIcon iconName="category" className="fs-2" />
          </button>
          <Dropdown1 />
        </div>
      </div>

      {/* Body */}
      <div className="card-body p-0 d-flex flex-column">
        {/* Stats */}
        <div className="card-p pt-5 bg-body flex-grow-1">
          <div className="row g-0">
            <div className="col mr-8">
              <div className="fs-7 text-muted fw-semibold">Average Sale</div>
              <div className="d-flex align-items-center">
                <div className="fs-4 fw-bold">$650</div>
                <KTIcon
                  iconName="arrow-up"
                  className="fs-5 text-success ms-1"
                />
              </div>
            </div>
            <div className="col">
              <div className="fs-7 text-muted fw-semibold">Commission</div>
              <div className="fs-4 fw-bold">$233,600</div>
            </div>
          </div>
          <div className="row g-0 mt-8">
            <div className="col mr-8">
              <div className="fs-7 text-muted fw-semibold">
                Annual Taxes 2019
              </div>
              <div className="fs-4 fw-bold">$29,004</div>
            </div>
            <div className="col">
              <div className="fs-7 text-muted fw-semibold">Annual Income</div>
              <div className="d-flex align-items-center">
                <div className="fs-4 fw-bold">$1,480,00</div>
                <KTIcon
                  iconName="arrow-down"
                  className="fs-5 text-danger ms-1"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Chart */}
        <div
          ref={chartRef}
          className="mixed-widget-3-chart card-rounded-bottom"
          data-kt-chart-color={chartColor}
          style={{ height: chartHeight }}
        ></div>
      </div>
    </div>
  );
};

const chartOptions = (chartHeight: string, chartColor: string): ApexOptions => {
  const labelColor = getCSSVariableValue("--bs-gray-800");
  const baseColor = getCSSVariableValue("--bs-" + chartColor);
  const lightColor = getCSSVariableValue("--bs-" + chartColor + "-light");

  return {
    series: [
      {
        name: "Net Profit",
        data: [30, 25, 45, 30, 55, 55],
      },
    ],
    chart: {
      fontFamily: "inherit",
      type: "area",
      height: chartHeight,
      toolbar: { show: false },
      zoom: { enabled: false },
      sparkline: { enabled: true },
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      labels: { show: false, style: { colors: labelColor, fontSize: "12px" } },
      axisTicks: { show: false },
      axisBorder: { show: false },
    },
    yaxis: {
      min: 0,
      max: 60,
      labels: { show: false, style: { colors: labelColor, fontSize: "12px" } },
    },
    tooltip: {
      y: { formatter: (val) => `$${val} thousands` },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  };
};

export { MixedWidget6 };
