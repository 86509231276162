/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { SectionKpiQuestionReport } from "../../app/models/SectionKpiQuestionReport";

export default async function getSectionKpiQuestionReportsByDashboard(
  dashboard_section_id: number
): Promise<SectionKpiQuestionReport[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query SectionKpiQuestionReportsByDashboardQuery(
        $dashboard_section_id: ID!
      ) {
        sectionKpiQuestionReportsByDashboard(
          dashboard_section_id: $dashboard_section_id
        ) {
          id
          dashboard_section_id
          section_kpi_question_id
          report
          reported_at
          is_final_report
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          dashboard_section_id: dashboard_section_id,
        },
        fetchPolicy: "no-cache",
      })
      .then((sectionKpiQuestionReportsByDashboardQueryResult: any) => {
        const sectionKpiQuestionReports: SectionKpiQuestionReport[] = get(
          sectionKpiQuestionReportsByDashboardQueryResult,
          "data.sectionKpiQuestionReportsByDashboard",
          null
        );

        resolve(sectionKpiQuestionReports);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
