import React from "react";
import { Position, NodeProps, Node, Edge } from "@xyflow/react";

import CustomHandle from "../handles/CustomHandle";

interface FinalResultNodeProps extends NodeProps {
  nodes: Node[];
  edges: Edge[];
}

export default function FinalResultNode(props: FinalResultNodeProps) {
  return (
    <div
      style={{
        padding: "10px",
        border: "1px solid #ddd",
        borderRadius: "5px",
        backgroundColor: "#bfffbf",
      }}
    >
      <div>
        <strong>Final Result</strong>
      </div>

      <CustomHandle
        type="source"
        id="final_result_node_source_1"
        position={Position.Top}
        connectionCount={1}
        nodes={props.nodes}
        edges={props.edges}
      />
    </div>
  );
}
