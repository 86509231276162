import React, { useCallback } from "react";
import { Position, NodeProps, Node, Edge, useReactFlow } from "@xyflow/react";

import CustomHandle from "../handles/CustomHandle";

interface QuestionNodeProps extends NodeProps {
  data: any;
  nodes: Node[];
  edges: Edge[];
  setNodes: (setter: (nodes: Node[]) => Node[]) => void;
}

const QuestionNode: React.FC<QuestionNodeProps> = (props) => {
  const { id, data, setNodes } = props;
  const { deleteElements } = useReactFlow();

  const handleUpdate = useCallback(
    (newValue: string) => {
      setNodes((prevNodes) =>
        prevNodes.map((node) =>
          node.id === id
            ? { ...node, data: { ...node.data, text: newValue } }
            : node
        )
      );
    },
    [id, setNodes]
  );

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLTextAreaElement>) => {
      const newValue = event.target.value;
      handleUpdate(newValue);
    },
    [handleUpdate]
  );

  const handleDelete = () => {
    deleteElements({ nodes: [{ id: props.id }] });
  };

  return (
    <div>
      <CustomHandle
        type="source"
        id="question_node_source_1"
        position={Position.Left}
        connectionCount={1}
        nodes={props.nodes}
        edges={props.edges}
      />

      <div>
        <div style={{ marginTop: 3, marginBottom: 10 }}>Question</div>

        <textarea
          id={`question-${id}`}
          name={`question-${id}`}
          defaultValue={data?.text || ""}
          onBlur={handleBlur}
          className="nodrag"
          rows={5}
          cols={20}
          placeholder="Enter your question here..."
        />
      </div>

      <CustomHandle
        type="source"
        id="question_node_source_2"
        position={Position.Top}
        connectionCount={1}
        nodes={props.nodes}
        edges={props.edges}
      />

      <CustomHandle
        type="target"
        id="question_node_target_1"
        position={Position.Right}
        connectionCount={1}
        nodes={props.nodes}
        edges={props.edges}
      />

      <button
        onClick={handleDelete}
        style={{
          position: "absolute",
          top: "1px",
          right: "-2px",
          background: "transparent",
          border: "none",
          color: "#F64E60",
          cursor: "pointer",
          fontSize: 8,
        }}
        aria-label="Close"
      >
        ❌
      </button>
    </div>
  );
};

export default QuestionNode;
