/* @flow */

import { ChatResult } from "../../app/models/ChatResult";
import stores from "../../app/stores";

export default async function chatGeneral(
  userDocumentId: number,
  documentMessageId: number,
  companyId: number,
  prompt: string,
  k: number,
  isWebSearchActive: boolean,
  history: string | undefined
): Promise<ChatResult> {
  return new Promise(async (resolve, reject) => {
    let subscriptionId: string | undefined = undefined;

    if (stores.userStore.currentUser.subscription) {
      const subscriptionItem =
        stores.userStore.currentUser.subscription.items.data.find(
          (item) =>
            item.plan.id ===
            stores.companyModelStore.models.find(
              (model) => model.id.toString() === "8"
            )?.stripe_price_id
        );

      if (subscriptionItem) {
        subscriptionId = subscriptionItem.id;
      }
    }

    fetch(`${process.env.REACT_APP_API_URL}/chatGeneral`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "client-id": stores.userStore.socketClientId,
      },
      body: JSON.stringify({
        subscriptionId: subscriptionId,
        userDocumentId: userDocumentId,
        documentMessageId: documentMessageId,
        companyId: companyId,
        prompt: prompt,
        k: k,
        isFineTunedGeneralChatActive: stores.userStore.currentUser.settings
          .is_fine_tuned_general_chat_active
          ? true
          : false,
        isWebSearchActive: isWebSearchActive,
        history: history,
      }),
      credentials:
        process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          const result: ChatResult = data;

          resolve(result);
        } else {
          reject();
        }
      });
  });
}
