/* @flow */

import stores from "../../app/stores";

export type SyncSelectedFilesResult = {
  success: boolean;
  message: string;
  updatedFiles?: FileMeta[];
};

export type FileMeta = {
  id: string;
  name?: string;
  modifiedTime?: string;
};

export default async function syncSelectedFiles(
  companyId: number,
  companyAppId: number,
  companyStorageId: number,
  fileIds: string[]
): Promise<SyncSelectedFilesResult> {
  return new Promise(async (resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/sync-selected-files`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "client-id": stores.userStore.socketClientId,
      },
      body: JSON.stringify({
        companyId,
        companyAppId,
        companyStorageId,
        fileIds,
      }),
      credentials:
        process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          const result: SyncSelectedFilesResult = data;
          resolve(result);
        } else {
          reject(new Error("No data returned from syncSelectedFiles API"));
        }
      })
      .catch((error) => reject(error));
  });
}
