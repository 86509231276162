/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { KTIcon } from "../../../helpers";
import { getCSSVariableValue } from "../../../assets/ts/_utils";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";
import { useThemeMode } from "../../layout/theme-mode/ThemeModeProvider";

type Props = {
  className: string;
  chartColor: string;
  chartHeight: string;
};

const MixedWidget9: React.FC<Props> = ({
  className,
  chartColor,
  chartHeight,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();

  const renderChart = () => {
    if (!chartRef.current) return;

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(chartColor, chartHeight)
    );
    chart.render();
    return chart;
  };

  useEffect(() => {
    const chart = renderChart();
    return () => chart?.destroy();
  }, [mode, chartColor, chartHeight]);

  return (
    <div className={`card ${className}`}>
      {/* Header */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Sales Statistics</span>
          <span className="text-muted fw-semibold fs-7">
            Recent sales statistics
          </span>
        </h3>
        <div className="card-toolbar">
          <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
            aria-label="More options"
          >
            <KTIcon iconName="category" className="fs-2" />
          </button>
          <Dropdown1 />
        </div>
      </div>

      {/* Body */}
      <div className="card-body p-0 d-flex flex-column">
        {/* Stats */}
        <div className="card-px pt-5 pb-10 flex-grow-1">
          {[
            {
              color: "info",
              icon: "bucket",
              amount: "$2,034",
              label: "Author Sales",
            },
            {
              color: "danger",
              icon: "abstract-26",
              amount: "$706",
              label: "Commision",
            },
            {
              color: "success",
              icon: "basket",
              amount: "$49",
              label: "Average Bid",
            },
            {
              color: "primary",
              icon: "cheque",
              amount: "$5.8M",
              label: "All Time Sales",
            },
          ].map((item, index) => (
            <div key={index} className="row g-0 mb-10">
              <div className="col">
                <div className="d-flex align-items-center me-2">
                  <div className={`symbol symbol-50px me-3`}>
                    <div className={`symbol-label bg-light-${item.color}`}>
                      <KTIcon
                        iconName={item.icon}
                        className={`fs-1 text-${item.color}`}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="fs-4 text-dark fw-bold">{item.amount}</div>
                    <div className="fs-7 text-muted fw-semibold">
                      {item.label}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Chart */}
        <div
          ref={chartRef}
          className="mixed-widget-6-chart card-rounded-bottom"
        ></div>
      </div>
    </div>
  );
};

const getChartOptions = (
  chartColor: string,
  chartHeight: string
): ApexOptions => {
  const baseColor = getCSSVariableValue(`--bs-${chartColor}`);
  const lightColor = getCSSVariableValue(`--bs-${chartColor}-light`);
  const labelColor = getCSSVariableValue("--bs-gray-800");
  const strokeColor = getCSSVariableValue("--bs-gray-300");

  return {
    series: [
      {
        name: "Net Profit",
        data: [30, 25, 45, 30, 55, 55],
      },
    ],
    chart: {
      fontFamily: "inherit",
      type: "area",
      height: chartHeight,
      toolbar: { show: false },
      zoom: { enabled: false },
      sparkline: { enabled: true },
    },
    fill: { type: "solid", opacity: 1 },
    stroke: { curve: "smooth", width: 3, colors: [baseColor] },
    xaxis: {
      categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      labels: { style: { colors: labelColor, fontSize: "12px" } },
      crosshairs: {
        stroke: { color: strokeColor, width: 1, dashArray: 3 },
      },
    },
    yaxis: {
      min: 0,
      max: 60,
      labels: { style: { colors: labelColor, fontSize: "12px" } },
    },
    tooltip: {
      y: { formatter: (val) => `$${val} thousands` },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  };
};

export { MixedWidget9 };
