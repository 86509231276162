/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { getCSSVariableValue } from "../../../assets/ts/_utils";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";
import { useThemeMode } from "../../layout/theme-mode/ThemeModeProvider";

type Props = {
  className: string;
  chartColor: string;
  chartHeight: string;
};

const MixedWidget8: React.FC<Props> = ({
  className,
  chartColor,
  chartHeight,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();

  const renderChart = () => {
    if (!chartRef.current) return;

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartColor, chartHeight)
    );
    chart.render();
    return chart;
  };

  useEffect(() => {
    const chartInstance = renderChart();
    return () => {
      chartInstance?.destroy();
    };
  }, [chartColor, chartHeight, mode]);

  return (
    <div className={`card ${className}`}>
      {/* Header */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Trends</span>
          <span className="text-muted fw-semibold fs-7">Latest trends</span>
        </h3>
        <div className="card-toolbar">
          <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <KTIcon iconName="category" className="fs-2" />
          </button>
          <Dropdown1 />
        </div>
      </div>

      {/* Body */}
      <div className="card-body d-flex flex-column">
        <div
          ref={chartRef}
          className="mixed-widget-5-chart card-rounded-top"
        ></div>

        {/* Items */}
        <div className="mt-5">
          {[
            {
              title: "Top Authors",
              description: "Ricky Hunt, Sandra Trepp",
              img: "/media/svg/brand-logos/plurk.svg",
              amount: "+82$",
            },
            {
              title: "Top Sales",
              description: "PitStop Emails",
              img: "/media/svg/brand-logos/figma-1.svg",
              amount: "+82$",
            },
            {
              title: "Top Engagement",
              description: "KT.com",
              img: "/media/svg/brand-logos/vimeo.svg",
              amount: "+82$",
            },
          ].map((item, index) => (
            <div key={index} className="d-flex flex-stack mb-5">
              <div className="d-flex align-items-center me-2">
                <div className="symbol symbol-50px me-3">
                  <div className="symbol-label bg-light">
                    <img
                      src={toAbsoluteUrl(item.img)}
                      alt=""
                      className="h-50"
                    />
                  </div>
                </div>
                <div>
                  <a
                    href="#"
                    className="fs-6 text-gray-800 text-hover-primary fw-bold"
                  >
                    {item.title}
                  </a>
                  <div className="fs-7 text-muted fw-semibold mt-1">
                    {item.description}
                  </div>
                </div>
              </div>
              <div className="badge badge-light fw-semibold py-4 px-3">
                {item.amount}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const chartOptions = (chartColor: string, chartHeight: string): ApexOptions => {
  const baseColor = getCSSVariableValue(`--bs-${chartColor}`);
  const lightColor = getCSSVariableValue(`--bs-${chartColor}-light`);
  const labelColor = getCSSVariableValue("--bs-gray-800");
  const strokeColor = getCSSVariableValue("--bs-gray-300");

  return {
    series: [
      {
        name: "Net Profit",
        data: [30, 30, 60, 25, 25, 40],
      },
    ],
    chart: {
      fontFamily: "inherit",
      type: "area",
      height: chartHeight,
      toolbar: { show: false },
      sparkline: { enabled: true },
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    stroke: {
      curve: "smooth",
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
      crosshairs: {
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
    },
    yaxis: {
      min: 0,
      max: 65,
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    tooltip: {
      y: {
        formatter: (val) => `$${val} thousands`,
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  };
};

export { MixedWidget8 };
