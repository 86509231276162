import React, { useEffect } from "react";

const PaymentRedirect: React.FC = () => {
  useEffect(() => {
    const stripeUrl = sessionStorage.getItem("stripeRedirectUrl");
    if (stripeUrl) {
      setTimeout(() => {
        window.location.assign(stripeUrl);
      }, 500);
    } else {
      console.error("Stripe URL not found in sessionStorage!");
    }
  }, []);

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{ height: "100vh" }}
    >
      <div className="spinner-border text-primary mb-5" role="status">
        <span className="visually-hidden">Redirecting...</span>
      </div>
      <h3 className="text-muted">Redirecting to Payment...</h3>
    </div>
  );
};

export default PaymentRedirect;
