import React from "react";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";

import { PageTitle, PageLink } from "../../../../layout/core";

import CreateNewSectionFlow from "./CreateNewSectionFlow";

function withRouter(Component: any) {
  function ComponentWithRouter(props: any) {
    let params = useParams();
    return <Component {...props} params={params} />;
  }
  return ComponentWithRouter;
}

const CreateNewSectionFlowCrumbs: Array<PageLink> = [
  {
    title: "Create  New Section Flow",
    path: "/create-new-section-flow",
    isSeparator: false,
    isActive: false,
  },
];

@observer
class CreateNewSectionFlowWrapper extends React.Component {
  render() {
    //@ts-ignore
    const selectedSectionId = this.props.params.id;

    return (
      <>
        <PageTitle breadcrumbs={CreateNewSectionFlowCrumbs}>
          Create New Section Flow
        </PageTitle>

        <CreateNewSectionFlow selectedSectionId={selectedSectionId} />
      </>
    );
  }
}

export default withRouter(CreateNewSectionFlowWrapper);
