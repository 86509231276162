import React, { useState } from "react";
import { observer } from "mobx-react";

import { CompanyApp } from "../../models/CompanyApp";
import { KTSVG } from "../../../helpers";

type ToolboxProps = {
  apps: CompanyApp[];
  nodeTemplates: { id: string; label: string; type: string }[];
  onDragEnd?: () => void;
};

const Toolbox: React.FC<ToolboxProps> = observer(
  ({ apps, nodeTemplates, onDragEnd }) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleDragStart = (
      event: React.DragEvent,
      data: any,
      type: string
    ) => {
      event.dataTransfer.setData(
        "application/reactflow",
        JSON.stringify({ data, type })
      );
      event.dataTransfer.effectAllowed = "move";
    };

    const handleDragEnd = () => {
      setIsPopupOpen(false);
      if (onDragEnd) onDragEnd();
    };

    const handleButtonClick = (event: React.MouseEvent) => {
      setIsPopupOpen((prev) => !prev);
    };

    return (
      <div
        className="position-absolute translate-middle-y end-3 z-index-10 p-0 bg-body shadow-sm rounded"
        style={{
          position: "absolute",
          top: "50%",
          right: "0",
          transform: "translateY(-50%)",
          zIndex: 10,
          marginRight: "3%",
          backgroundColor:
            document.documentElement.getAttribute("data-bs-theme") === "dark"
              ? "#151521"
              : "#fff",
        }}
      >
        <div className="m-3 d-flex position-relative">
          {/* Toolbox Items */}
          <div className="toolbox-list d-flex flex-column gap-4 align-items-center">
            {nodeTemplates.map((template) => (
              <div
                key={template.id}
                style={{
                  position: "relative",
                  width: "45px",
                  height: "45px",
                }}
                className="toolbox-item btn btn btn-light d-flex justify-content-center align-items-center p-0 rounded cursor-move"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={template.label}
                draggable
                onDragStart={(event) =>
                  handleDragStart(
                    event,
                    { id: template.id, label: template.label },
                    template.type
                  )
                }
                onDragEnd={handleDragEnd}
              >
                {template.label === "Create a question" ? (
                  <KTSVG
                    path="/media/icons/duotune/general/gen046.svg"
                    className="svg-icon-muted svg-icon-3x"
                  />
                ) : (
                  <KTSVG
                    path="/media/icons/duotune/general/gen028.svg"
                    className="svg-icon-muted svg-icon-3x"
                  />
                )}
              </div>
            ))}
            <div
              className="btn toolbox-item d-flex justify-content-center align-items-center btn-primary p-0 rounded"
              onClick={handleButtonClick}
              style={{
                position: "relative",
                width: "45px",
                height: "45px",
              }}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title={"Add App"}
            >
              <KTSVG
                path="/media/icons/duotune/general/gen022.svg"
                className="svg-icon-muted svg-icon-3x"
              />
            </div>
          </div>

          <div
            className="drag-drop-text text-muted"
            style={{
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%) rotate(90deg)",
              transformOrigin: "center",
              color: "#6c757d",
              fontSize: "16px",
              fontWeight: "bold",
              whiteSpace: "nowrap",
              letterSpacing: "1.7px",
              marginLeft: "8px",
            }}
          >
            Drag & Drop
          </div>
        </div>

        {isPopupOpen && (
          <div
            className="popup bg-body border rounded shadow "
            style={{
              position: "absolute",
              top: 125,
              right: 60,
              zIndex: 1000,
              padding: "10px",
              width: "220px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <button
              onClick={() => setIsPopupOpen(false)}
              style={{
                position: "absolute",
                top: "-4px",
                right: "0px",
                background: "transparent",
                border: "none",
                fontSize: "16px",
                cursor: "pointer",
                fontWeight: "bold",
              }}
              aria-label="Close"
            >
              <span className="fa fa-times fs-6"></span>
            </button>

            <div
              className="d-flex flex-column"
              style={{
                maxHeight: "190px",
                overflowY: "auto",
              }}
            >
              {apps.map((app) => (
                <div
                  key={app.id}
                  className="toolbox-item d-flex align-items-center border border-gray-300 rounded p-2 cursor-move me-1 mt-2"
                  draggable
                  onDragStart={(event) =>
                    handleDragStart(event, app, "appNode")
                  }
                  onDragEnd={handleDragEnd}
                >
                  <div className="symbol symbol-25px me-3">
                    <img
                      src={app.app.logo}
                      alt={app.app.name}
                      className="symbol-label"
                      style={{ objectFit: "cover" }}
                    />
                  </div>
                  <span className="fs-7">{app.app.name}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default Toolbox;
