import React, { useState } from "react";

import CompanyDetails from "./CreateCompany/CompanyDetails";
import SelectPlan from "./CreateCompany/SelectPlan";
import CreateCompanyRedirectPage from "./CreateCompany/CreateCompanyRedirectPage";
import { WizardLayout, WizardContent } from "./CreateCompany/WizardComponents";

const WizardsPage = () => {
  const defaultPremiumPriceId = process.env.REACT_APP_MONTHLY_PRICE_ID;

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    department_id: "",
    teamSize: "1-1",
    name: "",
    companyDescription: "",
    website: "",
    selectedPlan: "Premium",
    priceId: defaultPremiumPriceId,
    billingType: "monthly",
  });

  const handleNext = () => setStep((prevStep) => prevStep + 1);
  const handleBack = () => {
    if (step === 1) {
      setStep(0);
    } else if (step > 1) {
      setStep((prevStep) => prevStep - 1);
    }
  };

  if (step === 0) {
    return <CreateCompanyRedirectPage onNext={handleNext} />;
  }

  return (
    <WizardLayout currentStep={step}>
      <WizardContent>
        {step === 1 && (
          <CompanyDetails
            onNext={handleNext}
            onBack={handleBack}
            formData={formData}
            setFormData={setFormData}
          />
        )}
        {step === 2 && (
          <SelectPlan
            onNext={handleNext}
            onBack={handleBack}
            formData={formData}
            setFormData={setFormData}
          />
        )}
      </WizardContent>
    </WizardLayout>
  );
};

export default WizardsPage;
