import React, { FC, useState, useEffect } from "react";
import { KTIcon } from "../../../helpers";
import stores from "../../../app/stores";
import {
  createCheckoutSession,
  getStripeProfileSession,
} from "../../../helpers/api";

const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "";

if (!stripeKey) {
  throw new Error("Stripe key is not defined");
}

const isTestMode = stripeKey.includes("test");

const plans = [
  {
    title: "Premium",
    subTitle: "Advanced Features for Growing Teams",
    description:
      "Enjoy unlimited applications, employees, LLMs, and storage connections. Includes premium tools like Auto Sync and VoiceAsk. Perfect for teams requiring flexibility and more advanced functionality.",
    priceMonth: "49$",
    priceAnnual: "490$",
    isPopular: true,
    priceIdMonth: process.env.REACT_APP_MONTHLY_PRICE_ID,
    priceIdAnnual: process.env.REACT_APP_YEARLY_PRICE_ID,
    paymentLinks: {
      month: "#",
      annual: "#",
    },
    default: false,
    custom: false,
    tokenInfo: "Unlimited token",
    features: [
      { title: "Unlimited access to applications", supported: true },
      { title: "Unlimited LLM connections", supported: true },
      { title: "Unlimited storage connection", supported: true },
      { title: "1 Admin", supported: true },
      { title: "VoiceAsk", supported: true },
      { title: "1 Avatar", supported: true },
      { title: "Auto Sync", supported: true },
      { title: "Custom LLM tuning", supported: false },
      { title: "The Chief", supported: false },
      { title: "Use API", supported: false },
    ],
  },
  {
    title: "Enterprise",
    subTitle: "Comprehensive Solutions for Large Enterprises",
    description:
      "Full access to advanced features like unlimited admins, storage, and employees. Get specialized services like custom LLMs. Perfect for large organizations with specific needs.",
    default: false,
    custom: true,
    priceId: isTestMode
      ? "price_1PqZumBEosKxgEvXihK0LN6z"
      : "price_1PqweqwosKxgEmvXihK0LN6z",
    tokenInfo: "Unlimited token",
    features: [
      { title: "Unlimited access to applications", supported: true },
      { title: "Unlimited LLM connections", supported: true },
      { title: "Unlimited storage connections", supported: true },
      { title: "Unlimited admin additions", supported: true },
      { title: "VoiceAsk", supported: true },
      { title: "Avatars", supported: true },
      { title: "Auto Sync", supported: true },

      { title: "Custom LLM tuning", supported: true },
      { title: "The Chief", supported: true },
      { title: "Use API", supported: true },
    ],
  },
];

const UpgradePlan: FC = () => {
  const [isFreeUser, setIsFreeUser] = useState(true);
  const [selectedPlan, setSelectedPlan] = useState("Premium");
  const [billingType, setBillingType] = useState<"monthly" | "annual">(
    "monthly"
  );
  const [error, setError] = useState<string | null>(null);
  const [isCurrentPlan, setIsCurrentPlan] = useState(false);
  const [totalUsersCount, setTotalUsersCount] = useState(0);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    const checkSubscriptionAndTokenUsage = async () => {
      const currentUser = stores.userStore.currentUser;

      if (currentUser.subscription) {
        setIsFreeUser(false);

        const currentPriceId = currentUser.subscription.items.data[0].price.id;

        if (
          (billingType === "monthly" &&
            currentPriceId ===
              plans.find((plan) => plan.title === "Premium")?.priceIdMonth) ||
          (billingType === "annual" &&
            currentPriceId ===
              plans.find((plan) => plan.title === "Premium")?.priceIdAnnual)
        ) {
          setIsCurrentPlan(true);
        } else {
          setIsCurrentPlan(false);
        }
      } else {
        setIsFreeUser(true);
        setIsCurrentPlan(false);
      }
    };

    checkSubscriptionAndTokenUsage();

    const fetchCompanyUsers = async () => {
      const companyUsers = await stores.companyUserStore.getCompanyUsers();
      setTotalUsersCount(companyUsers.length);
    };

    fetchCompanyUsers();
  }, [selectedPlan, billingType]);

  const handlePlanSelect = (planTitle: string) => {
    setSelectedPlan(planTitle);
  };

  const handleBillingTypeChange = (type: "monthly" | "annual") => {
    setBillingType(type);
  };

  const handleCheckoutSession = async () => {
    try {
      const priceId =
        billingType === "monthly"
          ? plans.find((plan) => plan.title === "Premium")?.priceIdMonth
          : plans.find((plan) => plan.title === "Premium")?.priceIdAnnual;

      if (!priceId) {
        throw new Error("Price ID not found for the selected plan");
      }

      const currentSubscriptionId = stores.userStore.currentUser.subscription
        ? stores.userStore.currentUser.subscription.id
        : null;

      const stripeProfile = stores.userStore.currentUser.stripeProfile;

      if (!stripeProfile || !stripeProfile.id) {
        throw new Error("Stripe profile ID is undefined.");
      }

      const companyUsers = await stores.companyUserStore.getCompanyUsers();
      const totalUsersCount = companyUsers.length;

      const stripeSession = await createCheckoutSession(
        priceId,
        `${process.env.REACT_APP_PUBLIC_URL}/success`,
        `${process.env.REACT_APP_PUBLIC_URL}/cancel`,
        currentSubscriptionId,
        stripeProfile.id,
        totalUsersCount
      );

      sessionStorage.setItem("stripeSessionId", stripeSession.id);
      window.location.href = stripeSession.url;
    } catch (error) {
      console.error("Error during Stripe checkout:", error);
    }
  };

  const handleUpgradePlan = async () => {
    try {
      if (selectedPlan === "Enterprise") {
        window.location.href = "https://www.companydna.ai/waitlist";
      } else if (selectedPlan === "Premium") {
        if (isCurrentPlan) {
        } else {
          const currentBillingType = stores.userStore.currentUser.subscription
            ? stores.userStore.currentUser.subscription.items.data[0].price
                .recurring.interval
            : null;

          if (
            (currentBillingType === "month" && billingType === "annual") ||
            (currentBillingType === "year" && billingType === "monthly")
          ) {
            setShowConfirmationModal(true);
          } else {
            await handleCheckoutSession();
          }
        }
      }
    } catch (error) {
      console.error("Error during plan upgrade/downgrade:", error);
    }
  };

  const handleMyProfileClick = async () => {
    if (stores.userStore.currentUser.stripeProfile) {
      try {
        const updatePaymentMethodSession = await getStripeProfileSession(
          stores.userStore.currentUser.stripeProfile.id,
          //@ts-ignore
          process.env.REACT_APP_PUBLIC_URL
        );
        window.location.href = updatePaymentMethodSession.url;
      } catch (error) {
        console.error("Error retrieving Stripe profile session:", error);
        setError("Failed to retrieve your Stripe profile.");
      }
    }
  };

  return (
    <div className="modal fade" id="kt_modal_upgrade_plan" aria-hidden="true">
      <div className="modal-dialog modal-xl">
        <div className="modal-content rounded shadow-sm">
          <div className="modal-header justify-content-end border-0 pb-0">
            <button
              type="button"
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <KTIcon iconName="cross" className="fs-1" />
            </button>
          </div>

          <div className="modal-body pt-0 pb-15 px-5 px-xxl-15">
            <div className="text-center mb-5">
              <h1 className="mb-3">My Plan</h1>
              <p className="text-muted fw-bold fs-5">
                You are currently using the{" "}
                <strong>{isFreeUser ? "Free" : "Premium"}</strong> plan.
              </p>
            </div>

            <div className="text-center mb-5">
              <h3 className="text-muted fs-5">
                Empowering a team of {totalUsersCount} users in your company
              </h3>
            </div>

            <div className="d-flex justify-content-center mb-10">
              <div className="nav nav-tabs border-0">
                <button
                  type="button"
                  className={`nav-link btn btn-outline-secondary ${
                    billingType === "monthly" ? "active" : ""
                  }`}
                  onClick={() => handleBillingTypeChange("monthly")}
                  disabled={selectedPlan === "Enterprise"}
                >
                  Monthly Billing
                </button>
                <button
                  type="button"
                  className={`nav-link btn btn-outline-secondary ${
                    billingType === "annual" ? "active" : ""
                  }`}
                  onClick={() => handleBillingTypeChange("annual")}
                  disabled={selectedPlan === "Enterprise"}
                >
                  Annual Billing
                </button>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="row g-10 align-items-stretch">
                {plans.map((plan, index) => {
                  const isCurrentPlanForUser =
                    (billingType === "monthly" &&
                      plan.priceIdMonth ===
                        stores.userStore.currentUser.subscription?.items.data[0]
                          .price.id) ||
                    (billingType === "annual" &&
                      plan.priceIdAnnual ===
                        stores.userStore.currentUser.subscription?.items.data[0]
                          .price.id);

                  return (
                    <div className="col-lg-6" key={index}>
                      <div
                        className={`card card-bordered card-hover h-100 ${
                          selectedPlan === plan.title ? "bg-light-primary" : ""
                        }`}
                        onClick={() => handlePlanSelect(plan.title)}
                        style={{ cursor: "pointer", position: "relative" }}
                      >
                        <div className="card-body text-center p-10">
                          <h3 className="card-title mb-2">{plan.title}</h3>

                          {plan.title !== "Enterprise" &&
                          isCurrentPlanForUser ? (
                            <span
                              style={{
                                position: "absolute",
                                top: "-10px",
                                right: "-10px",
                                backgroundColor: "#28a745",
                                color: "#fff",
                                padding: "5px 10px",
                                borderRadius: "5px",
                                fontSize: "12px",
                                fontWeight: "bold",
                              }}
                            >
                              Current Plan
                            </span>
                          ) : plan.isPopular ? (
                            <span
                              style={{
                                position: "absolute",
                                top: "-10px",
                                right: "-10px",
                                backgroundColor: "#ffc107",
                                color: "#000",
                                padding: "5px 10px",
                                borderRadius: "5px",
                                fontSize: "12px",
                                fontWeight: "bold",
                              }}
                            >
                              Most Popular
                            </span>
                          ) : null}

                          <p className="card-text text-muted mb-4">
                            {plan.subTitle}
                          </p>

                          <h4 className="fw-bold text-primary mb-4 fs-2">
                            {plan.title === "Enterprise" ? (
                              <div className="text-primary fw-bold mb-0">
                                Contact Us
                              </div>
                            ) : (
                              <>
                                {billingType === "monthly"
                                  ? plan.priceMonth
                                  : plan.priceAnnual}
                                /{billingType}
                                {plan.title === "Premium" && (
                                  <span className="fs-6 text-muted">
                                    {" "}
                                    per user
                                  </span>
                                )}
                              </>
                            )}
                          </h4>

                          <p className="text-muted">{plan.description}</p>

                          <div className="w-100 mb-10">
                            {plan.features.map((feature, idx) => (
                              <div
                                className="d-flex align-items-center mb-7"
                                key={idx}
                              >
                                <span
                                  className={`fw-bold fs-5 text-${
                                    feature.supported ? "gray-700" : "gray-400"
                                  } flex-grow-1`}
                                >
                                  {feature.title}
                                </span>
                                <KTIcon
                                  iconName={
                                    feature.supported
                                      ? "check-circle"
                                      : "cross-circle"
                                  }
                                  className={`fs-1 ${
                                    feature.supported ? "text-success" : ""
                                  }`}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="d-flex flex-column flex-center flex-row-fluid mt-10">
              <div className="d-flex flex-center">
                <button
                  type="reset"
                  className="btn btn-light me-3"
                  data-bs-dismiss="modal"
                >
                  {isFreeUser ? "Cancel" : "Close"}
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleUpgradePlan}
                  disabled={
                    selectedPlan === "Enterprise" ? false : isCurrentPlan
                  }
                >
                  {selectedPlan === "Enterprise"
                    ? "Contact Us"
                    : isCurrentPlan
                    ? "Current Plan"
                    : billingType === "annual" &&
                      stores.userStore.currentUser.subscription?.items.data[0]
                        .price.recurring.interval === "month"
                    ? "Upgrade to Annual Plan"
                    : billingType === "monthly" &&
                      stores.userStore.currentUser.subscription?.items.data[0]
                        .price.recurring.interval === "year"
                    ? "Downgrade to Monthly Plan"
                    : "Upgrade to Premium Plan"}
                </button>
              </div>

              {!isFreeUser && (
                <div className="mt-5 text-center">
                  <button
                    type="button"
                    className="btn btn-link text-primary"
                    onClick={handleMyProfileClick}
                  >
                    Manage your Stripe profile
                  </button>
                </div>
              )}
              {error && <div className="alert alert-danger mt-4">{error}</div>}
            </div>
          </div>
        </div>
      </div>
      {/* Confirmation Modal */}
      {showConfirmationModal && (
        <div
          className="modal fade show d-block"
          tabIndex={-1}
          role="dialog"
          aria-modal="true"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered modal-md"
            role="document"
          >
            <div className="modal-content rounded shadow-sm">
              <div className="modal-header bg-light-primary">
                <h5 className="modal-title text-primary">
                  {billingType === "annual"
                    ? "Confirm Upgrade"
                    : "Confirm Downgrade"}
                </h5>
                <button
                  type="button"
                  className="btn btn-sm btn-icon btn-active-color-primary"
                  onClick={() => setShowConfirmationModal(false)}
                  aria-label="Close"
                >
                  <KTIcon iconName="cross" className="fs-2" />
                </button>
              </div>
              <div className="modal-body">
                <div className="d-flex align-items-center">
                  <KTIcon
                    iconName="information"
                    className="fs-3 text-warning me-3"
                  />
                  <p className="text-muted mb-0">
                    {billingType === "annual"
                      ? `Your current plan will be canceled immediately, and you will be billed for the yearly plan starting today.`
                      : `You are switching to a monthly plan. Your annual plan will be canceled immediately, and your billing will now occur monthly.`}
                  </p>
                </div>
              </div>
              <div className="modal-footer justify-content-between">
                <button
                  type="button"
                  className="btn btn-light me-3"
                  onClick={() => setShowConfirmationModal(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={async () => {
                    setShowConfirmationModal(false);
                    await handleCheckoutSession();
                  }}
                >
                  {billingType === "annual"
                    ? "Confirm Upgrade"
                    : "Confirm Downgrade"}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { UpgradePlan };
