/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

import { KTIcon } from "../../../../../../helpers";
import { CustomUser } from "../../../../../models/CustomUser";

import stores from "../../../../../stores";

type Props = {
  customUser: CustomUser;
};

@observer
export class CompanyUserActionsCell extends React.Component<Props> {
  // Removed the componentDidMount method since it's not necessary without MenuComponent

  // private openManageAppModal = () => {
  //   const { companyUser } = this.props;

  //   stores.companyUserStore.selectedCompanyUser =
  //     stores.companyUserStore.companyUsers.find(
  //       (item) => item.id === companyUser.id
  //     );

  //   stores.companyUserStore.isCompanyUserManageAppsModalOpened = true;
  // };

  // private openAddAppModal = () => {
  //   const { companyUser } = this.props;

  //   stores.companyUserStore.selectedCompanyUser =
  //     stores.companyUserStore.companyUsers.find(
  //       (item) => item.id === companyUser.id
  //     );

  //   stores.companyUserStore.isCompanyUserAddAppModalOpened = true;
  // };

  // private openEditModal = () => {
  //   const { companyUser } = this.props;

  //   stores.companyUserStore.selectedCompanyUser =
  //     stores.companyUserStore.companyUsers.find(
  //       (item) => item.id === companyUser.id
  //     );

  //   stores.companyUserStore.isCompanyUserEditModalOpened = true;
  // };

  private handleDeleteInviteItem = () => {
    ReactGA.event({
      category: "company_user_invites",
      action: "delete_user_invite_button",
      label: "delete_user_invite_button",
    });
    const { customUser } = this.props;
    const selectedUserInvite = stores.companyUserStore.companyUserInvites.find(
      (invite) => invite.id === customUser.id
    );

    stores.companyUserStore.selectedCompanyUserInvite = selectedUserInvite;

    stores.companyUserStore.isCompanyUserInviteDeleteModalOpened = true;
  };

  private handleDeleteItem = () => {
    const { customUser } = this.props;

    ReactGA.event({
      category: "company_users",
      action: "delete_company_user_button",
      label: "delete_company_user_button",
    });

    stores.companyUserStore.selectedCompanyUser =
      stores.companyUserStore.companyUsers.find(
        (item) => item.id === customUser.id
      );

    stores.companyUserStore.isCompanyUserDeleteModalOpened = true;
  };

  private openEditUserInviteModal = () => {
    const { customUser } = this.props;
    const selectedUserInvite = stores.companyUserStore.companyUserInvites.find(
      (invite) => invite.id === customUser.id
    );

    stores.companyUserStore.selectedCompanyUserInvite = selectedUserInvite;
    stores.companyUserStore.isCompanyUserEditModalOpened = true;

    ReactGA.event({
      category: "company_user_invites",
      action: "edit_user_invite",
      label: "edit_user_invite_button",
    });
  };

  render() {
    const { customUser } = this.props;
    const companyUser = stores.companyUserStore.companyUsers.find(
      (user) => user.id === customUser.id
    );
    const isUserInCompanyUsers = stores.companyUserStore.companyUsers.some(
      (user) => user.id === customUser.id
    );

    if (stores.companyUserStore.isLoading) {
      return (
        <div className="d-flex justify-content-center align-items-center w-100"></div>
      );
    }

    return (
      <>
        {companyUser?.user.id.toString() ===
        stores.userStore.currentUser.id.toString() ? (
          <div className="d-flex justify-content-center align-items-center w-100">
            <div></div>
          </div>
        ) : !isUserInCompanyUsers ? (
          <div className="d-flex justify-content-end">
            <div className="d-flex justify-content-end flex-shrink-0">
              <a
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                onClick={this.openEditUserInviteModal}
              >
                <KTIcon iconName="pencil" className="fs-3" />
              </a>
              <a
                onClick={this.handleDeleteInviteItem}
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
              >
                <KTIcon iconName="trash" className="fs-3" />
              </a>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-end flex-shrink-0">
            <Link
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
              to={`/company-user-manage-page/${customUser.id}`}
              onClick={() =>
                ReactGA.event({
                  category: "company_users",
                  action: "edit_company_user",
                  label: "edit_company_user_button",
                })
              }
            >
              <KTIcon iconName="pencil" className="fs-3" />
            </Link>
            <a
              onClick={this.handleDeleteItem}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            >
              <KTIcon iconName="trash" className="fs-3" />
            </a>
          </div>
        )}
      </>
    );
  }
}
