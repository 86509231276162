import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";

export default async function deleteCompanyAvatar(
  id: number
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation DeleteCompanyAvatar($id: ID!) {
      deleteCompanyAvatar(id: $id)
    }
  `;

  try {
    const { data } = await client.mutate({
      mutation,
      variables: { id },
    });

    const result = get(data, "deleteCompanyAvatar", null);

    if (result !== null && result > 0) {
      return true;
    } else {
      console.error("Failed to delete avatar. Server returned null or 0.");
      return false;
    }
  } catch (error) {
    console.error("Error occurred while deleting company avatar:", error);

    throw new Error("Failed to delete the avatar. Please try again.");
  }
}
