/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { DashboardSection } from "../../app/models/DashboardSection";
import Functions from "../Functions";

export default async function getDashboardSections(
  company_id: number
): Promise<DashboardSection[]> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query DashboardSectionsQuery($company_id: ID!) {
        dashboardSections(company_id: $company_id) {
          id
          company_id
          section_name
          section_description
          is_manager_section
          order
          section_preferences
          view
          suggestion_view
          nodes
          edges
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          company_id: company_id,
        },
        fetchPolicy: "no-cache",
      })
      .then((dashboardSectionsQueryResult: any) => {
        const dashboardSections: DashboardSection[] = get(
          dashboardSectionsQueryResult,
          "data.dashboardSections",
          null
        );

        dashboardSections.forEach((section) => {
          section.node_objects_list = Functions.parseNodesAndEdgesString(
            section.nodes
          );
          section.edge_objects_list = Functions.parseNodesAndEdgesString(
            section.edges
          );
        });

        resolve(dashboardSections);
      })
      .catch((error: any) => {
        console.error(error);

        resolve([]);
      });
  });
}
