import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { observer } from "mobx-react";
import {
  Background,
  ReactFlow,
  useNodesState,
  useEdgesState,
  Node,
  Edge,
  Connection,
  applyEdgeChanges,
  useReactFlow,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";

import stores from "../../stores";

import AppNode from "./nodes/AppNode";
import AppGroupNode from "./nodes/AppGroupNode";
import QuestionNode from "./nodes/QuestionNode";
import ResultNode from "./nodes/ResultNode";
import FinalResultNode from "./nodes/FinalResultNode";
import { validateConnection, handleEdgeUpdate } from "./utils/EdgeUtils";
import { handleConnection } from "./utils/ConnectionUtils";
import Toolbox from "./Toolbox";

type CustomNodeFlowProps = {
  onComplete: (isCompleted: boolean) => void;
  onFlowDataChange: (nodes: Node[], edges: Edge[]) => void;
  initialNodes: Node[];
  initialEdges: Edge[];
};

const CustomNodeFlow: React.FC<CustomNodeFlowProps> = observer(
  ({ onComplete, onFlowDataChange, initialNodes, initialEdges }) => {
    const [nodes, setNodes, onNodesChange] = useNodesState<Node>([]);
    const [edges, setEdges] = useEdgesState<Edge>([]);
    const reactFlowWrapper = useRef<HTMLDivElement>(null);
    const { screenToFlowPosition, fitView, zoomTo } = useReactFlow();

    const checkComplete = useCallback(() => {
      const edge = edges.find(
        (edge) => edge.sourceHandle === "final_result_node_source_1"
      );
      onComplete(!!edge);
    }, [edges, onComplete]);

    const memoizedInitialNodes = useMemo(() => initialNodes, [initialNodes]);
    const memoizedInitialEdges = useMemo(() => initialEdges, [initialEdges]);

    useEffect(() => {
      const timeout = setTimeout(() => {
        fitView({ padding: 0.2 });
        zoomTo(1);
      }, 400);

      return () => clearTimeout(timeout);
    }, [fitView, zoomTo]);

    useEffect(() => {
      if (memoizedInitialNodes.length > 0) setNodes(memoizedInitialNodes);
    }, [memoizedInitialNodes, setNodes]);

    useEffect(() => {
      if (memoizedInitialEdges.length > 0) setEdges(memoizedInitialEdges);
    }, [memoizedInitialEdges, setEdges]);

    useEffect(() => {
      checkComplete();
    }, [edges, checkComplete]);

    useEffect(() => {
      onFlowDataChange(nodes, edges);
    }, [nodes, edges, onFlowDataChange]);

    const onDrop = useCallback(
      (event: React.DragEvent) => {
        event.preventDefault();

        const reactFlowBounds =
          reactFlowWrapper.current?.getBoundingClientRect();
        if (!reactFlowBounds) return;

        const { data, type } = JSON.parse(
          event.dataTransfer.getData("application/reactflow")
        );
        const position = screenToFlowPosition({
          x: event.clientX,
          y: event.clientY,
        });

        let newNode: Node;

        if (type === "appNode") {
          newNode = {
            id: `app_${data.id}_${Date.now()}`,
            type: "appNode",
            position,
            data: {
              app: data.app,
              handles: [
                { id: "app_node_target_1", allowedTypes: ["questionNode"] },
              ],
            },
          };
        } else if (type === "questionNode") {
          newNode = {
            id: `question_${data.id}_${Date.now()}`,
            type: "questionNode",
            position,
            data: {
              text: "",
              handles: [
                {
                  id: "question_node_source_1",
                  allowedTypes: ["appNode", "appGroupNode"],
                },
                { id: "question_node_source_2", allowedTypes: ["resultNode"] },
                {
                  id: "question_node_target_1",
                  allowedTypes: ["resultNode"],
                },
              ],
            },
          };
        } else if (type === "appGroupNode") {
          newNode = {
            id: `appGroup_${Date.now()}`,
            type: "appGroupNode",
            position,
            data: {
              apps: [],
              handles: [
                {
                  id: "app_group_node_target_1",
                  allowedTypes: ["questionNode"],
                },
              ],
            },
          };
        }

        setNodes((nds) => [...nds, newNode]);
      },
      [setNodes, screenToFlowPosition]
    );

    const onDragOver = (event: React.DragEvent) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";
    };

    const onConnect = useCallback(
      (connection: Connection) => {
        const { source, target, sourceHandle, targetHandle } = connection;

        if (source && target && sourceHandle && targetHandle) {
          const newEdge: Edge = {
            id: `edge_${source}_${target}`,
            source,
            target,
            sourceHandle,
            targetHandle,
            type: "smoothstep",
          };

          handleConnection(
            newEdge,
            nodes,
            edges,
            setEdges,
            setNodes,
            validateConnection
          );
        }
      },
      [nodes, edges, setEdges, setNodes]
    );

    const onEdgesChange = useCallback(
      (changes: any) => {
        setEdges((eds) => applyEdgeChanges(changes, eds));
        changes.forEach((change: any) =>
          handleEdgeUpdate(nodes, edges, change, edges, setNodes, setEdges)
        );
      },
      [nodes, edges, setEdges, setNodes]
    );

    const extProps = useMemo(
      () => ({ nodes, edges, setNodes }),
      [nodes, edges, setNodes]
    );

    const apps = stores.companyAppStore.companyApps;
    const nodeTemplates = [
      { id: "questionNode", label: "Create a question", type: "questionNode" },
      { id: "appGroupNode", label: "Group your Apps", type: "appGroupNode" },
    ];

    return (
      <div style={{ display: "flex", height: "73vh" }}>
        <Toolbox apps={apps} nodeTemplates={nodeTemplates} />
        <div
          className="reactflow-wrapper"
          style={{ flexGrow: 1 }}
          ref={reactFlowWrapper}
        >
          {nodes.length > 0 ? (
            <ReactFlow
              nodes={nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onConnect={onConnect}
              onDrop={onDrop}
              onDragOver={onDragOver}
              fitViewOptions={{ padding: 1.5 }}
              nodeTypes={{
                appNode: React.memo((props) => (
                  <AppNode {...props} {...extProps} />
                )),
                appGroupNode: React.memo((props) => (
                  <AppGroupNode {...props} {...extProps} />
                )),
                questionNode: React.memo((props) => (
                  <QuestionNode {...props} {...extProps} />
                )),
                resultNode: React.memo((props) => (
                  <ResultNode {...props} {...extProps} />
                )),
                finalResultNode: React.memo((props) => (
                  <FinalResultNode {...props} {...extProps} />
                )),
              }}
              fitView
            >
              <Background />
            </ReactFlow>
          ) : null}
        </div>
      </div>
    );
  }
);

export default CustomNodeFlow;
