import { observable, action } from "mobx";
import RootStore from "./RootStore";
import { CompanyAvatar } from "../models/CompanyAvatar";

export default class WizardStore {
  rootStore: RootStore;

  @observable selectedDepartmentId: number | null = null;
  @observable initialDepartmentId: number | null = null;
  @observable selectedModelId: number | null = null;
  @observable selectedAppIds: number[] = [];
  @observable avatarName: string = "";
  @observable avatarDescription: string = "";
  @observable isWizardActive: boolean = false;
  @observable isEditMode: boolean = false;
  @observable editingAvatar: CompanyAvatar | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }
  @action setSelectedDepartment = (departmentId: number | null) => {
    this.selectedDepartmentId = departmentId;
  };

  @action setInitialDepartmentId = (departmentId: number) => {
    this.initialDepartmentId = departmentId;
  };

  @action setAvatarName = (name: string) => {
    this.avatarName = name;
  };

  @action setIsWizardActive = (active: boolean) => {
    this.isWizardActive = active;
  };

  @action resetSelectedDepartment = () => {
    this.selectedDepartmentId = null;
  };

  @action resetSelectedModel = () => {
    this.selectedModelId = null;
  };

  @action resetSelectedApps = () => {
    this.selectedAppIds = [];
  };

  @action resetWizard = () => {
    this.resetSelectedDepartment();
    this.resetSelectedModel();
    this.resetSelectedApps();
    this.setAvatarName("");
    this.setAvatarDescription("");
    this.setEditMode(false);
    this.editingAvatar = null;
    this.initialDepartmentId = null;
  };

  @action setEditMode = (isEditMode: boolean) => {
    this.isEditMode = isEditMode;
  };

  @action setEditingAvatar = (avatar: CompanyAvatar | null) => {
    if (avatar) {
      this.editingAvatar = avatar;
      if (
        avatar.company_avatar_departments &&
        avatar.company_avatar_departments.length > 0
      ) {
        this.setInitialDepartmentId(
          avatar.company_avatar_departments[0].department_id
        );
        this.setSelectedDepartment(
          avatar.company_avatar_departments[0].department_id
        );
      }
    }
  };

  @action setAvatarDescription = (description: string) => {
    this.avatarDescription = description;
  };

  @action populateWizardForEditing = (avatar: CompanyAvatar) => {
    this.setEditMode(true);
    this.setEditingAvatar(avatar);
    this.setAvatarName(avatar.name);
    this.setAvatarDescription(avatar.description);
    if (
      avatar.company_avatar_departments &&
      avatar.company_avatar_departments.length > 0
    ) {
      const departmentId = avatar.company_avatar_departments[0].department_id;
      this.setSelectedDepartment(departmentId);
      this.setInitialDepartmentId(departmentId);
    }
  };

  @action setSelectedApps = (appIds: number[]) => {
    this.selectedAppIds = appIds;
  };
}
