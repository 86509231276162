/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { DashboardSection } from "../../app/models/DashboardSection";
import Functions from "../Functions";

export default async function getDashboardSection(
  id: number
): Promise<DashboardSection | undefined> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const query = gql`
      query DashboardSectionQuery($id: ID!) {
        dashboardSection(id: $id) {
          id
          company_id
          section_name
          section_description
          is_manager_section
          order
          section_preferences
          view
          suggestion_view
          nodes
          edges
        }
      }
    `;

    client
      .query({
        query: query,
        variables: {
          id: id,
        },
        fetchPolicy: "no-cache",
      })
      .then((dashboardSectionQueryResult: any) => {
        const dashboardSection: DashboardSection = get(
          dashboardSectionQueryResult,
          "data.dashboardSection",
          null
        );

        dashboardSection.node_objects_list = Functions.parseNodesAndEdgesString(
          dashboardSection.nodes
        );
        dashboardSection.edge_objects_list = Functions.parseNodesAndEdgesString(
          dashboardSection.edges
        );

        resolve(dashboardSection);
      })
      .catch((error: any) => {
        console.error(error);

        resolve(undefined);
      });
  });
}
