import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";
import TopBarProgress from "react-topbar-progress-indicator";

import { MasterLayout } from "../../layout/MasterLayout";
import { getCSSVariableValue } from "../../assets/ts/_utils";
import { WithChildren } from "../../helpers";
import { CompanyUsersWrapper } from "../pages/CompanyUsers/CompanyUsersWrapper";
import { ConnectionsWrapper } from "../pages/CompanyConnections/ConnectionsWrapper";
import { CompanyModelsWrapper } from "../pages/CompanyModels/CompanyModelsWrapper";
import { CompanyStoragesWrapper } from "../pages/CompanyStorages/CompanyStoragesWrapper";
import { AvatarWrapper } from "../pages/CompanyAvatars/AvatarsWrapper";
import { ChatWrapper } from "../pages/ChatPage/ChatWrapper";
import AiManagerWrapper from "../pages/AiManager/AiManagerWrapper"; // Use AiManagerWrapper as part of dashboard
import { TutorialsWrapper } from "../pages/TutorialsPage/TutorialsWrapper";
import { AccountWrapper } from "../pages/accounts/AccountWrapper";
import { TheChiefDashboardWrapper } from "../pages/theChiefDashboard/TheChiefDashboardWrapper";

import stores from "../stores";

const PrivateRoutes: FC = observer(() => {
  const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
  const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));

  // Check if running in Electron
  const isElectron = window.electronAPIs?.isElectron ?? false;

  // AI Manager access control based on avatar count or chief availability
  const canAccessAiManager =
    stores.companyAvatarStore.companyAvatars.length >= 1 ||
    stores.companyStore.selectedUserCompany?.is_chief_available;

  // Account access control based on admin status or premium plan
  const canAccessAccount =
    stores.userStore.isCurrentUserAdmin ||
    stores.companyStore.selectedUserCompany?.plan === "Premium";

  return (
    <Routes>
      {/* All Other Routes within MasterLayout */}
      <Route element={<MasterLayout />}>
        <Route path="chat" element={<ChatWrapper />} />

        {!isElectron ? (
          <>
            {/* Redirect to Dashboard after success login/registration */}
            <Route path="auth/*" element={<Navigate to="/dashboard" />} />

            {/* Pages */}
            {stores.userStore.isCurrentUserAdmin && (
              <>
                <Route path="company-users" element={<CompanyUsersWrapper />} />
                <Route path="connections" element={<ConnectionsWrapper />} />
                <Route path="models" element={<CompanyModelsWrapper />} />
                <Route path="storages" element={<CompanyStoragesWrapper />} />
                <Route path="avatars" element={<AvatarWrapper />} />
                <Route path="tutorials" element={<TutorialsWrapper />} />
                <Route
                  path="the-chief-workspace"
                  element={<TheChiefDashboardWrapper />}
                />
              </>
            )}

            {/* Account Page - Only accessible if allowed */}
            {canAccessAccount && (
              <Route path="account" element={<AccountWrapper />} />
            )}

            {/* AI Manager - Only accessible if allowed */}
            {stores.userStore.isCurrentUserAdmin && canAccessAiManager && (
              <Route path="ai-manager" element={<AiManagerWrapper />} />
            )}

            {/* Lazy Modules */}
            {stores.userStore.isCurrentUserAdmin && (
              <>
                <Route
                  path="crafted/pages/profile/*"
                  element={
                    <SuspensedView>
                      <ProfilePage />
                    </SuspensedView>
                  }
                />
                <Route
                  path="crafted/pages/widgets/*"
                  element={
                    <SuspensedView>
                      <WidgetsPage />
                    </SuspensedView>
                  }
                />
              </>
            )}
          </>
        ) : (
          <Route path="*" element={<Navigate to="/chat" />} />
        )}

        {/* Page Not Found */}
        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
});

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
