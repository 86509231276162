/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { observer } from "mobx-react";
import { reaction } from "mobx";

import getCompanyUserDepartments from "../../../../../../helpers/api/getCompanyUserDepartments";
import Functions from "../../../../../../helpers/Functions";
import { CustomUser } from "../../../../../models/CustomUser";
import { Department } from "../../../../../models/Department";
import stores from "../../../../../stores";

type Props = {
  customUser: CustomUser;
};

type State = {
  userDepartments: Department[];
  initeUserDepartments: Department[];
};

@observer
export class CompanyUserDepartmentsCell extends React.Component<Props, State> {
  disposeReaction: () => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      userDepartments: [],
      initeUserDepartments: [],
    };

    this.initializeData();

    this.disposeReaction = reaction(
      () => stores.companyUserStore.companyUserInvites,
      () => {
        this.updateInviteUserDepartments();
      }
    );
  }

  componentWillUnmount() {
    this.disposeReaction();
  }

  async initializeData() {
    await this.updateUserDepartments();
    this.updateInviteUserDepartments();
  }

  async updateUserDepartments() {
    const { customUser } = this.props;

    if (customUser) {
      try {
        const companyUserDepartments = await getCompanyUserDepartments(
          customUser.id
        );

        const userDepartments =
          stores.companyDepartmentStore.departments.filter((department) =>
            companyUserDepartments.some(
              (companyUserDepartment) =>
                companyUserDepartment.department_id.toString() ===
                department.id.toString()
            )
          );

        this.setState({
          userDepartments: userDepartments,
        });
      } catch (error) {
        console.error("Error fetching company user departments:", error);
      }
    }
  }

  updateInviteUserDepartments() {
    const { customUser } = this.props;

    if (customUser) {
      const inviteUserDepartments: number[] =
        stores.companyUserStore.companyUserInvites
          .filter(
            (invite) =>
              invite.id === customUser.id && invite.user_invite_departments
          )
          .flatMap((invite) => {
            try {
              return JSON.parse(
                decodeURIComponent(invite.user_invite_departments!)
              ).map(Number);
            } catch (error) {
              console.error("Parsing error:", error);
              return [];
            }
          });

      const initeUserDepartments =
        stores.companyDepartmentStore.departments.filter((department) =>
          inviteUserDepartments.toString().includes(department.id.toString())
        );

      this.setState({
        initeUserDepartments: initeUserDepartments,
      });
    }
  }

  render() {
    const { userDepartments, initeUserDepartments } = this.state;
    const { customUser } = this.props;
    const isUserInCompanyUsers = stores.companyUserStore.companyUsers.some(
      (user) => user.id === customUser.id
    );
    if (stores.companyUserStore.isLoading) {
      return (
        <div className="d-flex justify-content-center align-items-center w-100">
          <div className="spinner-border text-primary" role="status"></div>
        </div>
      );
    }

    if (customUser.role_id === 1) {
      return (
        <div className="justify-content-center text-align-center symbol-group symbol-hover mb-1">
          <div> - </div>
        </div>
      );
    }
    if (customUser.role_id === 2 && !isUserInCompanyUsers) {
      return (
        <div className="justify-content-center text-align-center symbol-group symbol-hover mb-1">
          <div className="justify-content-center text-align-center symbol-group symbol-hover mb-1">
            {initeUserDepartments?.slice(0, 3).map((initeUserDepartments) => (
              <span
                key={initeUserDepartments.id}
                style={{
                  backgroundColor: Functions.lightenColor(
                    initeUserDepartments.color,
                    70
                  ),
                  color: initeUserDepartments.color,
                  padding: "1.5px 4.5px",
                  borderRadius: "3px",
                  marginRight: "3px",
                  marginTop: "5px",
                  fontSize: "0.75em",
                }}
              >
                {initeUserDepartments.name}
              </span>
            ))}

            {initeUserDepartments.length > 3 ? (
              <div className="symbol symbol-circle symbol-25px mx-2 mt-2">
                <div className="symbol-label bg-primary">
                  <span className="fs-8 text-inverse-dark">{`+${
                    initeUserDepartments.length - 3
                  }`}</span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      );
    }

    if (customUser.role_id === 2 && isUserInCompanyUsers) {
      return (
        <div className="justify-content-center text-align-center symbol-group symbol-hover mb-1">
          {userDepartments?.slice(0, 3).map((userDepartments) => (
            <span
              key={userDepartments.id}
              style={{
                backgroundColor: Functions.lightenColor(
                  userDepartments.color,
                  70
                ),
                color: userDepartments.color,
                padding: "1.5px 4.5px",
                borderRadius: "3px",
                marginRight: "3px",
                marginTop: "5px",
                fontSize: "0.75em",
              }}
            >
              {userDepartments.name}
            </span>
          ))}

          {userDepartments.length > 3 ? (
            <div className="symbol symbol-circle symbol-25px mx-2 mt-2">
              <div className="symbol-label bg-primary">
                <span className="fs-8 text-inverse-dark">{`+${
                  userDepartments.length - 3
                }`}</span>
              </div>
            </div>
          ) : null}
        </div>
      );
    }
  }
}
