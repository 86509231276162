import { action, observable, when } from "mobx";

import {
  createUserDocument,
  deleteUserDocument,
  getUserDocuments,
  updateUserDocument,
} from "../../helpers/api";
import { UserDocument } from "../models/UserDocument";
import { DashboardSection } from "../models/DashboardSection";

import RootStore from "./RootStore";
import stores from ".";

export default class UserDocumentStore {
  @observable userDocuments: UserDocument[] = [];
  @observable filteredAndSortedUserDocuments: UserDocument[] = [];
  @observable selectedUserDocument?: UserDocument;
  @observable isLoading: boolean = false;
  @observable lastSelectedDocumentId?: number = undefined;
  @observable selectedDashboardSection?: DashboardSection = undefined;

  constructor(rootStore: RootStore) {
    when(
      () => rootStore.userStore.isCurrentUserReady,
      () => {
        this.getUserDocuments();
      }
    );
  }

  @action getUserDocuments = async () => {
    this.isLoading = true;

    const userDocuments = await getUserDocuments(
      stores.userStore.currentUser.id
    );

    if (userDocuments.length > 0) {
      this.userDocuments = userDocuments;
    }

    this.filterAndSortDocuments();

    this.isLoading = false;
  };

  @action createUserDocument(name: string): Promise<UserDocument> {
    return new Promise(async (resolve, reject) => {
      this.isLoading = true;

      const createdUserDocument = await createUserDocument(
        stores.userStore.currentUser.id,
        name,
        Date.now()
      );

      this.userDocuments.push(createdUserDocument);
      this.filterAndSortDocuments();

      this.selectedUserDocument = createdUserDocument;

      this.isLoading = false;

      resolve(createdUserDocument);
    });
  }

  @action updateUserDocument(userDocument: UserDocument): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      await updateUserDocument(userDocument);

      const existingUserDocumentIndex = this.userDocuments.findIndex(
        (item) => item.id === userDocument.id
      );

      if (existingUserDocumentIndex !== -1) {
        this.userDocuments[existingUserDocumentIndex] = userDocument;
      }

      this.filterAndSortDocuments();

      resolve(true);
    });
  }

  @action deleteUserDocument(userDocumentId: number): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      this.selectedUserDocument = undefined;

      this.userDocuments = this.userDocuments.filter(
        (item) => item.id !== userDocumentId
      );
      this.filterAndSortDocuments();

      await deleteUserDocument(userDocumentId);

      resolve(true);
    });
  }

  @action filterAndSortDocuments = (searchText: string = "") => {
    this.filteredAndSortedUserDocuments = this.userDocuments;

    if (searchText) {
      this.filteredAndSortedUserDocuments = this.userDocuments.filter(
        (userDocument) =>
          userDocument.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    this.filteredAndSortedUserDocuments =
      this.filteredAndSortedUserDocuments.sort((a, b) =>
        a.created_at > b.created_at ? -1 : 1
      );
  };
}
