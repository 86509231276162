/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

type Props = {
  className: string; // Additional custom classes
  color: string; // The theme color used for text, progress bar, etc.
  title: string; // Title text displayed alongside progress
  description: string; // Main description text for the widget
  progress: string; // Progress percentage (e.g., '70%')
};

const StatisticsWidget6: React.FC<Props> = ({
  className,
  color,
  title,
  description,
  progress,
}) => {
  return (
    <div className={`card bg-light-${color} ${className}`}>
      {/* Card Body */}
      <div className="card-body my-3">
        {/* Description */}
        <a
          href="#"
          className={`card-title fw-bold text-${color} fs-5 mb-3 d-block`}
        >
          {description}
        </a>

        {/* Progress Info */}
        <div className="py-1">
          <span className="text-dark fs-1 fw-bold me-2">{progress}</span>
          <span className="fw-semibold text-muted fs-7">{title}</span>
        </div>

        {/* Progress Bar */}
        <div className={`progress h-7px bg-${color} bg-opacity-50 mt-7`}>
          <div
            className={`progress-bar bg-${color}`}
            role="progressbar"
            style={{ width: progress }}
          />
        </div>
      </div>
    </div>
  );
};

export { StatisticsWidget6 };
