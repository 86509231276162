import React, { useEffect, useState, useCallback, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";
import {
  cancelSubscriptionImmediately,
  checkSessionStatus,
  createStripeProfile,
  getStripeProfile,
} from "../../../../helpers/api";
import stores from "../../../stores";
import { Company } from "../../../models/Company";
import { KTSVG } from "../../../../helpers";

type SessionStatus = {
  status: string;
  session?: {
    old_subscription_id: string;
    plan: {
      id: string;
    };
    discounts: string[];
  };
};

const normalizeEmail = (email: string) => email.trim().toLowerCase();

const getOrCreateStripeCustomer = async (email: any) => {
  const normalizedEmail = normalizeEmail(email);
  let stripeProfile = await getStripeProfile(normalizedEmail);

  if (!stripeProfile) {
    stripeProfile = await createStripeProfile(normalizedEmail);
  }

  return stripeProfile;
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SuccessPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);

  const query = useQuery();
  const hasHandledReturnFromStripe = useRef(false);

  const checkSessionWithRetry = useCallback(
    async (
      sessionId: string,
      retries = 5,
      delay = 5000
    ): Promise<SessionStatus | null> => {
      for (let attempt = 0; attempt < retries; attempt++) {
        try {
          const sessionStatus = await checkSessionStatus(sessionId);

          if (sessionStatus?.status === "paid") {
            return sessionStatus;
          }
          await new Promise((resolve) => setTimeout(resolve, delay));
        } catch (error) {
          console.error("Error checking session status:", error);
          return null;
        }
      }
      return null;
    },
    []
  );

  useEffect(() => {
    if (hasHandledReturnFromStripe.current) {
      return;
    }
    hasHandledReturnFromStripe.current = true;

    const handleReturnFromStripe = async () => {
      let sessionId = query.get("session_id");

      if (!sessionId) {
        sessionId = sessionStorage.getItem("stripeSessionId");
      } else {
        sessionStorage.setItem("stripeSessionId", sessionId);
      }

      if (sessionId) {
        try {
          const userEmail = stores.userStore.currentUser.email;
          const stripeProfile = await getOrCreateStripeCustomer(userEmail);

          if (!stripeProfile) {
            console.error("Stripe profile could not be created or fetched.");
            setLoading(false);
            return;
          }

          const sessionStatus = await checkSessionWithRetry(sessionId);
          if (!sessionStatus) {
            console.warn(
              "Failed to retrieve valid session status after retries."
            );
            setLoading(false);
            return;
          }

          const selectedCompany = stores.companyStore.selectedUserCompany;

          if (selectedCompany) {
            if (sessionStatus.status === "paid") {
              const availableAvatarCount =
                selectedCompany.available_avatar_count !== undefined &&
                selectedCompany.available_avatar_count !== null &&
                selectedCompany.available_avatar_count !== 0
                  ? selectedCompany.available_avatar_count
                  : 1;

              // Check BETA100 coupon
              let isBETA100CouponUsed =
                sessionStatus.session &&
                sessionStatus.session.discounts.length > 0
                  ? true
                  : false;

              const updatedCompany: Company = {
                ...selectedCompany,
                plan: "Premium",
                index_status: selectedCompany.index_status,
                logo: selectedCompany.logo || "",
                index_updated_at: selectedCompany.index_updated_at,
                available_avatar_count: isBETA100CouponUsed
                  ? 10
                  : availableAvatarCount,
                is_chief_available: isBETA100CouponUsed,
                is_api_available: isBETA100CouponUsed,
              };

              const updateSuccess = await stores.companyStore.updateCompany(
                updatedCompany
              );

              if (updateSuccess) {
                setSuccess(true);

                const oldSubscriptionId =
                  sessionStatus.session?.old_subscription_id;

                if (oldSubscriptionId) {
                  try {
                    await cancelSubscriptionImmediately(oldSubscriptionId);
                  } catch (error) {
                    console.error("Error canceling old subscription:", error);
                  }
                } else {
                  console.log("No old subscription found, proceeding.");
                }

                sessionStorage.setItem("paymentSuccess", "true");
                sessionStorage.removeItem("stripeSessionId");
              } else {
                console.error("Failed to update company plan to Premium.");
              }
            } else {
              console.warn("Payment failed or session plan ID is missing.");
            }
          } else {
            console.error("No selected company found.");
          }
        } catch (error) {
          console.error("Error during payment session handling:", error);
        }
      } else {
        console.warn(
          "No session ID found in sessionStorage or query parameter."
        );
      }

      setLoading(false);
    };

    handleReturnFromStripe();
  }, [checkSessionWithRetry, query]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  if (!stores.companyStore.selectedUserCompany) {
    console.warn("No company found, redirecting to home.");
    return <Navigate to="/" />;
  }

  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-column flex-center flex-column-fluid">
        <div className="w-lg-600px p-10 p-lg-15 mx-auto">
          <div className="text-center mb-10">
            {success ? (
              <>
                <h1 className="text-dark mb-3">Payment Successful!</h1>
                <div className="text-gray-400 fw-bold fs-5 mb-10">
                  Your payment was completed successfully.
                </div>
                <KTSVG
                  path="/media/icons/duotune/general/gen026.svg"
                  className="svg-icon svg-icon-1 svg-icon-primary"
                />
              </>
            ) : (
              <>
                <h1 className="text-dark mb-3">Payment Failed!</h1>
                <div className="text-gray-400 fw-bold fs-5 mb-10">
                  Unfortunately, your payment could not be completed.
                </div>
                <KTSVG
                  path="/media/icons/duotune/general/gen040.svg"
                  className="svg-icon svg-icon-1 svg-icon-danger"
                />
              </>
            )}
          </div>
          <div className="text-center">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                window.location.replace("/chat");
              }}
            >
              Go to Dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
