import { Position, NodeProps, Node, Edge, useReactFlow } from "@xyflow/react";

import CustomHandle from "../handles/CustomHandle";
import { App } from "../../../models/App";

interface AppNodeProps extends NodeProps {
  data: {
    app: App;
    handles: { id: string; allowedTypes: string[] }[];
  };
  nodes: Node[];
  edges: Edge[];
}

const AppNode: React.FC<AppNodeProps> = (props) => {
  const { deleteElements } = useReactFlow();

  const handleDelete = () => {
    deleteElements({ nodes: [{ id: props.id }] });
  };

  return (
    <>
      <div
        style={{
          marginLeft: 15,
          marginRight: 15,
          marginTop: 5,
          marginBottom: 5,
        }}
      >
        <CustomHandle
          id="app_node_target_1"
          type="target"
          position={Position.Right}
          connectionCount={1}
          nodes={props.nodes}
          edges={props.edges}
        />

        <div>
          <img
            src={props.data.app.logo}
            alt={`${props.data.app.name} Logo`}
            style={{ width: 30, height: 30 }}
          />
          <div style={{ marginTop: 5, fontSize: 12 }}>
            {props.data.app.name}
          </div>
        </div>
      </div>

      <button
        onClick={handleDelete}
        style={{
          position: "absolute",
          top: "1px",
          right: "-2px",
          background: "transparent",
          border: "none",
          color: "#F64E60",
          cursor: "pointer",
          fontSize: 8,
        }}
        aria-label="Close"
      >
        ❌
      </button>
    </>
  );
};

export default AppNode;
