import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import {
  Position,
  NodeProps,
  Node,
  Edge,
  NodeToolbar,
  useReactFlow,
} from "@xyflow/react";
import { App } from "../../../models/App";
import { KTSVG } from "../../../../helpers";
import stores from "../../../stores";
import CustomHandle from "../handles/CustomHandle";

interface AppGroupNodeProps extends NodeProps {
  data: {
    apps?: App[];
    isEditing?: boolean;
    handles?: { id: string; allowedTypes: string[] }[];
  };
  nodes: Node[];
  edges: Edge[];
  setNodes: (callback: (nodes: Node[]) => Node[]) => void;
}

const AppGroupNode: React.FC<AppGroupNodeProps> = (props) => {
  const allApps = stores.companyAppStore.companyApps;
  const selectedApps = props.data.apps || [];
  const isEditing = props.data.isEditing || false;
  const scrollRef = useRef<HTMLDivElement>(null);
  const { deleteElements } = useReactFlow();

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = stores.companyAppStore.scrollPosition;
    }
  }, [isEditing]);

  const toggleAppSelection = (app: App) => {
    const updatedApps = selectedApps.find((a) => a.id === app.id)
      ? selectedApps.filter((a) => a.id !== app.id)
      : [...selectedApps, app];

    props.setNodes((nodes) =>
      nodes.map((node) =>
        node.id === props.id
          ? {
              ...node,
              data: {
                ...node.data,
                apps: updatedApps,
              },
            }
          : node
      )
    );
  };

  const saveChanges = () => {
    props.setNodes((nodes) =>
      nodes.map((node) =>
        node.id === props.id
          ? {
              ...node,
              data: {
                ...node.data,
                isEditing: false,
              },
            }
          : node
      )
    );
  };

  const toggleEditMode = () => {
    props.setNodes((nodes) =>
      nodes.map((node) =>
        node.id === props.id
          ? {
              ...node,
              data: {
                ...node.data,
                isEditing: !isEditing,
              },
            }
          : node
      )
    );
  };

  const handleScroll = () => {
    if (scrollRef.current) {
      stores.companyAppStore.scrollPosition = scrollRef.current.scrollTop;
    }
  };

  const handleDelete = () => {
    deleteElements({ nodes: [{ id: props.id }] });
  };

  return (
    <div>
      {isEditing ? (
        <div>
          <p className="fw-bold fs-5">Edit Apps</p>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "10px",
              maxHeight: "300px",
              overflowY: "auto",
            }}
            className="nowheel px-3"
            ref={scrollRef}
            onScroll={handleScroll}
          >
            {allApps.map((companyApp) => (
              <div
                key={companyApp.id}
                className="p-3"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                  border: selectedApps.find((a) => a.id === companyApp.app.id)
                    ? "1px solid #007bff"
                    : "1px solid #ccc",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
                onClick={() => toggleAppSelection(companyApp.app)}
              >
                {companyApp.app.logo && (
                  <img
                    src={companyApp.app.logo}
                    alt={`${companyApp.app.name} Logosu`}
                    style={{ width: 30, height: 30 }}
                  />
                )}
                {companyApp.app.name && (
                  <div style={{ marginTop: 5, fontSize: 12 }}>
                    {companyApp.app.name}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div style={{ marginTop: "10px" }}>
            <button
              onClick={saveChanges}
              style={{
                marginRight: "10px",
                padding: "5px 10px",
                backgroundColor: "#007bff",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Save
            </button>
          </div>
        </div>
      ) : (
        <div>
          {selectedApps.length > 0 && (
            <NodeToolbar isVisible={true} position={Position.Top}>
              <button
                onClick={toggleEditMode}
                style={{
                  padding: "5px 10px",
                  backgroundColor: "#007bff",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Edit Apps
              </button>
            </NodeToolbar>
          )}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: `repeat(${Math.min(
                selectedApps.length || 1,
                3
              )}, minmax(100px, 1fr))`,
              gap: "10px",
              padding: "2px",
            }}
          >
            {selectedApps.length > 0 ? (
              selectedApps.map((app, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                  }}
                >
                  {app.logo && (
                    <img
                      src={app.logo}
                      alt={`${app.name} Logosu`}
                      style={{ width: 30, height: 30 }}
                    />
                  )}
                  {app.name && (
                    <div style={{ marginTop: 5, fontSize: 12 }}>{app.name}</div>
                  )}
                </div>
              ))
            ) : (
              <div
                onClick={toggleEditMode}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px",
                  border: "1px dashed #ccc",
                  borderRadius: "8px",
                  height: "100px",
                }}
              >
                <KTSVG
                  path="media/icons/duotune/general/gen035.svg"
                  className="svg-icon-muted svg-icon-5hx"
                />
                <span style={{ fontSize: "14px", color: "#999" }}>
                  Add Apps
                </span>
              </div>
            )}
            <button
              onClick={handleDelete}
              style={{
                position: "absolute",
                top: "1px",
                right: "-2px",
                background: "transparent",
                border: "none",
                color: "#F64E60",
                cursor: "pointer",
                fontSize: 8,
              }}
              aria-label="Close"
            >
              ❌
            </button>
          </div>
        </div>
      )}

      <CustomHandle
        id="app_group_node_target_1"
        type="target"
        position={Position.Right}
        connectionCount={1}
        nodes={props.nodes}
        edges={props.edges}
      />
    </div>
  );
};

export default observer(AppGroupNode);
