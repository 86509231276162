/* @flow */

export type CreateApiKeyResult = {
  success: boolean;
  apiKey?: string;
  message?: string;
};

export default async function createApiKey(
  companyId: number,
  userId: number,
  subscriptionId: string
): Promise<CreateApiKeyResult> {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/createApiKey`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            companyId: companyId,
            userId: userId,
            subscriptionId: subscriptionId,
          }),
          credentials:
            process.env.REACT_APP_IS_DEV === "false"
              ? "include"
              : "same-origin",
        }
      );

      const contentType = response.headers.get("content-type");

      if (contentType && contentType.includes("application/json")) {
        const data = await response.json();
        if (data.success) {
          resolve(data);
        } else {
          reject({ success: false, message: data.message || "Unknown error" });
        }
      } else {
        const text = await response.text();
        reject({ success: false, message: text });
      }
    } catch (error) {
      console.error("API call error:", error);
      reject({ success: false, message: "API key creation failed." });
    }
  });
}
