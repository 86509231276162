/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { getCSSVariableValue } from "../../../assets/ts/_utils";
import { useThemeMode } from "../../layout/theme-mode/ThemeModeProvider";

type Props = {
  className: string;
  chartColor: string;
  chartHeight: string;
};

const MixedWidget10: React.FC<Props> = ({
  className,
  chartColor,
  chartHeight,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();

  // Initialize and refresh chart
  const renderChart = () => {
    if (chartRef.current) {
      const chart = new ApexCharts(
        chartRef.current,
        getChartOptions(chartColor, chartHeight)
      );
      chart.render();
      return chart;
    }
  };

  useEffect(() => {
    const chart = renderChart();
    return () => chart?.destroy();
  }, [mode, chartColor, chartHeight]);

  return (
    <div className={`card ${className}`}>
      {/* Body */}
      <div className="card-body d-flex flex-column p-0">
        {/* Stats */}
        <div className="flex-grow-1 card-p pb-0">
          <div className="d-flex flex-stack flex-wrap">
            <div className="me-2">
              <a
                href="#"
                aria-label="Generate Reports"
                className="text-dark text-hover-primary fw-bold fs-3"
              >
                Generate Reports
              </a>
              <div className="text-muted fs-7 fw-semibold">
                Finance and accounting reports
              </div>
            </div>
            <div className={`fw-bold fs-3 text-${chartColor}`}>$24,500</div>
          </div>
        </div>
        {/* Chart */}
        <div
          ref={chartRef}
          className="mixed-widget-7-chart card-rounded-bottom"
        ></div>
      </div>
    </div>
  );
};

const getChartOptions = (
  chartColor: string,
  chartHeight: string
): ApexOptions => {
  const labelColor = getCSSVariableValue("--bs-gray-800");
  // const strokeColor = getCSSVariableValue("--bs-gray-300");
  const baseColor = getCSSVariableValue(`--bs-${chartColor}`);
  const lightColor = getCSSVariableValue(`--bs-${chartColor}-light`);

  return {
    series: [
      {
        name: "Net Profit",
        data: [15, 25, 15, 40, 20, 50],
      },
    ],
    chart: {
      fontFamily: "inherit",
      type: "area",
      height: chartHeight,
      toolbar: { show: false },
      zoom: { enabled: false },
      sparkline: { enabled: true },
    },
    stroke: {
      curve: "smooth",
      width: 3,
      colors: [baseColor],
    },
    fill: {
      type: "solid",
      opacity: 1,
    },
    xaxis: {
      categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
      labels: { style: { colors: labelColor, fontSize: "12px" } },
      axisBorder: { show: false },
      axisTicks: { show: false },
    },
    yaxis: {
      min: 0,
      max: 60,
      labels: { style: { colors: labelColor, fontSize: "12px" } },
    },
    colors: [lightColor],
    tooltip: {
      y: {
        formatter: (val) => `$${val} thousands`,
      },
    },
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  };
};

export { MixedWidget10 };
