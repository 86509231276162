import { action, observable, when } from "mobx";

import {
  createUserActionLog,
  deleteUserActionLog,
  getUserActionLogs,
  updateUserActionLog,
} from "../../helpers/api";
import { UserActionLogStatus } from "../../helpers/Enums";
import { UserActionLog } from "../models/UserActionLog";

import RootStore from "./RootStore";
import stores from ".";

export default class UserActionLogStore {
  @observable userActionLogs: UserActionLog[] = [];
  @observable isLoading: boolean = false;
  @observable lastViewedAt: number = 0;

  constructor(rootStore: RootStore) {
    when(
      () => rootStore.companyStore.selectedUserCompany !== undefined,
      () => {
        this.getUserActionLogs();
      }
    );
  }

  @action getUserActionLogs = async () => {
    this.isLoading = true;

    this.userActionLogs = await getUserActionLogs(
      stores.userStore.currentUser.id
    );

    this.isLoading = false;
  };

  @action createUserActionLog(
    action: string,
    actionResult: string,
    actionCode: number,
    actionResultCode: number,
    actionStatus: UserActionLogStatus
  ): Promise<UserActionLog> {
    return new Promise(async (resolve, reject) => {
      const createdCompanyApp = await createUserActionLog(
        stores.userStore.currentUser.id,
        action,
        actionResult,
        actionCode,
        actionResultCode,
        actionStatus,
        Date.now()
      );

      this.userActionLogs.push(createdCompanyApp);

      resolve(createdCompanyApp);
    });
  }

  @action updateUserActionLog(userActionLog: UserActionLog): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      await updateUserActionLog(userActionLog);

      const existingUserActionLogIndex = this.userActionLogs.findIndex(
        (item) => item.id === userActionLog.id
      );

      if (existingUserActionLogIndex !== -1) {
        this.userActionLogs[existingUserActionLogIndex] = userActionLog;
      }

      resolve(true);
    });
  }

  @action deleteUserActionLog(userActionLogId: number): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      await deleteUserActionLog(userActionLogId);

      this.userActionLogs = this.userActionLogs.filter(
        (item) => item.id !== userActionLogId
      );

      resolve(true);
    });
  }

  @action setLastViewedAt = (time: number) => {
    this.lastViewedAt = time;
  };
}
