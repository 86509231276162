/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import clsx from "clsx";

import { useLayout } from "../../core";
import { KTIcon } from "../../../helpers";

import { AsideTabs } from "./AsideTabs";
import { AsideFooter } from "./AsideFooter";
import { TabsBase } from "./Tabs/_TabsBase";

const AsideDefault = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { config } = useLayout();
  const { classes } = useLayout();
  const [link, setLink] = useState<string>("projects");
  const [isElectron, setIsElectron] = useState(false);

  useEffect(() => {
    // Check if running in Electron
    setIsElectron(window.electronAPIs?.isElectron ?? false);
  }, []);

  const navigateAndSetLink = (link: string) => {
    setLink(link);
    navigate(link);
  };

  useEffect(() => {
    const asideElement = document.querySelector(".aside-secondary");

    if (asideElement) {
      const width = window.getComputedStyle(asideElement).width;
      if (width === "0px" && location.pathname === "/chat") {
        document.getElementById("kt_aside_toggle")?.click();
      } else if (width !== "0px" && location.pathname !== "/chat") {
        document.getElementById("kt_aside_toggle")?.click();
      }
    }
  }, [location]);

  return (
    <div
      id="kt_aside"
      className={clsx(
        "aside aside-extended",
        classes.aside.join(" "),
        { "mw-400px": isElectron } // Add mw-400px if running in Electron
      )}
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="false"
      data-kt-drawer-width="auto"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_toggle"
    >
      {/* begin::Primary */}
      {!isElectron && ( // Hide the primary section if running in Electron
        <div className="aside-primary d-flex flex-column align-items-lg-center flex-row-auto">
          <div
            className="aside-nav d-flex flex-column align-items-center flex-column-fluid w-100 pt-5 pt-lg-0"
            id="kt_aside_nav"
          >
            <AsideTabs
              link={link}
              setLink={(link) => navigateAndSetLink(link)}
            />
          </div>

          <AsideFooter
            link={link}
            setLink={(link) => navigateAndSetLink(link)}
          />
        </div>
      )}
      {/* end::Primary */}

      {config.aside.secondaryDisplay && (
        <>
          {/* begin::Secondary */}
          <div className="aside-secondary d-flex flex-row-fluid">
            {/* begin::Workspace */}
            <div className="aside-workspace my-5 p-2" id="kt_aside_wordspace">
              {/* <TabsBase link={link} /> */}
              <TabsBase link={"projects"} />
            </div>
            {/* end::Workspace */}
          </div>
          {/* end::Secondary */}

          {/* begin::Aside Toggle */}
          {!isElectron && ( // Hide the toggle button if running in Electron
            <button
              id="kt_aside_toggle"
              className={clsx(
                "btn btn-sm btn-icon bg-body btn-color-gray-700 btn-active-primary position-absolute translate-middle start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex ms-2",
                classes.asideToggle.join(" ")
              )}
              data-kt-toggle="true"
              data-kt-toggle-state="active"
              data-kt-toggle-target="body"
              data-kt-toggle-name="aside-minimize"
              style={{ marginBottom: "1.35rem" }}
            >
              <KTIcon iconName="arrow-left" className="fs-2 rotate-180" />
            </button>
          )}
          {/* end::Aside Toggle */}
        </>
      )}
    </div>
  );
};

export { AsideDefault };
