/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

type Suggestion = {
  id: string;
  title: string;
  content: string;
};

type Props = {
  className: string;
  suggestions: Suggestion[];
};

const SuggestionsWidget1: React.FC<Props> = ({ className, suggestions }) => {
  return (
    <div
      className={`card bgi-no-repeat ${className}`}
      style={{
        backgroundPosition: "right top",
        backgroundSize: "30% auto",
      }}
    >
      <div className="card-body">
        {/* Title */}
        <h2 className="text-gray-800 fw-bold mb-4">The Chief's Suggestions</h2>

        {/* Accordion */}
        {suggestions.map((suggestion) => (
          <div key={suggestion.id} className="m-0">
            {/* Section Heading */}
            <div
              className="d-flex align-items-center collapsible py-3 toggle collapsed mb-0"
              data-bs-toggle="collapse"
              data-bs-target={`#${suggestion.id}`}
            >
              <div className="btn btn-sm btn-icon mw-20px btn-active-color-primary me-5">
                <i className="ki-duotone ki-minus-square toggle-on text-primary fs-1">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                <i className="ki-duotone ki-plus-square toggle-off fs-1">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                </i>
              </div>
              <h4 className="text-gray-700 fw-bold cursor-pointer mb-0">
                {suggestion.title}
              </h4>
            </div>

            {/* Section Content */}
            <div id={suggestion.id} className="collapse fs-6 ms-1">
              <div className="mb-4 text-gray-600 fw-semibold fs-6 ps-10">
                {suggestion.content}
              </div>
            </div>

            {/* Separator */}
            <div className="separator separator-dashed"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { SuggestionsWidget1 };
