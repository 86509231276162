import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { WizardContent } from "./WizardComponents";
import CompanyDetailsForm from "./CompanyDetailsForm";

interface CompanyDetailsProps {
  onNext: () => void;
  onBack: () => void;
  formData: {
    department_id: string;
    teamSize: string;
    name: string;
    companyDescription?: string;
    website: string;
  };
  setFormData: (data: any) => void;
}

const CompanyDetails = ({
  onNext,
  onBack,
  formData,
  setFormData,
}: CompanyDetailsProps) => {
  return (
    <Formik
      initialValues={formData}
      enableReinitialize={true}
      validateOnMount={true}
      validationSchema={Yup.object({
        department_id: Yup.string().required("Department is required"),
        teamSize: Yup.string().required("Team size is required"),
        name: Yup.string().required("Company name is required"),
        website: Yup.string().nullable().url("Please enter a valid URL"),
        companyDescription: Yup.string().nullable(),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        const depId = parseInt(values.department_id, 10);
        setFormData({
          ...values,
          department_id: depId,
        });

        setSubmitting(false);
        onNext();
      }}
    >
      {({ isValid, isSubmitting }) => (
        <Form
          className="form w-lg-500px mx-auto"
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <WizardContent>
            <div style={{ paddingBottom: "120px" }}>
              <CompanyDetailsForm />
            </div>
          </WizardContent>
          <div
            className="fixed-bottom pb-20 pt-10"
            style={{
              height: "80px",
              backgroundColor:
                document.documentElement.getAttribute("data-bs-theme") ===
                "dark"
                  ? "#151521"
                  : "#fff",
            }}
          >
            <div className="container d-flex justify-content-between w-50">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onBack}
              >
                Back
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isSubmitting || !isValid}
              >
                Next
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyDetails;
