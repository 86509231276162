/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import { useTable, ColumnInstance, Row } from "react-table";
import { observer } from "mobx-react";

import i18n from "../../../../../i18n";
import { KTCardBody } from "../../../../../helpers";
import { CustomUser } from "../../../../models/CustomUser";
import stores from "../../../../stores";

import { CompanyUserListLoading } from "../components/loading/CompanyUserListLoading";
import { CompanyUsersListPagination } from "../components/pagination/CompanyUsersListPagination";
import { companyUsersColumns } from "./columns/_columns";
import { CustomHeaderColumn } from "./columns/CustomHeaderColumn";
import { CustomRow } from "./columns/CustomRow";

export const CompanyUsersTable: React.FC = observer(() => {
  const refreshData = async () => {
    await stores.companyUserStore.getCompanyUsers();
    await stores.companyUserStore.getCompanyUserInvites();
    await stores.companyUserStore.getCustomUsers();
  };

  useEffect(() => {
    refreshData();
  }, [stores.companyUserStore.selectedCompanyUserInvite]);

  const data = stores.companyUserStore.filteredCustomUsers;
  const columns = useMemo(() => companyUsersColumns, []);

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  if (stores.companyUserStore.isLoading) {
    return (
      <div className="card mb-5 mb-xl-10">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <CompanyUserListLoading />
        </div>
      </div>
    );
  }

  return (
    <KTCardBody className="py-4">
      <div className="d-flex justify-content-between mb-4">
        <h3 className="card-title align-items-start flex-column">
          Company Users
        </h3>
      </div>
      <div className="table-responsive">
        <table
          id="kt_table_users"
          className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
          {...getTableProps()}
        >
          <thead>
            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
              {headers.map((column: ColumnInstance<CustomUser>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>

          <tbody className="text-gray-600 fw-bold" {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<CustomUser>, i) => {
                prepareRow(row);
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
              })
            ) : (
              <tr>
                <td colSpan={8}>
                  <div className="text-gray-600 d-flex text-center w-100 align-content-center justify-content-center mt-6 mb-4">
                    {i18n.CompanyUsersTable.noMatchingRecordsFound}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <CompanyUsersListPagination />
    </KTCardBody>
  );
});
