/* @flow */

import { DashboardSection } from "../../app/models/DashboardSection";
import { DefaultResult } from "../../app/models/DefaultResult";
import stores from "../../app/stores";

export default async function saveDashboardSection(
  section: DashboardSection
): Promise<DefaultResult> {
  return new Promise(async (resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/saveDashboardSection`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        companyId: Number(stores.companyStore.selectedUserCompany?.id),
        dashboardSectionId: Number(section.id),
        nodes: section.nodes,
        edges: section.edges,
      }),
      credentials:
        process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          const result: DefaultResult = data;

          if (result.success) {
            resolve(result);
          } else {
            reject();
          }
        } else {
          reject();
        }
      });
  });
}
