/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTIcon } from "../../../helpers";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";

type TodoItem = {
  title: string;
  description: string;
  dueDate: string;
  bulletColor: string;
  badgeText: string;
  badgeColor: string;
  checked?: boolean;
};

type Props = {
  className: string;
  todos: TodoItem[];
};

const ListsWidget3: React.FC<Props> = ({ className, todos }) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0">
        <h3 className="card-title fw-bold text-dark">Todo</h3>
        <div className="card-toolbar">
          {/* begin::Menu */}
          <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <KTIcon iconName="category" className="fs-2" />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body pt-2">
        {todos.map((todo, index) => (
          <div key={index} className="d-flex align-items-center mb-8">
            {/* begin::Bullet */}
            <span
              className={`bullet bullet-vertical h-40px bg-${todo.bulletColor}`}
            ></span>
            {/* end::Bullet */}
            {/* begin::Checkbox */}
            <div className="form-check form-check-custom form-check-solid mx-5">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                defaultChecked={todo.checked}
              />
            </div>
            {/* end::Checkbox */}
            {/* begin::Description */}
            <div className="flex-grow-1">
              <a
                href="#"
                className="text-gray-800 text-hover-primary fw-bold fs-6"
              >
                {todo.title}
              </a>
              <span className="text-muted fw-semibold d-block">
                {todo.description}
              </span>
            </div>
            {/* end::Description */}
            <span
              className={`badge badge-light-${todo.badgeColor} fs-8 fw-bold`}
            >
              {todo.badgeText}
            </span>
          </div>
        ))}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { ListsWidget3 };
