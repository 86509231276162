import React from "react";
import { observer } from "mobx-react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import ReactGA from "react-ga4";
import { toast } from "react-toastify";

import { CompanyAvatarStatus, FeatureType } from "../../../helpers/Enums";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { CompanyAvatar } from "../../models/CompanyAvatar";
import i18n from "../../../i18n";
import { AvatarPageLoading } from "./loading/AvatarPageLoading";

import stores from "../../stores";

type Props = {
  navigate: (path: string) => void;
};

type State = {
  selectedAvatar?: CompanyAvatar;
  isCreateNewAvatar: boolean;
  showDeleteConfirmation: boolean;
  avatarToDelete?: CompanyAvatar;
  isEditMode: boolean;
  deleting: boolean;
  loadingAvatarId?: number;
};

@observer
class AvatarsPage extends React.Component<Props, State> {
  state: State = {
    selectedAvatar: undefined,
    isCreateNewAvatar: false,
    showDeleteConfirmation: false,
    avatarToDelete: undefined,
    isEditMode: false,
    deleting: false,
    loadingAvatarId: undefined,
  };

  async componentWillMount() {
    try {
      await stores.companyAvatarStore.getCompanyAvatars();
    } catch (error) {
      console.error("Failed to get company avatars on mount:", error);
    }
  }

  handleAvatarSelect = (avatar: CompanyAvatar) => {
    this.setState({ selectedAvatar: avatar });
  };

  handleCreateNewAvatar = async () => {
    if (
      !stores.companyModelStore.companyModels ||
      stores.companyModelStore.companyModels.length === 0
    ) {
      toast.error(i18n.ToastMessages.companyModelMissing, {
        position: "top-center",
        autoClose: 5000,
      });
      return;
    }
    if (
      !stores.companyAppStore.companyApps ||
      stores.companyAppStore.companyApps.length === 0
    ) {
      toast.error(i18n.ToastMessages.companyAppMissing, {
        position: "top-center",
        autoClose: 5000,
      });
      return;
    }

    ReactGA.event({
      category: "avatars_page",
      action: "create_new_avatar",
      label: "create_new_avatar",
    });

    const hasCreateAvatarPermission =
      await stores.userStore.checkSubscribedFeatureType(
        FeatureType.CreateAvatar
      );

    if (!hasCreateAvatarPermission) {
      toast.error(i18n.ToastMessages.maxAvatarError, {
        position: "top-center",
        autoClose: 5000,
      });
      return;
    }

    this.setState({ isCreateNewAvatar: true, isEditMode: false });
    stores.wizardStore.resetWizard();
  };

  deleteAvatar = async (avatar: CompanyAvatar) => {
    if (avatar.dashboard_section_id !== null) {
      toast.error("You do not have permission to delete this avatar.", {
        position: "top-center",
        autoClose: 5000,
      });
      return;
    }

    ReactGA.event({
      category: "avatars_page",
      action: "delete_avatar",
      label: "delete_avatar_button",
    });

    this.setState({ showDeleteConfirmation: true, avatarToDelete: avatar });
  };

  editAvatar = async (avatar: CompanyAvatar) => {
    if (avatar.dashboard_section_id !== null) {
      toast.error("You do not have permission to edit this avatar.", {
        position: "top-center",
        autoClose: 5000,
      });
      return;
    }

    ReactGA.event({
      category: "avatars_page",
      action: "edit_avatar",
      label: "edit_avatar_button",
    });
    stores.wizardStore.populateWizardForEditing(avatar);
    this.setState({ isCreateNewAvatar: true, isEditMode: true });
  };

  handleCloseWizard = () => {
    this.setState({ isCreateNewAvatar: false, isEditMode: false });
    stores.wizardStore.resetWizard();
    stores.wizardStore.setIsWizardActive(false);
  };

  confirmDeleteAvatar = async () => {
    const { avatarToDelete } = this.state;
    if (avatarToDelete && avatarToDelete.id) {
      this.setState({ deleting: true });
      try {
        const success =
          await stores.companyAvatarStore.deleteCompanyAvatarWithIndex(
            avatarToDelete.id
          );

        if (success) {
          await stores.companyAvatarStore.getCompanyAvatars();
        } else {
          toast.error(i18n.ToastMessages.deleteFailed, {
            position: "top-center",
            autoClose: 5000,
          });
        }
      } catch (error) {
        console.error("Error occurred:", error);
        toast.error(i18n.ToastMessages.deleteFailed, {
          position: "top-center",
          autoClose: 5000,
        });
      } finally {
        this.setState({
          deleting: false,
          showDeleteConfirmation: false,
          avatarToDelete: undefined,
        });
      }
    }
  };

  startChatWithAvatar = async (avatar: CompanyAvatar) => {
    ReactGA.event({
      category: "avatars_page",
      action: "start_chat_with_avatar",
      label: "start_chat_with_avatar_button",
    });

    this.setState({ loadingAvatarId: avatar.id });

    stores.companyAvatarStore.selectedCompanyAvatar = avatar;

    if (!stores.companyStore.selectedUserCompany) {
      console.error("No company selected");
      this.setState({ loadingAvatarId: undefined });
      return;
    }

    const documentName = `Chat with ${avatar.name}`;

    try {
      const newDocument = await stores.userDocumentStore.createUserDocument(
        documentName
      );
      stores.userDocumentStore.selectedUserDocument = newDocument;
      stores.userDocumentStore.lastSelectedDocumentId = newDocument.id;

      this.setState({ loadingAvatarId: undefined });

      this.props.navigate("/chat");
    } catch (error) {
      console.error("Failed to create chat document:", error);
      this.setState({ loadingAvatarId: undefined });
    }
  };

  renderAvatarStatusBadge = (status: CompanyAvatarStatus) => {
    let iconPath = "";
    let badgeClass = "";
    let labelText = "";

    switch (status) {
      case CompanyAvatarStatus.Connected:
        iconPath = "/media/icons/duotune/general/gen048.svg";
        badgeClass = "text-gray-600 text-hover-primary";
        labelText = "Connected";
        break;
      case CompanyAvatarStatus.InProgress:
        iconPath = "/media/icons/duotune/general/gen049.svg";
        badgeClass = "text-gray-600 text-hover-warning";
        labelText = "In Progress";
        break;
      case CompanyAvatarStatus.Failed:
        iconPath = "/media/icons/duotune/general/gen050.svg";
        badgeClass = "text-gray-600 text-hover-danger";
        labelText = "Failed";
        break;
      default:
        iconPath = "/media/icons/duotune/general/gen050.svg";
        badgeClass = "text-gray-600 text-hover-danger";
        labelText = "Not Connected";
        break;
    }

    return (
      <span className={`${badgeClass} fs-8 ms-2 mb-1 fw-bold`}>
        <KTSVG path={iconPath} className="svg-icon svg-icon-3 me-1" />
        {labelText}
      </span>
    );
  };

  renderAvatarItem = (avatar: CompanyAvatar, index: number) => {
    const { loadingAvatarId } = this.state;
    const isLoadingThisAvatar = loadingAvatarId === avatar.id;
    const isAnyLoading = loadingAvatarId !== undefined;
    const department = avatar.company_avatar_departments[0]?.department;
    const departmentName = department ? department.name : "Unknown";

    const canDelete = avatar.dashboard_section_id === null;
    const canEdit = avatar.dashboard_section_id === null;

    return (
      <div key={index} className="d-flex align-items-center mb-7">
        <div className="symbol symbol-60px me-1">
          <img
            src={toAbsoluteUrl(avatar.logo_url)}
            className="align-self-center"
            alt={avatar.name}
          />
        </div>

        <div className="py-3 px-4 me-1 mb-3">
          <div className="d-flex">
            <span className="fs-6 text-gray-800 fw-bolder">{avatar.name}</span>
            {this.renderAvatarStatusBadge(avatar.status)}
          </div>

          <div className="fw-bold text-gray-500">{departmentName}</div>

          <div className="text-gray-500">{avatar.description}</div>
        </div>
        <div className="">
          <div
            className="d-flex flex-wrap justify-content-start align-items-center symbol-group symbol-hover mx-6"
            style={{ maxWidth: "100%" }}
          >
            {avatar.company_avatar_apps?.slice(0, 10).map((app, index) => {
              const matchingApp = stores.companyAppStore.companyApps.find(
                (companyApp) =>
                  companyApp.id.toString() === app.company_app_id.toString()
              );

              return (
                <div
                  key={app.id}
                  className="symbol symbol-25px symbol-circle bg-body me-2 "
                  title={matchingApp ? matchingApp.app.name : "App not found"}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                >
                  <img
                    src={matchingApp?.app.logo || "/path/to/default/logo.png"}
                    alt={matchingApp?.app.name || "Unknown"}
                    className="rounded-circle"
                    style={{
                      width: "25px",
                      height: "25px",
                      padding: "3px",
                    }}
                  />
                </div>
              );
            })}

            {avatar.company_avatar_apps?.length > 10 && (
              <span
                className="symbol symbol-30px symbol-circle"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title={`+${avatar.company_avatar_apps.length - 10} more`}
              >
                <span className="symbol-label fs-8">
                  +{avatar.company_avatar_apps.length - 10}
                </span>
              </span>
            )}
          </div>
        </div>

        <div className="ms-auto d-flex">
          {/* Start Chat Icon */}
          <button
            className="btn btn-icon btn-light-primary me-2"
            disabled={
              avatar.status !== CompanyAvatarStatus.Connected || isAnyLoading
            }
            onClick={() => this.startChatWithAvatar(avatar)}
          >
            {isLoadingThisAvatar ? (
              <Spinner as="span" animation="border" size="sm" />
            ) : (
              <KTSVG
                path="/media/icons/duotune/communication/com012.svg"
                className="svg-icon-3"
              />
            )}
          </button>
          {/* Edit Icon */}
          <button
            className="btn btn-icon btn-light-warning me-2"
            onClick={() => this.editAvatar(avatar)}
            disabled={isAnyLoading}
            title={
              !canEdit
                ? "You do not have permission to edit this avatar."
                : "Edit Avatar"
            }
          >
            <KTSVG
              path="/media/icons/duotune/art/art005.svg"
              className="svg-icon-3"
            />
          </button>
          {/* Delete Icon */}
          <button
            className="btn btn-icon btn-light-danger"
            onClick={() => this.deleteAvatar(avatar)}
            disabled={isAnyLoading}
            title={
              !canDelete
                ? "You do not have permission to delete this avatar."
                : "Delete Avatar"
            }
          >
            <KTSVG
              path="/media/icons/duotune/general/gen027.svg"
              className="svg-icon-3"
            />
          </button>
        </div>
      </div>
    );
  };

  render() {
    const {
      isCreateNewAvatar: isCreatNewAvatar,
      showDeleteConfirmation,
      deleting,
      isEditMode,
      loadingAvatarId,
    } = this.state;
    const { isLoading } = stores.companyModelStore;

    if (isLoading) {
      return <AvatarPageLoading />;
    }

    if (deleting) {
      return <AvatarPageLoading />;
    }

    if (isCreatNewAvatar || isEditMode) {
      return <Navigate to={"/create-new-avatar"} />;
    }

    // Filtrelenmiş avatarlar
    const filteredAvatars = stores.companyAvatarStore.companyAvatars.filter(
      (avatar) => avatar.dashboard_section_id === null
    );

    return (
      <div className="card">
        <div className="card-header d-flex justify-content-between align-items-center">
          <h3 className="card-title">Manage Avatars</h3>
          <Button
            variant="primary"
            onClick={this.handleCreateNewAvatar}
            disabled={!!loadingAvatarId}
          >
            Create New Avatar
          </Button>
        </div>
        <div className="card-body">
          {filteredAvatars.length > 0 ? (
            filteredAvatars.map(this.renderAvatarItem)
          ) : (
            <div>You don't have any avatars.</div>
          )}

          <Modal
            show={showDeleteConfirmation}
            onHide={() => this.setState({ showDeleteConfirmation: false })}
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Delete Avatar</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Are you sure you want to delete this avatar?
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({ showDeleteConfirmation: false });
                  ReactGA.event({
                    category: "avatars_page",
                    action: "cancel_delete_avatar_modal",
                    label: "cancel_delete_avatar_modal_button",
                  });
                }}
                disabled={!!loadingAvatarId}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                onClick={() => {
                  this.confirmDeleteAvatar();
                }}
                disabled={!!loadingAvatarId}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

export default AvatarsPage;
