/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";

type TrendItem = {
  icon: string; // URL for the icon image
  title: string;
  description: string;
  badgeText: string;
  badgeColor: string;
};

type Props = {
  className: string;
  trends: TrendItem[];
};

const ListsWidget4: React.FC<Props> = ({ className, trends }) => {
  return (
    <div className={`card card-xl-stretch mb-xl-8 ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold text-dark">Trends</span>
          <span className="text-muted mt-1 fw-semibold fs-7">
            Latest tech trends
          </span>
        </h3>
        <div className="card-toolbar">
          {/* begin::Menu */}
          <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <KTIcon iconName="category" className="fs-2" />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body pt-5">
        {trends.map((trend, index) => (
          <div key={index} className="d-flex align-items-sm-center mb-7">
            {/* begin::Symbol */}
            <div className="symbol symbol-50px me-5">
              <span className="symbol-label">
                <img
                  src={toAbsoluteUrl(trend.icon)}
                  className="h-50 align-self-center"
                  alt={trend.title}
                />
              </span>
            </div>
            {/* end::Symbol */}
            {/* begin::Section */}
            <div className="d-flex align-items-center flex-row-fluid flex-wrap">
              <div className="flex-grow-1 me-2">
                <a
                  href="#"
                  className="text-gray-800 text-hover-primary fs-6 fw-bold"
                >
                  {trend.title}
                </a>
                <span className="text-muted fw-semibold d-block fs-7">
                  {trend.description}
                </span>
              </div>
              <span
                className={`badge badge-light-${trend.badgeColor} fw-bold my-2`}
              >
                {trend.badgeText}
              </span>
            </div>
            {/* end::Section */}
          </div>
        ))}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { ListsWidget4 };
