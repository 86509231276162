import React from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import { toAbsoluteUrl } from "../../../../helpers";
import { CompanyModel } from "../../../models/CompanyModel";
import stores from "../../../stores";
import { UserDocument } from "../../../models/UserDocument";
import {
  CompanyIndexStatus,
  CompanyModelStatus,
} from "../../../../helpers/Enums";

interface Props {
  userDocument?: UserDocument;
  onCompanyModelSelect: (companyModel: CompanyModel | undefined) => void;
  isTheChiefFeatureAvailable: boolean;
}

@observer
export default class ModelSelectionTableView extends React.Component<Props> {
  state = {
    isElectron: false,
  };

  constructor(props: Props) {
    super(props);

    // Check if running in Electron
    this.state.isElectron = window.electronAPIs?.isElectron ?? false;
  }

  private renderCompanyModel = (companyModel: CompanyModel, index: number) => {
    return (
      <tr key={companyModel.id} className="odd">
        <td>
          <div
            onClick={() => this.props.onCompanyModelSelect(companyModel)}
            className="d-flex align-items-center cursor-pointer"
          >
            <div className="me-5 position-relative">
              <div className="symbol symbol-50px me-1">
                <span className="symbol-label">
                  <img
                    src={toAbsoluteUrl(companyModel.model.logo)}
                    className="h-50 align-self-center"
                    alt=""
                  />
                </span>
              </div>
            </div>

            <div className="d-flex flex-column justify-content-center">
              <button
                type="button"
                className="fs-6 text-gray-800 text-hover-primary btn btn-link p-0"
                onClick={() => this.props.onCompanyModelSelect(companyModel)}
              >
                {companyModel.model.name}
              </button>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  render() {
    const { isElectron } = this.state;
    const connectedCompanyModels =
      stores.companyModelStore.companyModels.filter(
        (model) => model.status === CompanyModelStatus.Connected
      );

    return (
      <div className="card">
        <div
          style={{ maxHeight: "50vh", overflow: "scroll" }}
          className="card-body pt-0"
        >
          <div className="table-responsive">
            <table
              id="kt_profile_overview_table"
              className="table table-row-bordered table-row-dashed gy-4 align-middle fw-bold dataTable no-footer"
            >
              <tbody className="fs-6">
                {this.props.isTheChiefFeatureAvailable &&
                  stores.companyStore.selectedUserCompany &&
                  stores.companyStore.selectedUserCompany.index_status ===
                    CompanyIndexStatus.Connected && (
                    <tr
                      key={stores.companyStore.selectedUserCompany.id}
                      className="odd"
                    >
                      <td>
                        <div
                          onClick={() =>
                            this.props.onCompanyModelSelect(undefined)
                          }
                          className="d-flex align-items-center cursor-pointer"
                        >
                          <div className="me-5 position-relative">
                            <div className="symbol symbol-50px me-1">
                              <span className="symbol-label">
                                <img
                                  src={toAbsoluteUrl(
                                    "https://imagedelivery.net/01kSRcVEVAYmOHOBQNSt0A/93bbf41d-9c33-44c7-b6eb-38fe5b9a1300/mid"
                                  )}
                                  className="h-50 align-self-center"
                                  alt=""
                                />
                              </span>
                            </div>
                          </div>

                          <div className="d-flex flex-column justify-content-center">
                            <button
                              type="button"
                              className="fs-6 text-gray-800 text-hover-primary btn btn-link p-0"
                              onClick={() =>
                                this.props.onCompanyModelSelect(undefined)
                              }
                            >
                              The Chief
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}

                {connectedCompanyModels.length > 0 ? (
                  connectedCompanyModels.map((companyModel, index) =>
                    this.renderCompanyModel(companyModel, index)
                  )
                ) : (
                  <tr>
                    <td className="text-center" colSpan={2}>
                      <div className="text-gray-600 d-flex text-center w-100 align-content-center justify-content-center mt-8">
                        Model provider not found.
                      </div>
                      {!isElectron && (
                        <Link
                          to="/models"
                          className="btn btn-primary px-6 py-3 mt-3"
                        >
                          Connect Model
                        </Link>
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
