/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTIcon } from "../../../helpers";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";

type CalendarItem = {
  time: string;
  badgeColor: string;
  content: string;
  link?: string;
  linkText?: string;
};

type Props = {
  className: string;
  title: string;
  subtitle: string;
  calendarItems: CalendarItem[];
};

const CalendarWidget1: React.FC<Props> = ({
  className,
  title,
  subtitle,
  calendarItems,
}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header align-items-center border-0 mt-4">
        <h3 className="card-title align-items-start flex-column">
          <span className="fw-bold mb-2 text-dark">{title}</span>
          <span className="text-muted fw-semibold fs-7">{subtitle}</span>
        </h3>
        <div className="card-toolbar">
          {/* begin::Menu */}
          <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <KTIcon iconName="category" className="fs-2" />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body pt-5">
        {/* begin::Timeline */}
        <div className="timeline-label">
          {calendarItems.map((item, index) => (
            <div key={index} className="timeline-item">
              {/* begin::Label */}
              <div className="timeline-label fw-bold text-gray-800 fs-6">
                {item.time}
              </div>
              {/* end::Label */}
              {/* begin::Badge */}
              <div className="timeline-badge">
                <i
                  className={`fa fa-genderless text-${item.badgeColor} fs-1`}
                ></i>
              </div>
              {/* end::Badge */}
              {/* begin::Content */}
              <div
                className={`timeline-content ${
                  item.link ? "fw-bold text-gray-800" : "fw-mormal text-muted"
                } ps-3`}
              >
                {item.content}
                {item.link && (
                  <a href={item.link} className="text-primary">
                    {item.linkText}
                  </a>
                )}
              </div>
              {/* end::Content */}
            </div>
          ))}
        </div>
        {/* end::Timeline */}
      </div>
      {/* end: Card Body */}
    </div>
  );
};

export { CalendarWidget1 };
