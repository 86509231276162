/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

type TableRow = {
  logo: string;
  title: string;
  description: string;
  progress: string;
};

type Props = {
  className: string;
  title: string;
  subtitle: string;
  rows: TableRow[];
};

const TablesWidget1: React.FC<Props> = ({
  className,
  title,
  subtitle,
  rows,
}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">{title}</span>
          <span className="text-muted fw-semibold fs-7">{subtitle}</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body py-3">
        {/* begin::Table container */}
        <div className="table-responsive">
          {/* begin::Table */}
          <table className="table align-middle gs-0 gy-5">
            {/* begin::Table head */}
            <thead>
              <tr>
                <th className="p-0 w-50px"></th>
                <th className="p-0 min-w-200px"></th>
                <th className="p-0 min-w-100px"></th>
                <th className="p-0 min-w-40px"></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {rows.map((row, index) => (
                <tr key={index}>
                  <th>
                    <div className="symbol symbol-50px me-2">
                      <span className="symbol-label">
                        {/* <img
                          src={toAbsoluteUrl(row.logo)}
                          className="h-50 align-self-center"
                          alt="logo"
                        /> */}
                      </span>
                    </div>
                  </th>
                  <td>
                    <a
                      href="#"
                      className="text-dark fw-bold text-hover-primary mb-1 fs-6"
                    >
                      {row.title}
                    </a>
                    <span className="text-muted fw-semibold d-block fs-7">
                      {row.description}
                    </span>
                  </td>
                  <td>
                    <div className="d-flex flex-column w-100 me-2">
                      <div className="d-flex flex-stack mb-2">
                        <span className="text-muted me-2 fs-7 fw-semibold">
                          {row.progress}
                        </span>
                      </div>
                      <div className="progress h-6px w-100">
                        <div
                          className="progress-bar bg-primary"
                          role="progressbar"
                          style={{ width: row.progress }}
                        ></div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
    </div>
  );
};

export { TablesWidget1 };
