/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import { KTIcon } from "../../../helpers";
import ApexCharts, { ApexOptions } from "apexcharts";
import { getCSS, getCSSVariableValue } from "../../../assets/ts/_utils";
import clsx from "clsx";
import { useThemeMode } from "../../layout/theme-mode/ThemeModeProvider";

type Props = {
  className: string;
  svgIcon: string;
  color: string;
  change: string;
  description: string;
};

const StatisticsWidget4: React.FC<Props> = ({
  className,
  svgIcon,
  color,
  change,
  description,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();

  // Function to initialize or refresh the chart
  const refreshChart = () => {
    if (!chartRef.current) return;

    const height = parseInt(getCSS(chartRef.current, "height") || "150");
    const labelColor = getCSSVariableValue("--bs-gray-800");
    const baseColor = getCSSVariableValue(`--bs-${color}`);
    const lightColor = getCSSVariableValue(`--bs-${color}-light`);

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, labelColor, baseColor, lightColor)
    );

    chart.render();
    return chart;
  };

  // Effect to handle chart rendering and cleanup
  useEffect(() => {
    const chart = refreshChart();
    return () => {
      if (chart) chart.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, color, mode]);

  return (
    <div className={`card ${className}`}>
      {/* Card Body */}
      <div className="card-body p-0">
        {/* Icon and Data */}
        <div className="d-flex flex-stack card-p flex-grow-1">
          <span className={clsx("symbol symbol-50px", "me-2")}>
            <span className={clsx("symbol-label", `bg-light-${color}`)}>
              <KTIcon iconName={svgIcon} className={`fs-2x text-${color}`} />
            </span>
          </span>
          <div className="d-flex flex-column text-end">
            <span className="text-dark fw-bold fs-2">{change}</span>
            <span className="text-muted fw-semibold mt-1">{description}</span>
          </div>
        </div>

        {/* Chart */}
        <div
          ref={chartRef}
          className="statistics-widget-4-chart card-rounded-bottom"
          style={{ height: "150px" }}
        ></div>
      </div>
    </div>
  );
};

export { StatisticsWidget4 };

// Chart Options Function
function getChartOptions(
  height: number,
  labelColor: string,
  baseColor: string,
  lightColor: string
): ApexOptions {
  return {
    series: [
      {
        name: "Net Profit",
        data: [40, 40, 30, 30, 35, 35, 50],
      },
    ],
    chart: {
      fontFamily: "inherit",
      type: "area",
      height,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: { show: false },
    dataLabels: { enabled: false },
    fill: {
      type: "solid",
      opacity: 1,
    },
    stroke: {
      curve: "smooth",
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
      labels: { show: false, style: { colors: labelColor, fontSize: "12px" } },
      axisBorder: { show: false },
      axisTicks: { show: false },
      crosshairs: { show: false },
    },
    yaxis: {
      min: 0,
      max: 60,
      labels: { show: false, style: { colors: labelColor, fontSize: "12px" } },
    },
    tooltip: {
      style: { fontSize: "12px" },
      y: {
        formatter: (val) => `$${val} thousands`,
      },
    },
    colors: [lightColor],
    markers: {
      colors: [lightColor],
      strokeColors: [baseColor],
      strokeWidth: 3,
    },
  };
}
