import React from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { observer } from "mobx-react";

import { PageLink, PageTitle } from "../../../layout/core";
import TheChiefDashboard from "./TheChiefDashboard";

const TheChiefDashboardBreadCrumbs: Array<PageLink> = [
  {
    title: "Home",
    path: "/the-chief-workspace",
    isSeparator: false,
    isActive: false,
  },
];

interface State {}

@observer
class TheChiefDashboardWrapper extends React.Component<
  WrappedComponentProps,
  State
> {
  constructor(props: WrappedComponentProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { intl } = this.props;

    return (
      <>
        <PageTitle breadcrumbs={TheChiefDashboardBreadCrumbs}>
          {intl.formatMessage({ id: "MENU.THECHIEFDASHBOARD" })}
        </PageTitle>

        <TheChiefDashboard />
      </>
    );
  }
}

const TheChiefDashboardWrapperWithIntl = injectIntl(TheChiefDashboardWrapper);

export { TheChiefDashboardWrapperWithIntl as TheChiefDashboardWrapper };
