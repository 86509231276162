/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { KTIcon } from "../../../helpers";
import { getCSSVariableValue } from "../../../assets/ts/_utils";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";
import clsx from "clsx";
import { useThemeMode } from "../../layout/theme-mode/ThemeModeProvider";

type StatItem = {
  label: string;
  value: string;
};

type Props = {
  className: string;
  chartColor: string;
  chartHeight: string;
  headerTitle: string;
  stats: StatItem[][];
  chartData: {
    name: string;
    data: number[];
  }[];
  categories: string[];
};

const MixedWidget3: React.FC<Props> = ({
  className,
  chartColor,
  chartHeight,
  headerTitle,
  stats,
  chartData,
  categories,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const { mode } = useThemeMode();

  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartHeight, chartData, categories)
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, mode]);

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className={`card-header border-0 bg-${chartColor} py-5`}>
        <h3 className="card-title fw-bold text-white">{headerTitle}</h3>

        <div className="card-toolbar">
          <button
            type="button"
            className={clsx(
              "btn btn-sm btn-icon btn-color-white btn-active-white",
              `btn-active-color-${chartColor}`,
              "border-0 me-n3"
            )}
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <KTIcon iconName="category" className="fs-2" />
          </button>
          <Dropdown1 />
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body p-0">
        <div
          ref={chartRef}
          className={`mixed-widget-12-chart card-rounded-bottom bg-${chartColor}`}
        ></div>

        <div className="card-rounded bg-body mt-n10 position-relative card-px py-15">
          {stats.map((row, rowIndex) => (
            <div className="row g-0 mb-7" key={rowIndex}>
              {row.map((stat, index) => (
                <div className="col mx-5" key={index}>
                  <div className="fs-6 text-gray-400">{stat.label}</div>
                  <div className="fs-2 fw-bold text-gray-800">{stat.value}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
      {/* end::Body */}
    </div>
  );
};

const chartOptions = (
  chartHeight: string,
  chartData: { name: string; data: number[] }[],
  categories: string[]
): ApexOptions => {
  const labelColor = getCSSVariableValue("--bs-gray-500");
  const borderColor = getCSSVariableValue("--bs-gray-200");

  return {
    series: chartData,
    chart: {
      fontFamily: "inherit",
      type: "bar",
      height: chartHeight,
      toolbar: { show: false },
      sparkline: { enabled: true },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        borderRadius: 5,
      },
    },
    legend: { show: false },
    dataLabels: { enabled: false },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    xaxis: {
      categories,
      axisBorder: { show: false },
      axisTicks: { show: false },
      labels: { style: { colors: labelColor, fontSize: "12px" } },
    },
    yaxis: {
      labels: { style: { colors: labelColor, fontSize: "12px" } },
    },
    fill: {
      type: ["solid", "solid"],
      opacity: [0.25, 1],
    },
    tooltip: {
      y: {
        formatter: (val) => `$${val} thousands`,
      },
    },
    colors: ["#ffffff", "#ffffff"],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
    },
  };
};

export { MixedWidget3 };
