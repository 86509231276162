import React, { ReactNode } from "react";

interface WizardLayoutProps {
  children: ReactNode;
  currentStep: number;
}

export const WizardLayout: React.FC<WizardLayoutProps> = ({
  children,
  currentStep,
}) => (
  <div className="d-flex flex-column h-100">
    <WizardHeader currentStep={currentStep} />
    <div className="flex-grow-1 overflow-auto px-5">{children}</div>
  </div>
);

interface WizardHeaderProps {
  currentStep: number;
}

export const WizardHeader: React.FC<WizardHeaderProps> = ({ currentStep }) => {
  const steps = [{ title: "Company Details" }, { title: "Select Plan" }];

  return (
    <div className="pt-8 pb-5">
      <div className="d-flex justify-content-center align-items-center">
        {steps.map((item, stepIndex) => {
          const stepNumber = stepIndex + 1;
          let stepState: "completed" | "current" | "pending" = "pending";

          if (currentStep > stepNumber) {
            stepState = "completed";
          } else if (currentStep === stepNumber) {
            stepState = "current";
          }

          const isCurrent = stepState === "current";
          const isCompleted = stepState === "completed";

          return (
            <div
              key={stepIndex}
              className={`
                text-center px-4 py-2 me-4 fs-4 
                ${isCurrent ? "border-primary border-3 fw-bold" : ""}
                ${!isCurrent ? "border-1" : ""}
                border-bottom 
                ${
                  isCompleted
                    ? "text-muted"
                    : isCurrent
                    ? "text-primary"
                    : "text-muted"
                }
              `}
              style={{ cursor: "pointer" }}
            >
              {item.title}
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface WizardContentProps {
  children: ReactNode;
}

export const WizardContent: React.FC<WizardContentProps> = ({ children }) => (
  <div className="flex-grow-1 overflow-auto px-5">{children}</div>
);

interface WizardFooterProps {
  onBack: () => void;
  onNext: () => void;
  currentStep: number;
  isLastStep: boolean;
}

export const WizardFooter: React.FC<WizardFooterProps> = ({
  onBack,
  onNext,
  currentStep,
  isLastStep,
}) => {
  return (
    <div className="d-flex justify-content-center mt-5">
      <div className="d-flex pb-10 pt-20 w-50 align-items-center justify-content-between">
        {currentStep > 1 && (
          <button className="btn btn-secondary me-5" onClick={onBack}>
            Back
          </button>
        )}
        {!isLastStep && (
          <button className="btn btn-primary" onClick={onNext}>
            Next
          </button>
        )}
        {isLastStep && (
          <button className="btn btn-success" onClick={onNext}>
            Finish
          </button>
        )}
      </div>
    </div>
  );
};
