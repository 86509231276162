/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";

type ProductItem = {
  imageUrl: string;
  title: string;
  description: string;
  rating: number;
};

type Props = {
  className: string;
  headerTitle: string;
  headerSubtitle: string;
  products: ProductItem[];
};

const ListsWidget8: React.FC<Props> = ({
  className,
  headerTitle,
  headerSubtitle,
  products,
}) => {
  return (
    <div className={`card card-xl-stretch mb-5 mb-xl-8 ${className}`}>
      {/* begin::Header */}
      <div className="card-header align-items-center border-0 mt-4">
        <h3 className="card-title align-items-start flex-column">
          <span className="fw-bold text-dark">{headerTitle}</span>
          <span className="text-muted mt-1 fw-semibold fs-7">
            {headerSubtitle}
          </span>
        </h3>
        <div className="card-toolbar">
          {/* begin::Menu */}
          <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <KTIcon iconName="category" className="fs-2" />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body pt-3">
        {products.map((product, index) => (
          <div key={index} className="d-flex align-items-sm-center mb-7">
            {/* begin::Symbol */}
            <div className="symbol symbol-60px symbol-2by3 me-4">
              <div
                className="symbol-label"
                style={{
                  backgroundImage: `url(${toAbsoluteUrl(product.imageUrl)})`,
                }}
              ></div>
            </div>
            {/* end::Symbol */}
            {/* begin::Content */}
            <div className="d-flex flex-row-fluid align-items-center flex-wrap my-lg-0 me-2">
              {/* begin::Title */}
              <div className="flex-grow-1 my-lg-0 my-2 me-2">
                <a
                  href="#"
                  className="text-gray-800 fw-bold text-hover-primary fs-6"
                >
                  {product.title}
                </a>
                <span className="text-muted fw-semibold d-block pt-1">
                  {product.description}
                </span>
              </div>
              {/* end::Title */}
              {/* begin::Section */}
              <div className="d-flex align-items-center">
                <div className="me-6">
                  <i className="fa fa-star-half-alt me-1 text-warning fs-5"></i>
                  <span className="text-gray-800 fw-bold">
                    {product.rating.toFixed(1)}
                  </span>
                </div>
                <a href="#" className="btn btn-icon btn-light btn-sm border-0">
                  <KTIcon
                    iconName="arrow-right"
                    className="fs-2 text-primary"
                  />
                </a>
              </div>
              {/* end::Section */}
            </div>
            {/* end::Content */}
          </div>
        ))}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { ListsWidget8 };
