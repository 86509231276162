/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTIcon, toAbsoluteUrl } from "../../../helpers";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";

type Author = {
  avatar: string;
  name: string;
  role: string;
};

type Props = {
  className: string;
  authors: Author[];
  title?: string;
};

const ListsWidget2: React.FC<Props> = ({
  className,
  authors = [],
  title = "Authors",
}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0">
        <h3 className="card-title fw-bold text-dark">{title}</h3>
        <div className="card-toolbar">
          {/* begin::Menu */}
          <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <KTIcon iconName="category" className="fs-2" />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body pt-2">
        {authors.map((author, index) => (
          <div className="d-flex align-items-center mb-7" key={index}>
            {/* begin::Avatar */}
            <div className="symbol symbol-50px me-5">
              <img src={toAbsoluteUrl(author.avatar)} className="" alt="" />
            </div>
            {/* end::Avatar */}
            {/* begin::Text */}
            <div className="flex-grow-1">
              <a href="#" className="text-dark fw-bold text-hover-primary fs-6">
                {author.name}
              </a>
              <span className="text-muted d-block fw-semibold">
                {author.role}
              </span>
            </div>
            {/* end::Text */}
          </div>
        ))}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { ListsWidget2 };
