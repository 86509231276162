/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";

import { MenuComponent } from "../../../assets/ts/components";
import { DashboardSection } from "../../models/DashboardSection";

export const CustomSectionViewActionModal: React.FC<{
  section: DashboardSection;
  onDeletePress: () => void;
  onUpdatePress: () => void;
}> = ({ section, onDeletePress, onUpdatePress }) => {
  useEffect(() => {
    MenuComponent.reinitialization();
  }, []);

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
          Actions
        </div>
      </div>

      <div className="px-3 py-3">
        <a
          onClick={onDeletePress}
          className="px-3 d-flex justify-content-between align-items-center text-danger cursor-pointer"
        >
          Delete
          <i className="fas fa-trash-alt text-danger"></i>
        </a>

        <a
          onClick={onUpdatePress}
          className="px-3 d-flex justify-content-between align-items-center text-warning cursor-pointer mt-6"
        >
          Update
          <i className="fas fa-edit text-warning"></i>
        </a>
      </div>
    </div>
  );
};
