/* @flow */
export default async function getGoogleAccessToken(
  integrationId: string,
  companyAppId: string
): Promise<string> {
  return new Promise(async (resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/google-access-token`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ integrationId, companyAppId }),
      credentials:
        process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data && data.success) {
          const accessToken = data.access_token;
          resolve(accessToken);
        } else {
          console.error("Google Access Token alınamadı:", data.message);
          reject(data.message || "Google Access Token alınamadı.");
        }
      })
      .catch((error) => {
        console.error(
          "Google Access Token API çağrısı sırasında hata oluştu:",
          error
        );
        reject("Google Access Token alınırken bir hata oluştu.");
      });
  });
}
