import React from "react";
import { Link, Navigate } from "react-router-dom";
import { isEqual } from "lodash";

import { DashboardSection } from "../../models/DashboardSection";
import {
  deleteDashboardSection,
  getDashboardSections,
} from "../../../helpers/api";
import stores from "../../stores";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";

import CustomSectionView from "./CustomSectionView";
import { TheChiefDashboardLoading } from "./loading/TheChiefDashboardLoading";

type Props = {};
type State = {
  sections: DashboardSection[];
  isEditing: boolean;
  isCreateNewSectionFlow: boolean;
  loading: boolean;
};

export default class TheChiefDashboard extends React.Component<Props, State> {
  private fetchIntervalId: NodeJS.Timeout | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      sections: [],
      isEditing: false,
      isCreateNewSectionFlow: false,
      loading: true,
    };
  }

  componentDidMount() {
    this.fetchSections();

    this.fetchIntervalId = setInterval(this.fetchSections, 5000);
  }

  componentWillUnmount() {
    if (this.fetchIntervalId) {
      clearInterval(this.fetchIntervalId);
    }
  }

  private fetchSections = async () => {
    try {
      if (stores.companyStore.selectedUserCompany) {
        const sections = await getDashboardSections(
          stores.companyStore.selectedUserCompany.id
        );

        if (!isEqual(this.state.sections, sections)) {
          console.log("Sections changed, updating state");

          this.setState({
            sections: sections,
            loading: false,
          });
        }
      }
    } catch (error) {
      console.error("Error fetching sections:", error);
      this.setState({ loading: false });
    }
  };

  handleLayoutChange = (layout: any[]) => {};

  handleDeleteSection = async (section: DashboardSection) => {
    this.setState({
      sections: this.state.sections.filter((s) => s.id !== section.id),
    });

    await deleteDashboardSection(section.id);
  };

  private handleCompleteOnboarding = async () => {
    try {
      this.setState({ loading: true });

      stores.userStore.currentUser.is_onboarded = true;

      await stores.userStore.updateUserData();

      await this.fetchSections();
    } catch (error) {
      console.error("Error completing onboarding:", error);
      this.setState({ loading: false });
    }
  };

  handleCreateNewSectionFlow = async () => {
    this.setState({ isCreateNewSectionFlow: true });
    stores.wizardStore.resetWizard();
  };

  toggleEditMode = () => {
    this.setState((prevState) => ({ isEditing: !prevState.isEditing }));
  };

  private renderOnboardingPage = () => {
    const { name } = stores.userStore.currentUser || {};
    const userName = name || "User";

    const companyApps = stores.companyAppStore.companyApps;
    const companyModels = stores.companyModelStore.companyModels;

    const isAppConnected = companyApps && companyApps.length > 0;
    const isModelConnected = companyModels && companyModels.length > 0;

    if (this.state.loading) {
      return (
        <div className="card mb-5 mb-xl-10">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "80vh" }}
          >
            <TheChiefDashboardLoading />
          </div>
        </div>
      );
    }

    return (
      <div className="container py-10">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body p-lg-5">
                <h2 className="fw-bold mb-7">Welcome {userName},</h2>
                <p className="fw-semibold fs-5 text-gray-800">
                  To start creating your dashboard, please complete the
                  following steps:
                </p>

                {/* Step 1: Model Integration */}
                <div className="d-flex align-items-center mb-5">
                  <div>
                    {isModelConnected ? (
                      <KTSVG
                        path="/media/icons/duotune/general/gen043.svg"
                        className="svg-icon-2hx svg-icon-success me-4"
                      />
                    ) : (
                      <KTSVG
                        path="/media/icons/duotune/general/gen040.svg"
                        className="svg-icon-2hx svg-icon-gray-400 me-4"
                      />
                    )}
                  </div>
                  <div>
                    <h4 className="fw-bold mb-1">
                      1) Integrate Your Data Model
                    </h4>
                    <p className="text-gray-600 mb-1">
                      Connect at least one CompanyModel by providing the
                      required information.
                    </p>
                    <Link
                      to="/models"
                      className="btn btn-sm btn-light btn-color-gray-800 btn-outline py-2 mb-2 mt-2"
                    >
                      <KTSVG
                        path="/media/icons/duotune/technology/teh003.svg"
                        className="svg-icon-3 me-2"
                      />
                      Manage Models
                    </Link>
                  </div>
                </div>

                {/* Step 2: App Integration */}
                <div className="d-flex align-items-center mb-5">
                  <div>
                    {isAppConnected ? (
                      <KTSVG
                        path="/media/icons/duotune/general/gen043.svg"
                        className="svg-icon-2hx svg-icon-success me-4"
                      />
                    ) : (
                      <KTSVG
                        path="/media/icons/duotune/general/gen040.svg"
                        className="svg-icon-2hx svg-icon-gray-400 me-4"
                      />
                    )}
                  </div>
                  <div>
                    <h4 className="fw-bold mb-1">
                      2) Integrate Your Application
                    </h4>
                    <p className="text-gray-600 mb-1">
                      Connect at least one CompanyApp from our app store to
                      enable data flow and interactivity.
                    </p>
                    <Link
                      to="/connections"
                      className="btn btn-sm btn-light btn-color-gray-800 btn-outline py-2 mb-2 mt-2"
                    >
                      <KTSVG
                        path="/media/icons/duotune/general/gen008.svg"
                        className="svg-icon-3 me-2"
                      />
                      Manage Connections
                    </Link>
                  </div>
                </div>

                <div className="text-center mt-10">
                  <button
                    className="btn btn-primary fs-6 px-8 py-3"
                    disabled={!isAppConnected || !isModelConnected}
                    onClick={this.handleCompleteOnboarding}
                  >
                    Complete Onboarding
                  </button>

                  <div className="form-text mt-4">
                    This button will be enabled once you have integrated at
                    least one model and one app.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { isCreateNewSectionFlow, sections, loading } = this.state;

    const currentUser = stores.userStore.currentUser;
    const isOnboarded = currentUser?.is_onboarded;

    if (loading) {
      return (
        <div className="card mb-5 mb-xl-10">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "80vh" }}
          >
            <TheChiefDashboardLoading />
          </div>
        </div>
      );
    }

    if (isCreateNewSectionFlow) {
      return <Navigate to="/create-new-section-flow" />;
    }

    if (!isOnboarded) {
      return this.renderOnboardingPage();
    }

    // return (
    //   <div className="content d-flex flex-column flex-column-fluid">
    //     <div className="d-flex flex-row flex-row-fluid mb-4 justify-content-end">
    //       <button
    //         className={`btn ${isEditing ? "btn-danger" : "btn-primary"}`}
    //         onClick={this.toggleEditMode}
    //       >
    //         {isEditing ? "Finish Editing" : "Edit Sections"}
    //       </button>

    //       <button
    //         className={"btn btn-primary mx-3"}
    //         onClick={this.handleCreateNewSectionFlow}
    //       >
    //         Create Custom Section
    //       </button>
    //     </div>

    //     <GridLayout
    //       className="layout"
    //       cols={12}
    //       rowHeight={100}
    //       width={1200}
    //       onLayoutChange={this.handleLayoutChange}
    //       isDraggable={isEditing}
    //       isResizable={isEditing}
    //     >
    //       {sections.map((section) => (
    //         <div key={`item-${section.id}`} className="grid-item">
    //           <div
    //             style={{ width: "50vw" }}
    //             className="col-xxl-10"
    //             key={section.id}
    //           >
    //             <CustomSectionView section={section} />
    //           </div>
    //         </div>
    //       ))}
    //     </GridLayout>
    //   </div>
    // );

    return (
      <>
        {sections.length > 0 ? (
          <div className="row gy-5 g-xl-8">
            {sections.map((section) => (
              <div className="col-md-6 col-xxl-6" key={section.id}>
                <CustomSectionView
                  section={section}
                  onDeletePress={this.handleDeleteSection}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="container py-10 mt-10">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="card">
                  <div className="card-body p-lg-5 d-flex flex-row align-items-center">
                    <div className="symbol symbol-80px mb-2 me-4">
                      <img
                        alt="Pic"
                        src={toAbsoluteUrl(
                          "https://imagedelivery.net/01kSRcVEVAYmOHOBQNSt0A/93bbf41d-9c33-44c7-b6eb-38fe5b9a1300/mid"
                        )}
                      />
                    </div>

                    <p className="text-gray-600 mb-1">
                      🤖 The Chief is working diligently to set up your
                      dashboard and will have it ready for you shortly. Now is
                      the perfect time to grab a coffee and let the AI handle
                      the rest! ☕
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}
