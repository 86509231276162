/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { KTIcon } from "../../../helpers";
import { Dropdown1 } from "../../content/dropdown/Dropdown1";

type NotificationItem = {
  iconColor: string;
  title: string;
  description: string;
  labelText: string;
  labelColor: string;
};

type Props = {
  className: string;
  title: string;
  notifications: NotificationItem[];
};

const ListsWidget6: React.FC<Props> = ({ className, title, notifications }) => {
  return (
    <div className={`card card-xl-stretch mb-5 mb-xl-8 ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0">
        <h3 className="card-title fw-bold text-dark">{title}</h3>
        <div className="card-toolbar">
          {/* begin::Menu */}
          <button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <KTIcon iconName="category" className="fs-2" />
          </button>
          <Dropdown1 />
          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="card-body pt-0">
        {notifications.map((notification, index) => (
          <div
            key={index}
            className={`d-flex align-items-center bg-light-${notification.iconColor} rounded p-5 mb-7`}
          >
            {/* begin::Icon */}
            <span className={`text-${notification.iconColor} me-5`}>
              <KTIcon
                iconName="abstract-26"
                className={`text-${notification.iconColor} fs-1 me-5`}
              />
            </span>
            {/* end::Icon */}
            {/* begin::Title */}
            <div className="flex-grow-1 me-2">
              <a
                href="#"
                className="fw-bold text-gray-800 text-hover-primary fs-6"
              >
                {notification.title}
              </a>
              <span className="text-muted fw-semibold d-block">
                {notification.description}
              </span>
            </div>
            {/* end::Title */}
            {/* begin::Label */}
            <span className={`fw-bold text-${notification.labelColor} py-1`}>
              {notification.labelText}
            </span>
            {/* end::Label */}
          </div>
        ))}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { ListsWidget6 };
