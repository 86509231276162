import React from "react";
import { observer } from "mobx-react";
import { ThemeModeSwitcher } from "../../../partials";
import { Notification } from "../../../partials/";
import stores from "../../../app/stores";
import { KTSVG } from "../../../helpers";

const Topbar: React.FC = observer(() => {
  const totalFreeTokenCount = process.env.REACT_APP_FREE_TOKEN_COUNT
    ? parseInt(process.env.REACT_APP_FREE_TOKEN_COUNT)
    : 25000;
  const usedTokenCount =
    totalFreeTokenCount - stores.companyStore.freeTokenCount;

  const progress = Math.round((100 * usedTokenCount) / totalFreeTokenCount);

  const color =
    progress < 50 ? "primary" : progress > 75 ? "danger" : "warning";

  return (
    <div className="d-flex flex-shrink-0 align-items-center">
      {stores.userStore.isCurrentUserAdmin && stores.userStore.isFreeUser ? (
        <div className="d-flex ms-2">
          <button
            className="btn btn-flex flex-center btn-color-gray-700 btn-active-color-primary h-40px px-0 px-md-6"
            style={{
              width: 300,
            }}
            onClick={() => {}}
          >
            <KTSVG
              path={"/media/icons/duotune/abstract/abs021.svg"}
              className={`svg-icon svg-icon-2x text-${color} me-3`}
            />
            <div className="d-flex flex-column w-100 ms-2">
              <div className="d-flex mb-2">
                <span
                  className={`d-flex text-${color} me-2 fs-7 fw-semibold`}
                  style={{ flex: 1 }}
                >
                  Free Token Usage
                </span>
                <span className="text-muted me-2 fs-8 fw-semibold">{`${progress}%`}</span>
              </div>
              <div className="progress h-6px w-100">
                <div
                  className={`progress-bar bg-${color}`}
                  role="progressbar"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
            </div>
          </button>
        </div>
      ) : null}

      {/* Notification Component */}
      <Notification />

      {/* Theme mode switcher */}
      <ThemeModeSwitcher toggleBtnClass="flex-center bg-body btn-color-gray-600 btn-active-color-primary h-40px" />
    </div>
  );
});

export { Topbar };
